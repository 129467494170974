import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { ProductForSale } from 'types/Product';
import { daysUntil, getDay } from 'utils/date/format';

type ProductDeliveryDeadlineTextProps = {
  product: ProductForSale;
  selectedDeliveryDate: string;
};

const ProductDeliveryDeadlineText = ({
  product,
  selectedDeliveryDate
}: ProductDeliveryDeadlineTextProps) => {
  const { deadlines } = product;

  const { t } = useTranslation();

  const deliveryDateDeadline = deadlines[selectedDeliveryDate];
  const daysToDeadline = daysUntil(deliveryDateDeadline);

  if (daysToDeadline > 7 || !deliveryDateDeadline) return null;

  const TIME_BEFORE_MIDNIGHT = '23:59';

  const deadlineTextColor = daysToDeadline === 0 ? 'warning' : 'secondary';

  const deadlineText = t('consumer:OrderBefore', {
    date:
      daysToDeadline === 0
        ? TIME_BEFORE_MIDNIGHT
        : `${getDay(deliveryDateDeadline)} ${TIME_BEFORE_MIDNIGHT}`
  });

  return (
    <Typography variant="paragraphSmall" mt="xxs" color={deadlineTextColor}>
      {deadlineText}
    </Typography>
  );
};

export default ProductDeliveryDeadlineText;
