import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 0;
  max-width: 320px;

  > img {
    height: 100px;
    width: auto;
  }
`;

const LoaderText = styled(Typography).attrs(() => {
  return {
    variant: 'paragraph'
  };
})`
  text-align: center;
  animation-name: ${fadeIn};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: both;
`;

const Loader = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <img
        src="/assets/Loading-dagensmat.gif"
        alt={t('common:loaderVegetableAnimation')}
      />
      <LoaderText>{t('common:loaderTrade')}</LoaderText>
    </Container>
  );
};
export default Loader;
