import { MenuItem } from './presets/menu-item';
import { ModalAction } from './presets/modal-action';
import { NavIcon } from './presets/nav-icon';
import { NavLink } from './presets/nav-link';
import { ButtonWrapper } from './components';
import { DropdownItem } from './presets/dropdown-item';
import { CalloutToggle } from './presets/callout-toggle';

export const Button = Object.assign(ButtonWrapper, {
  CalloutToggle,
  DropdownItem,
  MenuItem,
  ModalAction,
  NavLink,
  NavIcon
});
