import { ReactComponent as alert } from './information/alert.svg';
import { ReactComponent as check } from './information/check.svg';
import { ReactComponent as deliveryAreas } from './information/delivery-areas.svg';
import { ReactComponent as location } from './information/location.svg';
import { ReactComponent as showLessInfo } from './information/show-less-info.svg';
import { ReactComponent as showMoreInfo } from './information/show-more-info.svg';
import { ReactComponent as success } from './information/success.svg';
import { IconDefinitions } from './types';

export const informationIcons = {
  alert: {
    medium: alert
  },
  check: {
    medium: check
  },
  deliveryAreas: {
    medium: deliveryAreas
  },
  location: {
    medium: location
  },
  showLessInfo: {
    medium: showLessInfo
  },
  showMoreInfo: {
    medium: showMoreInfo
  },
  success: {
    medium: success
  }
} as const satisfies IconDefinitions;

export type InformationIcon = keyof typeof informationIcons;
