import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';

import ResponsiveImage from '../../../_common/components/utils/ResponsiveImage.style';

const PackingAndLabelingInfo = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Typography variant="paragraphSmall" mb="s">
        {t('producer:tipsForPricingProductsParagraph1')}
      </Typography>
      <Typography variant="paragraphSmallBold">
        {t('producer:tipsForPricingProductsHeader2')}
      </Typography>
      <Typography variant="paragraphSmall" mb="s">
        {t('producer:tipsForPricingProductsParagraph2')}
      </Typography>
      <Typography variant="paragraphSmallBold">
        {t('producer:tipsForPricingProductsHeader3')}
      </Typography>
      <Typography variant="paragraphSmall" mb="s">
        {t('producer:tipsForPricingProductsParagraph3')}
      </Typography>
      <Typography variant="paragraphSmallBold">
        {t('producer:tipsForPricingProductsHeader4')}
      </Typography>
      <Typography variant="paragraphSmall" mb="s">
        {t('producer:tipsForPricingProductsParagraph4')}
      </Typography>
      <Typography variant="paragraphSmallBold">
        {t('producer:tipsForPricingProductsHeader5')}
      </Typography>
      <Typography variant="paragraphSmall" mb="s">
        {t('producer:tipsForPricingProductsParagraph5')}
      </Typography>
      <Typography variant="paragraphSmallBold">
        {t('producer:tipsForPricingProductsHeader6')}
      </Typography>
      <Typography variant="paragraphSmall" mb="xs" color="secondary">
        {t('producer:tipsForPricingProductsParagraph6')}
      </Typography>
      <ResponsiveImage src="/assets/TIPS_PRICING_EXAMPLE.jpg" alt="" />
    </Container>
  );
};

export default PackingAndLabelingInfo;
