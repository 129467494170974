import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../_common/hooks/reduxHooks';
import { fetchCategories } from './reducers/categories';
import { clearConsumers, fetchConsumers } from './reducers/consumers';
import {
  clearProducerProducts,
  fetchProducerProducts
} from './reducers/products';
import { CommandPalette } from '@components/command-palette';
import {
  clearProductRequests,
  setUpProductRequests
} from '_common/reducers/productRequests';
import { clearOrders, fetchOrders } from '_common/reducers/orders';
import { clearMessages, setUpMessages } from '_common/reducers/messages';

export const ProducerLayout = () => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector(({ auth }) => {
    return {
      userId: auth._id
    };
  });

  const fetchUserData = () => {
    dispatch(fetchOrders({ producerId: userId }));
    dispatch(fetchProducerProducts(userId));
    dispatch(fetchCategories());
    dispatch(setUpProductRequests(userId));
    dispatch(fetchConsumers({ producerId: userId }));
    dispatch(setUpMessages(userId));
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
    return () => {
      dispatch(clearOrders());
      dispatch(clearProducerProducts());
      dispatch(clearProductRequests());
      dispatch(clearConsumers());
      dispatch(clearMessages());
    };
  }, [userId]);

  return (
    <>
      <Outlet />
      <CommandPalette />
    </>
  );
};
