import { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';

export const fullWidthStyle = tv({
  base: `
    m-auto
    max-w-desktop
    justify-center
    px-s

    print:max-w-none
    print:p-xxs
  `
});

type Props = PropsWithChildren;

export const BoxFullWidth = ({ children }: Props) => {
  return <div className={fullWidthStyle()}>{children}</div>;
};
