import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useTranslation } from '@dagens/frontend-i18n';
import { useThrowError } from '../../../utils/errorHandling';
import { DeliveryWindow } from '../../../types/Consumer';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import Loader from '_common/components/loader/Loader';
import PageHeader from '@components/page-header/PageHeader';
import { userUpdated } from '_common/reducers/auth';
import { ActionButton } from '@components/action-button';
import { BottomActions } from '@components/bottom-actions';
import { Page } from '@components/page';
import {
  getConsumerDeliveryInformation,
  postConsumerDeliveryInformation
} from 'api';
import REQ, { ReqType } from 'utils/REQ';
import { DeliveryInformationForm } from '@components/delivery-information-form';

type OriginalConsumerDeliveryInformation = {
  deliveryAddress: string;
  deliveryWindow: DeliveryWindow;
  deliveryInfo?: string;
};

type LocationState =
  | {
      redirect?: string;
    }
  | undefined;

const DeliveryInformationPage = () => {
  const [
    originalConsumerDeliveryInformation,
    setOriginalConsumerDeliveryInformation
  ] = useState<OriginalConsumerDeliveryInformation | undefined>(undefined);
  const [earliestDeliveryWindowHour, setEarliestDeliveryWindowHour] =
    useState(7);
  const [latestDeliveryWindowHour, setLatestDeliveryWindowHour] = useState(17);
  const [minDeliveryWindowDuration, setMinDeliveryWindowDuration] = useState(2);
  const [deliveryAddress, setDeliveryAddress] = useState<string>('');
  const [deliveryWindow, setDeliveryWindow] = useState<DeliveryWindow>({
    start: '08:00',
    end: '16:00'
  });
  const [deliveryInstructions, setDeliveryInstructions] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [fetchReq, setFetchReq] = useState<ReqType>(REQ.INIT);
  const [saveReq, setSaveReq] = useState<ReqType>(REQ.INIT);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const throwError = useThrowError();
  const location = useLocation();
  const state = location.state as LocationState;

  const { userId } = useAppSelector(({ auth: { _id: userId } }) => ({
    userId
  }));

  const getDeliveryInformation = async () => {
    setFetchReq(REQ.PENDING);
    await getConsumerDeliveryInformation({ consumerId: userId })
      .then(deliveryInformation => {
        setOriginalConsumerDeliveryInformation({
          deliveryAddress: deliveryInformation.deliveryAddress,
          deliveryWindow: deliveryInformation.deliveryWindow,
          deliveryInfo: deliveryInformation.deliveryInfo
        });
        setEarliestDeliveryWindowHour(
          deliveryInformation.earliestDeliveryWindowHour
        );
        setLatestDeliveryWindowHour(
          deliveryInformation.latestDeliveryWindowHour
        );
        setMinDeliveryWindowDuration(
          deliveryInformation.minDeliveryWindowDuration
        );
        setDeliveryAddress(deliveryInformation.deliveryAddress);
        setDeliveryWindow(deliveryInformation.deliveryWindow);
        setDeliveryInstructions(deliveryInformation.deliveryInfo || '');
        setFetchReq(REQ.SUCCESS);
      })
      .catch(() => {
        setFetchReq(REQ.ERROR);
        throwError(`Failed to fetch delivery information for user ${userId}`);
      });
  };

  const onSubmit = async () => {
    setSaveReq(REQ.PENDING);
    try {
      await postConsumerDeliveryInformation({
        userId,
        deliveryAddress,
        deliveryInfo: deliveryInstructions,
        deliveryWindow
      });
      dispatch(
        userUpdated({
          deliveryAddress,
          deliveryInfo: deliveryInstructions,
          deliveryWindow
        })
      );
      setSaveReq(REQ.SUCCESS);
    } catch (error) {
      setSaveReq(REQ.ERROR);
      throwError(`Failed to update delivery information for user ${userId}`);
    }
  };

  const hasFormChanges = () => {
    return !isEqual(originalConsumerDeliveryInformation, {
      deliveryWindow: deliveryWindow,
      deliveryAddress: deliveryAddress,
      deliveryInfo: deliveryInstructions
    });
  };

  useEffect(() => {
    getDeliveryInformation();
  }, []);

  const canSave = useMemo(() => {
    return fetchReq === REQ.SUCCESS && !hasError && hasFormChanges();
  }, [deliveryAddress, deliveryWindow, deliveryInstructions]);

  const loading =
    fetchReq !== REQ.SUCCESS || !originalConsumerDeliveryInformation;

  return (
    <Page
      bottom={
        !loading && (
          <BottomActions>
            <ActionButton.Save
              onClick={onSubmit}
              disabled={!canSave}
              saveReq={saveReq}
              redirectTo={state?.redirect ?? '/profile'}
            >
              {t('common:SubmitDeliveryInformation')}
            </ActionButton.Save>
          </BottomActions>
        )
      }
    >
      <PageHeader headerText={t('common:DeliveryInformation')} />
      {loading ? (
        <Loader />
      ) : (
        <DeliveryInformationForm
          deliveryAddress={deliveryAddress}
          onDeliveryAddressChange={(address: string) => {
            setDeliveryAddress(address);
          }}
          deliveryWindow={deliveryWindow}
          earliestDeliveryWindowHour={earliestDeliveryWindowHour}
          latestDeliveryWindowHour={latestDeliveryWindowHour}
          minDeliveryWindowDuration={minDeliveryWindowDuration}
          onDeliveryWindowChange={(deliveryWindow: DeliveryWindow) => {
            setDeliveryWindow(deliveryWindow);
          }}
          deliveryInstructions={deliveryInstructions}
          onDeliveryInstructionsChange={(instructions: string) => {
            setDeliveryInstructions(instructions);
          }}
          onErrorChange={(hasError: boolean) => {
            setHasError(hasError);
          }}
        />
      )}
    </Page>
  );
};

export default DeliveryInformationPage;
