import { Mixpanel } from 'mixpanel-browser';
import { isRegularPrice } from './pricing';
import { type User } from '_common/reducers/auth';
import { ALL_CUSTOMERS, gitCommitRef } from 'config';
import { Pricing, EditablePricing } from 'types/Product';
import { SanityRef } from 'types/Sanity';

const { mixpanel }: { mixpanel: Mixpanel } = window as any;

export const setupRole = (role?: User) => {
  const { _id, _type, name, distributionAreaName, loginEmail, organization } =
    role ?? {};

  mixpanel.identify(_id);

  mixpanel.people.set({
    roleType: _type,
    $name: name,
    organizationCountryCode: organization?.address?.country,
    distributionAreaName,
    release: gitCommitRef
  });

  mixpanel.register({
    loginEmail
  });
};

/** Page View Events */

export const BASKET_PAGE_VIEW = 'Basket - Page view';
export const PRODUCT_PAGE_VIEW = 'Product - Page view';
export const LOGIN_PAGE_VIEW = 'Login - Page view';
export const USER_SIGNUP_PAGE_VIEW = 'User signup - Page view';
export const PRODUCER_SIGNUP_PAGE_VIEW = 'Producer signup - Page view';
export const CONSUMER_SIGNUP_PAGE_VIEW = 'Consumer signup - Page view';
export const PRODUCT_SEARCH_PAGE_VIEW = 'Product search - Page view';
export const PRODUCER_OVERVIEW_PAGE_VIEW = 'Producer overview - Page view';
export const PRODUCER_SHOP_PAGE_VIEW = 'Producer shop - Page view';
export const PRODUCER_HOME_PAGE_VIEW = 'Producer home - Page view';
export const MY_PRODUCTS_PAGE_VIEW = 'My products - Page view';
export const ADD_PRODUCT_PAGE_VIEW = 'Add product - Page view';
export const EDIT_PRODUCT_PAGE_VIEW = 'Edit product - Page view';
export const PRODUCER_ORDER_OVERVIEW_PAGE_VIEW =
  'Producer order overview - Page view';
export const CONSUMER_VIEW_FAVORITE_PAGE = 'My favorites - Page view';
export const INTRO_SCREEN_ONE_PAGE_VIEW = 'Intro Screen 1 - Page view';
export const INTRO_SCREEN_TWO_PAGE_VIEW = 'Intro Screen 2 - Page view';
export const MINIMUM_ORDER_FORM_PAGE_VIEW = 'Minimum order form - Page view';
export const CUSTOMER_LIST_PAGE_VIEW = 'Customer list - Page view';

/** User Action Events */

export const BASKET_CLICK_SEND_ORDER = 'Basket - Click send order';
export const SELECT_DELIVERY_DAY_PRODUCT_SEARCH =
  'Product Search - Select Delivery Day';
export const SELECT_DELIVERY_DAY_PRODUCT = 'Product - Select Delivery Day';
export const UNSELECT_DELIVERY_DAY =
  'Delivery Day Banner - Unselect Delivery Day';
export const DOWNLOAD_PARCEL_LABELS = 'Download Parcel Labels';
export const TOGGLE_ORDER_NOTE_TEXT_AREA = 'Toggle Order Note Text Area';
export const SWITCH_ROLE = 'Switch Role';
export const TOGGLE_PACKING_LIST_AXES = 'Toggle Packing List Axes';
export const DOWNLOAD_DELIVERY_NOTES = 'Download Delivery Notes';
export const OPEN_PRODUCT_FROM_PRODUCER_LANDING_PAGE =
  'Open Product From Producer Landing Page';
export const DOWNLOAD_MY_DATA = 'Profile - Click download Trade Data';
export const PROFILE_CLICK_TRADE_REPORT_LINK =
  'Profile - Click Trade Report link';
export const CLICK_COPY_TRADE_REPORT_LINK =
  'Profile - Click copy Trade Report link';
export const CONSUMER_SIGNUP_SUBMITTED = 'Consumer Signup Submitted';
export const CONSUMER_SIGNUP_FAILED = 'Consumer Signup Failed';
export const PRODUCER_SIGNUP_SUBMITTED = 'Producer Signup Submitted';
export const PRODUCER_SIGNUP_FAILED = 'Producer Signup Failed';
export const CONSUMER_RESET_FILTERS_DELIVERY_DATE =
  'Product Search - Click Reset Filters And Delivery Day Button';
export const SEARCH_SEASON_CAL_CLICKED = 'Search season cal - Clicked product';
export const FAVORITES_SEASON_CAL_CLICKED =
  'My favorites season cal - Clicked product';
export const PRODUCT_CARD_CLICKED =
  'Product search card - Clicked product card';
export const PRODUCT_ORDER_ITEM_CARD_CLICKED =
  'Product order item search card - Clicked product card';
export const PRODUCER_SEASON_CAL_CLICKED =
  'Producer season cal - Clicked product';
export const PRODUCT_CATEGORY_BUTTON_OPENED =
  'Product search - Show filter category';
export const PRODUCT_CATEGORY_BUTTON_CLOSED =
  'Product search - Hide filter category';
export const PRODUCT_SEARCH_ADD_FILTER = 'Product search - Add filter';
export const PRODUCT_SEARCH_REMOVE_FILTER = 'Product search - Remove filter';
export const PRODUCT_SEARCH = 'Product search - Search';
export const INTRO_SCREEN_ONE_CLICK_SKIP_INTRO =
  'Intro Screen 1 - Click skip intro';
export const INTRO_SCREEN_ONE_CLICK_NEXT = 'Intro Screen 1 - Click next';
export const INTRO_SCREEN_TWO_CLICK_CTA = 'Intro Screen 2 - Click CTA';
export const PRODUCT_SEARCH_CLICK_SHOW_ALL_PRODUCTS =
  'Product search - Click show all products';
export const EDIT_PRODUCT_CLICK_EDIT_OFFER = 'Edit product - Click edit offer';
export const EDIT_PRODUCT_CLICK_CONFIRM_TO_DELETE_OFFER =
  'Edit product - Click confirm to delete offer';
export const EDIT_PRODUCT_CLICK_ADD_NEW_OFFER =
  'Edit product - Click add new offer';
export const ADD_OFFER_PAGE_VIEW = 'Add offer - Page view';
export const ADD_OFFER_CLICK_SAVE_OFFER = 'Add offer - Click save offer';
export const EDIT_OFFER_PAGE_VIEW = 'Edit offer - Page view';
export const EDIT_OFFER_CLICK_SAVE_OFFER = 'Edit offer - Click save offer';
export const EDIT_PRODUCT_UNSAVED_CHANGES_ALERT_VIEW =
  'Edit product - Unsaved changes alert view';
export const CREATE_ORDER_FLOW_CLICK_PLACE_ORDER =
  'Create order flow - Click place order';
export const PRODUCER_HOME_CLICK_SHOP_LINK = 'Producer home - Click shop link';
export const PRODUCER_SHOP_CLICK_PRODUCT_SIGNUP_CTA =
  'Producer shop - Click product signup CTA';
export const CLICK_PRODUCT_NEWS_CARD =
  'Customer home page - Click news-product preview';
export const MINIMUM_ORDER_MESSAGE_SHOWN =
  'Basket - Minimum order message shown';
export const BASKET_INCREASE_BASKET_QUANTITY =
  'Basket - Increase basket quantity';
export const BASKET_DECREASE_BASKET_QUANTITY =
  'Basket - Decrease basket quantity';
export const BASKET_ADD_TO_BASKET = 'Basket - Add to basket';
export const BASKET_REMOVE_FROM_BASKET = 'Basket - Remove from basket';
export const INCREASE_BASKET_QUANTITY = 'Increase basket quantity';
export const DECREASE_BASKET_QUANTITY = 'Decrease basket quantity';
export const ADD_TO_BASKET = 'Add to basket';
export const REMOVE_FROM_BASKET = 'Remove from basket';
export const INPUT_BASKET_QUANTITY = 'Input basket quantity';
export const CLICK_BASKET_SMALL_PRODUCT_CARD =
  "Basket - Click producer's small product card";
export const PRODUCER_HOME_REMIND_CUSTOMER_LINK =
  'Producer home - Click customer link in remind customer module';
export const PRODUCER_HOME_TOP_UP_ORDER_LINK =
  'Producer home - Click order link in top-up orders module';
export const PRODUCER_HOME_TOP_UP_ALL_ORDERS =
  'Producer home - Click view all orders in top-up orders module';
export const PRODUCER_HOME_CLICK_TRADE_REPORT_LINK =
  'Producer home — Click trade report link in sales statistics module';
export const CUSTOMER_LANDING_PAGE_WEEKLY_ORDER_NUDGE_SHOWN =
  'Customer Landing Page - Weekly Order Nudge shown';
export const CUSTOMER_LANDING_PAGE_CLICK_WEEKLY_ORDER_NUDGE_CTA =
  'Customer Landing Page - Clicked Weekly Order Nudge CTA';

/** General events */
export const SUCCESSFUL_LOGIN = 'Successful Login';
export const AUTHENTICATED = 'Authenticated';

export const track: Mixpanel['track'] = (...args) => {
  return mixpanel.track(...args);
};

type ConsumersFromReduxStore = {
  _id: string;
  name: string;
  contactPerson: string;
  distributionArea: SanityRef;
};

export const getConsumersForDataTracking = (
  consumers: ConsumersFromReduxStore[],
  pricing: Pricing | EditablePricing
):
  | {
      consumerNames: string[];
      consumerIds: string[];
      numberOfConsumers: number;
    }
  | undefined => {
  if (isRegularPrice(pricing)) {
    return {
      consumerNames: [ALL_CUSTOMERS],
      consumerIds: [ALL_CUSTOMERS],
      numberOfConsumers: 1
    };
  }

  const ids = pricing.specialConsumers?.map(({ _ref }) => {
    return _ref;
  });

  return ids?.reduce<{
    consumerNames: string[];
    consumerIds: string[];
    numberOfConsumers: number;
  }>(
    (acc, key) => {
      const foundConsumer = consumers.find(consumer => {
        return consumer._id === key;
      });
      if (foundConsumer) {
        const { name, _id } = foundConsumer;
        acc.consumerNames.push(name);
        acc.consumerIds.push(_id);
        acc.numberOfConsumers += 1;
      }
      return acc;
    },
    {
      consumerNames: [],
      consumerIds: [],
      numberOfConsumers: 0
    }
  );
};

export const getViewerForDataTracking = (
  roleType: string | undefined,
  isInSameDa: boolean,
  producerId: string,
  _id: string | undefined
): string => {
  switch (true) {
    case roleType === 'consumers' && isInSameDa:
      return 'Customer (in DA)';
    case roleType === 'consumers' && !isInSameDa:
      return 'Customer (outside DA)';
    case roleType === 'producers' && producerId === _id:
      return 'Producer (own shop)';
    case roleType === 'producers' && producerId !== _id:
      return 'Producer (any)';
    default:
      return 'Unauth';
  }
};

export const getLocationForDataTracking = (pathname: string): string => {
  const CLICK_NAVBAR_SIGNUP_CTA = 'Click navbar signup CTA';

  switch (true) {
    case ['/letsgo', '/komigang', '/buy'].includes(pathname):
      return `Consumer signup - ${CLICK_NAVBAR_SIGNUP_CTA}`;
    case pathname === '/login':
      return `Login - ${CLICK_NAVBAR_SIGNUP_CTA}`;
    case pathname === '/reset-password':
      return `Reset password - ${CLICK_NAVBAR_SIGNUP_CTA}`;
    case pathname === '/signup':
      return `User signup - ${CLICK_NAVBAR_SIGNUP_CTA}`;
    case ['/signup-producers', '/blimed', '/sell'].includes(pathname):
      return `Producer signup - ${CLICK_NAVBAR_SIGNUP_CTA}`;
    default:
      return `Producer shop - ${CLICK_NAVBAR_SIGNUP_CTA}`;
  }
};
