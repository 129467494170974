import { useTranslation } from '@dagens/frontend-i18n';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import OrderAdjustmentSummaryTable from './OrderAdjustmentSummaryTable';
import {
  getTotalOrderPrice,
  getTotalOrdersPrice,
  isOrderInvoiceSentToDagens
} from '_common/reducers/orders';
import { Order, OrderAdjustment, OrderKind } from 'types/Order';
import { getDateShort } from 'utils/date/format';
import { formatNok2Decimals } from 'utils/texts';

type OrderAdjustedFeedbackProps = {
  order: Order;
  orderAndAdjustments: OrderKind[];
};

const OrderAdjustedFeedback = ({
  order,
  orderAndAdjustments
}: OrderAdjustedFeedbackProps) => {
  const { t } = useTranslation();

  const showOrderIsAdjusted =
    orderAndAdjustments.length > 1 && isOrderInvoiceSentToDagens(order);

  if (!showOrderIsAdjusted) {
    return null;
  }

  const initialOrderTotal = getTotalOrderPrice(order.orderLines);
  const adjustments = orderAndAdjustments.filter((o): o is OrderAdjustment => {
    return o._type === 'orderAdjustment';
  });
  const adjustedTotal = getTotalOrdersPrice([order, ...adjustments]);

  const showAdjustmentSummaryTable = adjustments.length > 0;
  const adjustmentOverviewRows = [
    {
      description: `${t('common:InitialOrder')} (${order.orderNumberString})`,
      amount: formatNok2Decimals(initialOrderTotal)
    },
    ...adjustments.map(adjustment => {
      return {
        description: `${t('common:Adjustment')} ${getDateShort(
          adjustment._createdAt
        )} (${adjustment.orderNumberString})`,
        amount: formatNok2Decimals(getTotalOrderPrice(adjustment.orderLines))
      };
    }),
    {
      description: t('common:AdjustedOrderTotal'),
      amount: formatNok2Decimals(adjustedTotal)
    }
  ];

  return (
    <Container my="l">
      <Callout colour="noteworthy" mb="s">
        <Typography variant="paragraph">
          {t('producer:OrderAdjustments.orderHasBeenAdjusted')}
        </Typography>
      </Callout>
      {showAdjustmentSummaryTable && (
        <OrderAdjustmentSummaryTable rows={adjustmentOverviewRows} />
      )}
    </Container>
  );
};
export default OrderAdjustedFeedback;
