import { Button, Icon } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { tv } from 'tailwind-variants';
import { CustomerActionsModal } from '_producer/pages/customer-list-page/customer-actions-modal';
import { Consumer } from 'types/Consumer';

const style = tv({
  slots: {
    list: `
      -mt-s
      flex
      flex-col
    `,
    item: `
      transition-regular
      relative
      border-y-thin
      border-y-lightGrey
      px-xs
      py-s
      transition-[color,background-color,border-color,opacity]

      hover:bg-brightPurple
    `,
    itemText: `
      mb-xs
      flex
      justify-between
    `,
    itemTextName: 'text-paragraphBold',
    itemTextContactPerson: `
      text-paragraphSmall
      text-secondary
    `,
    itemEmails: `
      grid
      w-full
      grid-cols-[max-content,1fr]
      items-center
      gap-x-s
      gap-y-xs
      overflow-hidden
      text-ellipsis
      text-paragraphSmall
    `,
    itemEmail: `
      truncate
      text-secondary
    `,
    itemPhone: `text-secondary`,
    itemAddress: `text-secondary`
  }
});

type Props = {
  consumers: Consumer[];
  activeConsumer: Consumer | null;
  onConsumerClick: (consumer: Consumer) => void;
  onModalClose: () => void;
};

export const CustomerList = ({
  consumers,
  activeConsumer,
  onConsumerClick,
  onModalClose
}: Props) => {
  const {
    item,
    itemText,
    itemTextContactPerson,
    itemTextName,
    itemEmails,
    itemEmail,
    itemPhone,
    itemAddress,
    list
  } = style();
  const { t } = useTranslation();

  return (
    <>
      <ul className={list()}>
        {consumers.map(consumer => {
          const {
            _id,
            name,
            contactPerson,
            emails,
            phone,
            structuredDeliveryAddress: {
              addressLine1,
              addressLine2,
              postalCode,
              city
            }
          } = consumer;

          return (
            <li key={_id} className={item()}>
              <div className={itemText()}>
                <div>
                  <div className={itemTextName()}>{name}</div>
                  <div className={itemTextContactPerson()}>{contactPerson}</div>
                </div>
                <Button
                  variant="borderless"
                  size="regular"
                  fillParent
                  onClick={() => onConsumerClick(consumer)}
                >
                  <Icon icon="meatballs" size="small" />
                </Button>
              </div>

              <div className={itemEmails()}>
                <div>{t('common:Email')}</div>
                <div className={itemEmail()}>
                  {emails.map((email, index, array) => (
                    <>
                      <span key={email}>{email}</span>
                      {index < array.length - 1 && <br />}
                    </>
                  ))}
                </div>
                <div>{t('common:Phone')}</div>
                <div className={itemPhone()}>{phone}</div>
                <div>{t('common:DeliveryAddress')}</div>
                <div className={itemAddress()}>
                  {addressLine1 && (
                    <>
                      {addressLine1}
                      <br />
                    </>
                  )}
                  {addressLine2 && (
                    <>
                      {addressLine2}
                      <br />
                    </>
                  )}
                  {(postalCode || city) && (
                    <>
                      {postalCode} {city}
                    </>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <CustomerActionsModal
        activeConsumer={activeConsumer}
        onClose={onModalClose}
      />
    </>
  );
};
