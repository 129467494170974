import { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';

const style = tv({
  base: `
    text-paragraphSmall
    text-secondary
  `
});

type Props = PropsWithChildren;

export const FormFieldFeedback = ({ children }: Props) => {
  return <div className={style()}>{children}</div>;
};
