import { useTranslation } from '@dagens/frontend-i18n';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { FC } from 'react';
import InContextOnboardingMessage from '_common/components/in-context-onboarding-message/InContextOnboardingMessage';
import { formatNok } from 'utils/texts';

type Props = {
  numOrdersToSettle: number;
  onboardingStep: { isOnboarding: boolean; messageKey: string };
  toSettleAmount: number;
};

const OnboardingHandler: FC<Props> = ({
  numOrdersToSettle,
  onboardingStep,
  toSettleAmount
}) => {
  const { t } = useTranslation();
  return (
    <>
      <InContextOnboardingMessage
        mt="s"
        message={t(onboardingStep.messageKey)}
        showStep={onboardingStep.isOnboarding}
      />
      <Callout mt="s" mb="s">
        {numOrdersToSettle < 1 ? (
          <Typography>{t('producer:NoOrdersReadyForInvoicing')}</Typography>
        ) : (
          <Typography>
            {t('producer:UnsettledOrders', {
              count: numOrdersToSettle,
              nok: formatNok(toSettleAmount)
            })}
          </Typography>
        )}
      </Callout>
    </>
  );
};

export default OnboardingHandler;
