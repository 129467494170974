import { Component } from 'react';

import EditableOrderLineTableRow from './EditableOrderLineTableRow';
import OrderLineTableRow from './OrderLineTableRow';
import { OrderLine } from 'types/Order';
import { UpdateOrderLinePayload } from 'api';

type Props = {
  orderId: string;
  orderLines: OrderLine[];
  isReadOnly?: boolean;
  isOrderAdjustment?: boolean;
  onUpdateOrder?: () => void;
  onSaveOrderLine?: (payload: UpdateOrderLinePayload) => Promise<void> | void;
};

type State = {
  editingKey?: string;
};

class OrderLineTableBody extends Component<Props, State> {
  state: State = {
    editingKey: undefined
  };

  toggleEditing = (key: string | undefined) => {
    const { editingKey } = this.state;

    if (editingKey === key) {
      this.setState({ editingKey: undefined });
    } else {
      this.setState({ editingKey: key });
    }
  };

  render() {
    const { editingKey } = this.state;
    const {
      orderLines,
      orderId,
      isReadOnly = false,
      isOrderAdjustment,
      onUpdateOrder,
      onSaveOrderLine
    } = this.props;

    return (
      <tbody>
        {orderLines.map(line => {
          return line._key === editingKey && !isReadOnly ? (
            <EditableOrderLineTableRow
              key={line._key}
              orderId={orderId}
              toggleEditing={this.toggleEditing}
              line={line}
              isOrderAdjustment={isOrderAdjustment}
              onUpdateOrder={onUpdateOrder}
              onSaveOrderLine={onSaveOrderLine}
            />
          ) : (
            <OrderLineTableRow
              key={line._key}
              onClick={() => {
                this.toggleEditing(line._key);
              }}
              line={line}
              isReadOnly={isReadOnly}
            />
          );
        })}
        <tr />
      </tbody>
    );
  }
}

export default OrderLineTableBody;
