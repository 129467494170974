import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { capitalize } from '@dagensmat/core';

import Logger from '../../../services/Logger';
import DagensDeliveredOrderLines from './DagensDeliveredOrderLines';
import ProducerDeliveredOrderLines from './ProducerDeliveredOrderLines';
import { formatDate } from 'utils/date/format';
import { DeliveryFeesResponse } from 'types/Basket';
import {
  getBasketByProducer,
  ProductWithBasket
} from '_consumer/reducers/productsForSale';

type BasketOrderLinesByDeliveryDateProps = {
  basketByDeliveryDate: {
    deliveryDate: string;
    products: ProductWithBasket[];
  }[];
  deliveryFees?: DeliveryFeesResponse;
  exemptionCallback: (date: string, over: boolean) => void;
};

const BasketOrderLinesByDeliveryDate = ({
  basketByDeliveryDate = [],
  deliveryFees,
  exemptionCallback
}: BasketOrderLinesByDeliveryDateProps) => {
  return (
    <>
      {basketByDeliveryDate.map(({ deliveryDate, products }) => {
        const basketByProducer = getBasketByProducer(products);
        const calculatedFeesForDate = deliveryFees?.deliveryDayFees.find(
          day => {
            return day.date === deliveryDate;
          }
        );
        if (!calculatedFeesForDate) {
          Logger.error(
            new Error(
              `No delivery fees found for date ${deliveryDate}. This should not happen.`
            )
          );
          return null;
        }

        return (
          <Container key={deliveryDate} my="xl">
            <Typography variant="secondaryHeading">
              {capitalize(formatDate(deliveryDate))}
            </Typography>
            <DagensDeliveredOrderLines
              basket={basketByProducer}
              deliveryDate={deliveryDate}
              deliveryFees={calculatedFeesForDate}
              exemptionCallback={exemptionCallback}
            />
            <ProducerDeliveredOrderLines
              basket={basketByProducer}
              deliveryDate={deliveryDate}
              deliveryFees={calculatedFeesForDate}
            />
          </Container>
        );
      })}
    </>
  );
};

export default BasketOrderLinesByDeliveryDate;
