import { tr } from '@dagens/frontend-i18n';
import i18n from 'i18next';

export const isNotEmpty = (val: string): boolean => {
  return val.trim().length > 0;
};

type MinMax = {
  min: number;
  max: number;
};
export const isBetween = (len: number, { min, max }: MinMax): boolean => {
  return len >= min && len <= max;
};

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePassword = (password: string): boolean => {
  return password.length >= 6;
};

export const validateBankAccountNumber = (
  bankAccountNumber: string
): boolean => {
  return /^\d{11}$|^\d{14}$/.test(bankAccountNumber);
};

export const validatePasswordRepeat = (
  password: string,
  passwordRepeat: string
): boolean => {
  return validatePassword(password) && password === passwordRepeat;
};

export const validateShopUrl = (shopUrl: string): boolean => {
  return /^[A-Za-z0-9.]+$/.test(shopUrl) || shopUrl === '';
};

export type ValidationMessage<TField extends string = string> = {
  field: TField;
  message: string;
};

export type OrganizationResponse = {
  orgNumber?: string;
  status?: string;
  name?: string;
  exists?: boolean;
};

export const validateOrganization = (
  org: OrganizationResponse
): ValidationMessage<'orgNumber'> | undefined => {
  const { orgNumber = '' } = org;
  const field = 'orgNumber';

  if (org.status === 'ORGANIZATION_NOT_FOUND') {
    return {
      field,
      message: tr(i18n, 'signup:CouldNotFindOrgNumberInBusinessRegistry', {
        orgNumber
      })
    };
  }
  if (!isBetween(orgNumber.length, { min: 8, max: 9 })) {
    return { field, message: tr(i18n, 'signup:MustHaveValidOrganization') };
  }
  return undefined;
};

export const validateProducer = (
  state: ProducerSignupFields
): ValidationMessage<keyof ProducerSignupFields> | undefined => {
  const fieldMissingValidations = [
    {
      field: 'orgNumber',
      message: tr(i18n, 'signup:validation.producersOrgNumber')
    },
    {
      field: 'name',
      message: tr(i18n, 'signup:validation.producersName')
    },
    {
      field: 'contactPerson',
      message: tr(i18n, 'signup:validation.producersContactPerson')
    },
    {
      field: 'address',
      message: tr(i18n, 'signup:validation.producersContactAddress')
    },
    {
      field: 'phone',
      message: tr(i18n, 'signup:validation.producersCompanyPhone')
    },
    {
      field: 'orgNumber',
      message: tr(i18n, 'signup:validation.producersOrgNumber')
    },
    {
      field: 'acceptTerms',
      message: tr(i18n, 'signup:validation.producersAcceptTerms')
    }
  ] as const;

  return fieldMissingValidations.find(v => {
    return !state[v.field];
  });
};

export const validateConsumer = (
  state: ConsumerSignupFields
): ValidationMessage<keyof ConsumerSignupFields> | undefined => {
  const fieldMissingValidations = [
    {
      field: 'name',
      message: tr(i18n, 'signup:validation.consumerName')
    },
    {
      field: 'contactPerson',
      message: tr(i18n, 'signup:validation.consumerContactPerson')
    },
    {
      field: 'deliveryAddress',
      message: tr(i18n, 'signup:validation.consumerDeliveryAddress')
    },
    {
      field: 'phone',
      message: tr(i18n, 'signup:validation.consumerPhone')
    },
    {
      field: 'orgNumber',
      message: tr(i18n, 'signup:validation.consumerOrgNumber')
    },
    {
      field: 'acceptTerms',
      message: tr(i18n, 'signup:validation.consumerAcceptTerms')
    }
  ] as const;

  return fieldMissingValidations.find(v => {
    return !state[v.field];
  });
};

export type SignupFields = {
  email: string;
  password: string;
  passwordRepeat: string;
  howDidYouHearAboutUs: string;
};

export type DefaultFields = {
  name: string;
  contactPerson: string;
  phone: string;
  orgNumber: string;
  acceptTerms: boolean;
};

export type ConsumerFields = {
  deliveryAddress: string;
};

export type ProducerFields = {
  address: string;
};

export type ProducerSignupFields = SignupFields &
  DefaultFields &
  ProducerFields;
export type ConsumerSignupFields = SignupFields &
  DefaultFields &
  ConsumerFields;

export const validateSignup = (
  state: Record<'email' | 'password' | 'passwordRepeat', string>
): ValidationMessage<'email' | 'password' | 'passwordRepeat'> | boolean => {
  const fieldMissingValidations = [
    {
      field: 'email',
      message: tr(i18n, 'signup:validation.producersEmail')
    } as ValidationMessage<'email'>,
    {
      field: 'password',
      message: tr(i18n, 'signup:validation.producersPassword')
    } as ValidationMessage<'password'>,
    {
      field: 'passwordRepeat',
      message: tr(i18n, 'signup:validation.producersValidatePassword')
    } as ValidationMessage<'passwordRepeat'>
  ].find(v => {
    return !state[v.field];
  });

  if (fieldMissingValidations) {
    return fieldMissingValidations;
  }

  const { email, password, passwordRepeat } = state;

  const emailFormatValidation =
    !validateEmail(email) &&
    ({
      field: 'email',
      message: tr(i18n, 'signup:validation.commonEmail')
    } as ValidationMessage<'email'>);

  const passwordValidation =
    !validatePassword(password) &&
    ({
      field: 'password',
      message: tr(i18n, 'signup:validation.commonPassword')
    } as ValidationMessage<'password'>);

  const passwordRepeatValidation =
    !validatePasswordRepeat(password, passwordRepeat) &&
    ({
      field: 'passwordRepeat',
      message: tr(i18n, 'signup:validation.commonPasswordRepeat')
    } as ValidationMessage<'passwordRepeat'>);

  return (
    emailFormatValidation || passwordValidation || passwordRepeatValidation
  );
};
