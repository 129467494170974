import { useTranslation } from '@dagens/frontend-i18n';
import { Box } from '@dagens/carrot';
import { ProducerWithNumProductsForSale } from '../../../types/Producer';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import PageHeader from '@components/page-header/PageHeader';
import SearchInput from '_common/components/search/search-bar/SearchInput';
import usePageView from '_common/hooks/usePageView';
import useSimpleSearch from '_common/hooks/useSimpleSearch';
import { GridContainer } from '_common/pages/search/grid/Grid';
import ProducerCard from '_consumer/components/producer/ProducerCard';
import { countProducerProducts } from '_consumer/reducers/producers';
import { Page } from '@components/page';
import getProducerSearchFunction from 'algolia/producerIndex';
import { filterSearchHits } from 'utils/array';
import { PRODUCER_OVERVIEW_PAGE_VIEW } from 'utils/mixpanel';
import REQ, { combineReqs } from 'utils/REQ';
import { CommonNavbar } from '@components/navbar';

type Props = {
  producers: ProducerWithNumProductsForSale[];
  roleId: string | undefined;
  distributionAreaId: string | undefined;
  isMeyersAccount: boolean | undefined;
};

const ProducerListPage = ({
  roleId,
  distributionAreaId,
  isMeyersAccount,
  producers = []
}: Props) => {
  usePageView(PRODUCER_OVERVIEW_PAGE_VIEW);

  const searchFunction = getProducerSearchFunction({
    roleId,
    onlyIds: true,
    distributionAreaId,
    isMeyersAccount
  });

  const [searchString, onChange, onClear, hits] =
    useSimpleSearch(searchFunction);

  const filteredProducers = filterSearchHits(hits, producers);
  const { t } = useTranslation();
  return (
    <Page
      header={
        <>
          <CommonNavbar showLogo />
          <Box.FullWidth>
            <PageHeader
              headerText={t('common:header.producers')}
              subTitle={t('producer:independentProducers', {
                count: producers.filter(p => {
                  return p.numProductsForSale > 0;
                }).length
              })}
            />
            <SearchInput
              hasActiveSearch={!!searchString}
              value={searchString}
              onChange={onChange}
              placeholder={t('consumer:SearchForProducers')}
              onClear={onClear}
            />
          </Box.FullWidth>
        </>
      }
    >
      <GridContainer>
        {filteredProducers.map(producer => {
          return <ProducerCard key={producer._id} producer={producer} />;
        })}
      </GridContainer>
    </Page>
  );
};

const ProductListPageFetcher = () => {
  const { distributionAreaId, isMeyersAccount, producers, req, roleId } =
    useAppSelector(
      ({
        auth: { _id: roleId, distributionAreaId, isMeyersAccount },
        producers: { items: producerItems, req: producerReq },
        productsForSale: { items: productItems, req: productReq }
      }) => {
        return {
          req: combineReqs([producerReq, productReq]),
          roleId,
          distributionAreaId,
          isMeyersAccount,
          producers: countProducerProducts(producerItems, productItems)
        };
      }
    );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return (
    <ProducerListPage
      roleId={roleId}
      distributionAreaId={distributionAreaId}
      isMeyersAccount={isMeyersAccount}
      producers={producers}
    />
  );
};

export default ProductListPageFetcher;
