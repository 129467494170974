import { Container } from '@dagensmat/carrot/Layout';

import { NumberInput } from '../input-number';
import { TextInput } from '../input-text';
import { InputWithSelect } from '../input-with-select';
import { SelectMulti } from '../select-multi';
import { FormFieldErrorMessage } from './error-message';
import { FormFieldFeedback } from './feedback';
import { HelpTextContainer } from './help-text-container';
import { LabelContainer } from './label-container';
import { FormFieldBaseProps, FormFieldType, InputRenderProps } from './types';
import { useRequiredValidation } from './use-required-validation';

export type Props<SelectType extends string> = FormFieldBaseProps &
  InputRenderProps<SelectType>;

export const FormField = <SelectType extends string>({
  type,
  inputProps,
  label,
  optional = false,
  explanation,
  helpText,
  maxLength,
  valueLength,
  feedback,
  errorMessage,
  showError
}: Props<SelectType>) => {
  const hasRequiredValue = useRequiredValidation({
    value: inputProps.value,
    optional
  });
  const invalid = inputProps.invalid || !hasRequiredValue;
  const showErrorMessage = errorMessage && invalid && showError;

  return (
    <Container flex direction="column" gap="xxs" flexGrow={1}>
      <LabelContainer
        label={label}
        optional={optional}
        explanation={explanation}
      />
      <HelpTextContainer
        helpText={helpText}
        maxLength={maxLength}
        valueLength={valueLength}
      />

      {type === FormFieldType.NUMBER && (
        <NumberInput
          {...inputProps}
          invalid={invalid}
          showError={showError}
          optional={optional}
        />
      )}
      {type === FormFieldType.NUMBER_WITH_SELECT && (
        <InputWithSelect
          {...inputProps}
          invalid={invalid}
          showError={showError}
          optional={optional}
        />
      )}
      {type === FormFieldType.SELECT_MULTI && (
        <SelectMulti
          {...inputProps}
          invalid={invalid}
          showError={showError}
          optional={optional}
        />
      )}
      {type === FormFieldType.TEXT && (
        <TextInput
          {...inputProps}
          maxLength={maxLength}
          invalid={invalid}
          showError={showError}
          optional={optional}
        />
      )}

      {feedback && <FormFieldFeedback>{feedback}</FormFieldFeedback>}
      {showErrorMessage && (
        <FormFieldErrorMessage>{errorMessage}</FormFieldErrorMessage>
      )}
    </Container>
  );
};
