import { Box } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { capitalize } from '@dagensmat/core';
import { addMonths } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { getOrderHistory } from '../../../api';
import REQ, { ReqType } from '../../../utils/REQ';
import { useAppSelector } from '../../hooks/reduxHooks';
import OrderGroup from '_common/components/order-group/OrderGroup';
import PageHeader from '@components/page-header/PageHeader';
import SearchInput from '_common/components/search/search-bar/SearchInput';
import { useQueryParams } from '_common/hooks/useQueryParams';
import {
  groupOrdersByDeliveryDate,
  orderKindIsOrder,
  splitActiveAndPastOrders
} from '_common/reducers/orders';
import { ContentLoader } from '@components/content-loader';
import { CommonNavbar } from '@components/navbar';
import { Page } from '@components/page';
import { Order } from 'types/Order';
import { formatDate, getDate } from 'utils/date/format';

type OrderListProps = {
  searchQuery: string | null;
};

const OrderList = ({ searchQuery }: OrderListProps) => {
  const { t } = useTranslation();
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [orders, setOrders] = useState<Order[]>([]);
  const { roleId } = useAppSelector(({ auth }) => {
    return {
      roleId: auth._id
    };
  });

  const fetchOrderHistory = async () => {
    setReq(REQ.PENDING);

    const FETCH_PERIOD = 24;
    const FETCH_INTERVAL = 3;

    const today = new Date();
    const history = await Promise.all(
      Array.from({ length: FETCH_PERIOD / FETCH_INTERVAL }).map(
        async (_, i) => {
          const startDate = addMonths(
            today,
            -(i + 1) * FETCH_INTERVAL
          ).toISOString();
          const endDate = addMonths(today, -i * FETCH_INTERVAL).toISOString();
          const orderHistory = await getOrderHistory({
            consumerId: roleId,
            startDate,
            endDate
          });
          return orderHistory.filter(orderKindIsOrder);
        }
      )
    );

    setOrders(history.flat());
    setReq(REQ.SUCCESS);
  };

  const orderGroups = useMemo(() => {
    const { pastOrders } = splitActiveAndPastOrders(orders);

    const filteredOrders = pastOrders.filter(order => {
      if (!searchQuery || searchQuery.length < 1) {
        return true;
      }
      const orderSearchableFields = [
        order.orderNumberString,
        order.consumer.name,
        getDate(order.deliveryDate)
      ].filter(Boolean);
      const searchKeywords = searchQuery.toLowerCase().split(' ');

      return searchKeywords.every(keyword => {
        return orderSearchableFields.some(field => {
          return field?.toLowerCase().includes(keyword);
        });
      });
    });

    return groupOrdersByDeliveryDate(filteredOrders);
  }, [orders, searchQuery]);

  useEffect(() => {
    fetchOrderHistory();
  }, []);

  return (
    <ContentLoader req={req}>
      <Container mt="m">
        {orderGroups.length < 1 ? (
          <Callout>
            <Typography variant="paragraph">
              {t('common:NoOrderHistoryYet')}
            </Typography>
          </Callout>
        ) : (
          orderGroups.map(({ deliveryDate, orders: consumerOrders }) => {
            return (
              <OrderGroup
                key={deliveryDate}
                header={capitalize(formatDate(deliveryDate))}
                orders={consumerOrders}
                roleType="consumers"
              />
            );
          })
        )}
      </Container>
    </ContentLoader>
  );
};

const QUERY_KEY = 'query';

const OrdersHistoryPage = () => {
  const { getQueryValue, setQueryValue } = useQueryParams();
  const searchQuery = getQueryValue(QUERY_KEY);
  const { t } = useTranslation();

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t('common:OrderHistory')}
              subTitle={t('common:DeliveredAndCanceledOrders')}
            />
            <SearchInput
              placeholder={t('common:SearchInOrderHistory')}
              value={searchQuery || ''}
              onChange={({ target: { value } }) => {
                return setQueryValue(QUERY_KEY, value);
              }}
              onClear={() => {
                return setQueryValue(QUERY_KEY, '');
              }}
            />
          </Box.FullWidth>
        </>
      }
    >
      <OrderList searchQuery={searchQuery} />
    </Page>
  );
};

export default OrdersHistoryPage;
