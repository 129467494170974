import { Nav } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { NavMenuLogout, NavMenuSecondaryItems } from '@components/navbar/menu';
import { Navbar } from '@components/navbar/navbar';

export const AuthNavbar = () => {
  const { t } = useTranslation();
  return (
    <Navbar
      showLogo
      showActionsOnMobile
      actions={
        <Nav.Menu heading={t('common:Menu')}>
          <NavMenuSecondaryItems />
          <Nav.MenuDivider />
          <NavMenuLogout />
        </Nav.Menu>
      }
    />
  );
};
