import Theme from '@dagensmat/carrot/Theme';
import React from 'react';
import styled from 'styled-components';

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: ${Theme.Spacings.m + Theme.Spacings.xxs}px;
  margin-bottom: ${Theme.Spacings.xs}px;
  cursor: pointer;
  font-size: ${Theme.FontSizes.paragraph}px;
  line-height: ${Theme.Spacings.m}px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;

  :hover input ~ span {
    background-color: ${Theme.Colours.brightPurple};
  }

  :hover input:checked ~ span {
    background-color: ${Theme.Colours.black};
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: ${Theme.Spacings.xxs}px;
    top: 2px;
    width: 5px;
    height: ${Theme.Spacings.xs}px;
    border: solid ${Theme.Colours.white};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked ~ span {
    background-color: ${Theme.Colours.black};
  }

  :focus > label,
  :active > label {
    outline: solid black 1px;
  }
`;

const Checkmark = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 2px;
  height: ${Theme.Spacings.s + Theme.Spacings.xxs}px;
  width: ${Theme.Spacings.s + Theme.Spacings.xxs}px;
  background-color: ${Theme.Colours.white};
  border: solid ${Theme.Colours.black} 2px;
  border-radius: ${Theme.BorderRadius.small}px;

  :after {
    content: '';
    position: absolute;
    display: none;
  }
`;

type CheckboxWithLabelProps = {
  label?: React.ReactNode;
  name: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckboxWithLabel = ({
  label,
  name,
  checked,
  onChange
}: CheckboxWithLabelProps) => {
  return (
    <Container htmlFor={name}>
      {label}
      <CheckboxInput
        name={name}
        type="checkbox"
        id={name}
        checked={checked}
        onChange={onChange}
      />
      <Checkmark />
    </Container>
  );
};

export default CheckboxWithLabel;
