import {
  AvailableLanguages,
  type ConsumerOnboardingStepValue
} from '@dagensmat/core';
import { getProductsSearchFunction } from 'algolia/productsIndex';
import { mapAlgoliaDatesAndDeadlines } from 'algolia/shared';
import { post } from 'services/HttpService';
import { ProductForSale } from 'types/Product';

export type User = {
  userId: string;
};

export const postConsumerOnboardingUpdate = (payload: {
  userId: string | undefined;
  onboardingStep: ConsumerOnboardingStepValue;
}) => {
  post('/consumer/onboarding/update', payload);
};

export const getProductsForSale = async ({
  userId,
  distributionAreaId,
  roleLang,
  isMeyersAccount
}: {
  userId: string;
  distributionAreaId: string;
  roleLang: AvailableLanguages;
  isMeyersAccount?: boolean;
}): Promise<ProductForSale[]> => {
  const searchFunction = getProductsSearchFunction({
    roleId: userId,
    distributionAreaId,
    onlyIds: false,
    roleLang,
    isMeyersAccount
  });

  const hits: ProductForSale[] = await searchFunction('');

  return mapAlgoliaDatesAndDeadlines(hits, distributionAreaId);
};

export const getProducerProductsFromAlgolia = async ({
  producerId,
  roleLang = AvailableLanguages.ENGLISH,
  distributionAreaId,
  roleId,
  isMeyersAccount
}: {
  producerId: string;
  roleLang?: AvailableLanguages;
  distributionAreaId?: string;
  roleId?: string;
  isMeyersAccount?: boolean;
}) => {
  const searchFunction = getProductsSearchFunction({
    roleId,
    onlyIds: false,
    roleLang,
    distributionAreaId,
    isMeyersAccount
  });

  const hits: ProductForSale[] = await searchFunction('', {
    facetFilters: [`producer._id:${producerId}`]
  });

  return distributionAreaId
    ? mapAlgoliaDatesAndDeadlines(hits, distributionAreaId)
    : hits;
};
