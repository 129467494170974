import { useTranslation } from '@dagens/frontend-i18n';
import { Icon } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import Theme from '@dagensmat/carrot/Theme';
import styled from 'styled-components';
import Input from '_common/components/input/Input.style';

const Unbutton = styled.button`
  background: transparent;
  padding: ${Theme.Spacings.xs}px;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: ${Theme.BorderRadius.regular}px;

  :hover {
    background-color: ${Theme.Colours.brightPurple};
  }
`;

type SearchInputProps = {
  hasActiveSearch?: boolean;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  onClear: () => void;
};
const SearchInput = ({
  hasActiveSearch = false,
  value = '',
  onChange,
  onKeyDown,
  placeholder,
  onClear
}: SearchInputProps) => {
  const { t } = useTranslation();
  return (
    <Container position="relative" mb="xs">
      <Input
        type="search"
        value={value}
        onChange={onChange}
        {...(onKeyDown && { onKeyDown })}
        placeholder={placeholder || t('common:searchProductsProducers')}
      />
      {hasActiveSearch && (
        <Unbutton type="button" onClick={onClear}>
          <Icon name="clear" />
        </Unbutton>
      )}
    </Container>
  );
};
export default SearchInput;
