import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { useEffect, useState } from 'react';

import Loader from '../../../_common/components/loader/Loader';
import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { getHubsByCountry } from '../../../api';
import Logger from '../../../services/Logger';
import { useThrowError } from '../../../utils/errorHandling';
import REQ, { ReqType } from '../../../utils/REQ';
import { joinArrayOptions } from '../../../utils/texts';
import { DeliveryType, Hub } from 'types/Logistics';
import { useMediaQuery } from '_common/hooks/useMediaQueries';
import DeliveryTypeTag from '_common/components/tags/DeliveryTypeTag';

export type DetailPair = {
  detail: string;
  value: string;
};

type DeliveryOptionProps = {
  deliveryTypesToShow: DeliveryType[];
};

const DeliveryOption = ({
  deliveryTypesToShow: deliveryTypeToShow
}: DeliveryOptionProps) => {
  const [hubsByCountry, setHubsByCountry] = useState<Hub[]>([]);
  const [fetchHubsReq, setFetchHubsReq] = useState<ReqType>(REQ.INIT);

  const { t } = useTranslation();
  const mediaQuery = useMediaQuery();
  const throwAsyncError = useThrowError();

  const { country, transactionCut, transactionCutForSelfDelivery } =
    useAppSelector(({ auth }) => {
      return {
        country: auth.organization?.address.country,
        transactionCut: auth.transactionCut,
        transactionCutForSelfDelivery: auth.transactionCutForSelfDelivery
      };
    });

  const fetchHubsByCountry = async () => {
    setFetchHubsReq(REQ.PENDING);
    if (!country) {
      return;
    }

    await getHubsByCountry({ country })
      .then(res => {
        setFetchHubsReq(REQ.SUCCESS);
        setHubsByCountry(res);
      })
      .catch(e => {
        setFetchHubsReq(REQ.ERROR);
        Logger.error(e);
        throwAsyncError(e);
      });
  };

  useEffect(() => {
    fetchHubsByCountry();
  }, []);

  if (fetchHubsReq !== REQ.SUCCESS) {
    return <Loader />;
  }
  const transactionCutPercent = (transactionCut ?? 0) * 100;
  const transactionCutForSelfDeliveryPercent =
    (transactionCutForSelfDelivery ?? 0) * 100;
  const countryText = country === 'no' ? 'Oslo' : t('Copenhagen');
  const countryDetails: DetailPair[] = [
    {
      detail: t(
        'producer:DeliveryRouteRequest.DagensDeliveryHubsLocationDetail'
      ),
      value: joinArrayOptions(
        hubsByCountry.map(hub => {
          return hub.hubArea;
        }),
        t('common:and')
      )
    },
    {
      detail: t(
        'producer:DeliveryRouteRequest.DagensDeliveryDropOffDaysDetail'
      ),
      value:
        country === 'no'
          ? t('producer:DeliveryRouteRequestPage.OsloHubDropOffDays')
          : t('producer:DeliveryRouteRequestPage.CopenhagenHubDropOffDays')
    },
    {
      detail: t('producer:DeliveryRouteRequest.DagensDeliveryPriceDetail'),
      value: t('producer:DeliveryRouteRequest.DagensDeliveryPriceValue', {
        transactionCutPercent
      })
    }
  ];

  const selfDeliveryDetails: DetailPair[] = [
    {
      detail: t('common:deliveryDays'),
      value: t('producer:DeliveryRouteRequest.SelfDeliveryDays')
    },
    {
      detail: t('producer:DeliveryRouteRequest.DagensDeliveryPriceDetail'),
      value: t('producer:DeliveryRouteRequest.SelfDeliveryPriceValue', {
        transactionCutForSelfDeliveryPercent
      })
    }
  ];

  return (
    <>
      {deliveryTypeToShow.map((deliveryType, i) => {
        const details =
          deliveryType === DeliveryType.DAGENS_DELIVERY
            ? countryDetails
            : selfDeliveryDetails;

        const dagensDeliveryInstructions = `${t(
          'producer:DeliveryRouteRequest.DagensDeliveryDescription',
          { countryText }
        )} ${t('producer:DagensTakesCareOfDelivery')} `;

        const selfDeliveryInstructions = t(
          'producer:DeliveryRouteRequest.RadioButtonDeliveryTypeSelfExplanationText'
        );

        const instructions =
          deliveryType === DeliveryType.DAGENS_DELIVERY
            ? dagensDeliveryInstructions
            : selfDeliveryInstructions;
        return (
          <Container
            key={deliveryType}
            backgroundColour="white"
            px="s"
            py="s"
            mb={deliveryTypeToShow.length !== i + 1 ? 's' : 'none'}
          >
            <Container flex mb="xs">
              <DeliveryTypeTag deliveryType={deliveryType} />
            </Container>
            <Typography variant="paragraph" mb="s">
              {instructions}
            </Typography>

            {details.map(detail => {
              return (
                <Container
                  key={detail.detail}
                  flex
                  direction={mediaQuery.tabletUp ? 'row' : 'column'}
                  wrap="wrap"
                  mb="xs"
                >
                  <Typography
                    variant="paragraphSmall"
                    as="span"
                    mr="xxs"
                    color="secondary"
                  >
                    {detail.detail}:
                  </Typography>
                  <Typography variant="paragraphSmall" as="span">
                    {detail.value}
                  </Typography>
                </Container>
              );
            })}
          </Container>
        );
      })}
    </>
  );
};

export default DeliveryOption;
