import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import HorizontalScrollContainer from '_common/components/utils/HorisontalScrollContainer';

type HighlightedContentSectionProps = {
  sectionTitle: string;
  children: React.ReactChild;
};

const HighlightedContentSection = ({
  sectionTitle,
  children
}: HighlightedContentSectionProps) => {
  return (
    <Container mt="xs">
      <Typography variant="tertiaryHeading">{sectionTitle}</Typography>
      <HorizontalScrollContainer>{children}</HorizontalScrollContainer>
    </Container>
  );
};

export default HighlightedContentSection;
