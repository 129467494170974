import Theme from '@dagensmat/carrot/Theme';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';
import { MediaQuery } from 'utils/mediaQueries';

export const GridContainer = styled.div`
  --span: 1;
  display: grid;
  grid-template-columns: repeat(var(--span), 1fr);
  grid-template-rows: auto;
  gap: ${Theme.Spacings.xs}px;

  ${MediaQuery.tabletUp} {
    --span: 2;
    gap: ${Theme.Spacings.s}px;
  }

  ${MediaQuery.desktopUp} {
    --span: 3;
  }
`;

type GridProps = {
  children: React.ReactChild;
  hasMore: boolean;
  loadMore: () => void;
};

const Grid = ({ hasMore, loadMore, children }: GridProps) => {
  return (
    <InfiniteScroll hasMore={hasMore} loadMore={loadMore}>
      <GridContainer>{children}</GridContainer>
    </InfiniteScroll>
  );
};

export default Grid;
