import { Container } from '@dagensmat/carrot/Layout';
import { capitalize } from '@dagensmat/core';
import LinkListElement from '_common/components/link-list-element/LinkListElement';
import StatusTag from '_common/components/status-tag/ProductRequestStatusTag';
import { ProductRequest } from 'types/Product';
import { formatDate } from 'utils/date/format';

type ProductRequestListProps = {
  items: ProductRequest[];
};

const ProductRequestList = ({ items = [] }: ProductRequestListProps) => {
  return (
    <Container mt="m">
      {items.map(
        ({
          _id,
          _createdAt,
          counterPartName,
          isOpened,
          hasNewMessage,
          pathname
        }) => {
          return (
            <LinkListElement
              key={_id}
              header={capitalize(formatDate(_createdAt))}
              subTitle={counterPartName}
              to={pathname}
              state={{ returnPath: true }}
            >
              <StatusTag opened={isOpened} hasNewMessage={hasNewMessage} />
            </LinkListElement>
          );
        }
      )}
    </Container>
  );
};

export default ProductRequestList;
