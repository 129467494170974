import { useEffect, useState } from 'react';
import { useTranslation } from '@dagens/frontend-i18n';
import { Form } from '@dagens/carrot';
import { FormField } from '../../carrot/form-field';
import { DeliveryWindow } from '../../types/Consumer';
import { FormFieldType } from '../../carrot/form-field/types';
import { DeliveryWindowField } from './DeliveryWindowField';
import { DeliveryAddressField } from '@components/delivery-address-field/DeliveryAddressField';

type Props = {
  deliveryAddress: string;
  onDeliveryAddressChange: (address: string) => void;
  deliveryWindow: DeliveryWindow;
  earliestDeliveryWindowHour: number;
  latestDeliveryWindowHour: number;
  minDeliveryWindowDuration: number;
  onDeliveryWindowChange: (deliveryWindow: DeliveryWindow) => void;
  deliveryInstructions: string;
  onDeliveryInstructionsChange: (instructions: string) => void;
  onErrorChange?: (hasError: boolean) => void;
};

export const DeliveryInformationForm = ({
  deliveryAddress,
  deliveryWindow,
  earliestDeliveryWindowHour,
  latestDeliveryWindowHour,
  minDeliveryWindowDuration,
  deliveryInstructions,
  onDeliveryAddressChange,
  onDeliveryWindowChange,
  onDeliveryInstructionsChange,
  onErrorChange
}: Props) => {
  const [hasErrorDeliveryAddress, setHasErrorDeliveryAddress] = useState(false);
  const [hasErrorDeliveryWindow, setHasErrorDeliveryWindow] = useState(false);
  const { t } = useTranslation();

  const handleDeliveryAddressChange = (address: string) => {
    onDeliveryAddressChange(address);
  };

  useEffect(() => {
    if (hasErrorDeliveryAddress || hasErrorDeliveryWindow) {
      onErrorChange && onErrorChange(true);
    } else {
      onErrorChange && onErrorChange(false);
    }
  }, [hasErrorDeliveryAddress, hasErrorDeliveryWindow]);

  return (
    <Form>
      <DeliveryAddressField
        deliveryAddress={deliveryAddress}
        onDeliveryAddressChange={handleDeliveryAddressChange}
        onErrorChange={(hasError: boolean) => {
          setHasErrorDeliveryAddress(hasError);
        }}
      />
      <DeliveryWindowField
        deliveryWindow={deliveryWindow}
        earliestDeliveryWindowHour={earliestDeliveryWindowHour}
        latestDeliveryWindowHour={latestDeliveryWindowHour}
        minDeliveryWindowDuration={minDeliveryWindowDuration}
        onDeliveryWindowChange={onDeliveryWindowChange}
        onErrorChange={(hasError: boolean) => {
          setHasErrorDeliveryWindow(hasError);
        }}
      />
      <FormField
        type={FormFieldType.TEXT}
        label={t(
          'common:deliveryInformationFormFieldDeliveryInstructionsLabel'
        )}
        helpText={t(
          'common:deliveryInformationFormFieldDeliveryInstructionsDescription'
        )}
        optional
        inputProps={{
          value: deliveryInstructions,
          onChange: ({ target: { value } }) => {
            onDeliveryInstructionsChange(value);
          }
        }}
      />
    </Form>
  );
};
