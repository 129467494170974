import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { shuffle } from 'lodash';
import { useMemo, useState } from 'react';
import { MinimumOrderContainer } from './SuggestedProducts.style';
import SmallProductCard from '_common/components/product-card/SmallProductCard';
import HorizontalScrollContainer from '_common/components/utils/HorisontalScrollContainer';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { RootState } from 'store';
import { CLICK_BASKET_SMALL_PRODUCT_CARD, track } from 'utils/mixpanel';

type Props = {
  producerName?: string;
  producerId: string;
  deliveryDate: string;
  underMinimum: boolean;
};

const SuggestedProducts = ({
  producerName,
  deliveryDate,
  producerId,
  underMinimum
}: Props) => {
  const { productsForSale } = useAppSelector(
    ({ productsForSale: { items } }: RootState) => {
      return {
        productsForSale: items
      };
    }
  );

  const onCardClick = (productId: string) => {
    track(CLICK_BASKET_SMALL_PRODUCT_CARD, { productId });
  };

  const suggestionsFilter = () => {
    const onlyByProducer = productsForSale
      .filter(p => {
        return p.producer._id === producerId;
      })
      .filter(p => {
        return p.forSaleStatus === 'FOR_SALE';
      })
      .filter(p => {
        return p.deliveryDates.includes(deliveryDate);
      })
      .filter(p => {
        return !p.soldOutDates?.includes(deliveryDate);
      });

    return shuffle(onlyByProducer);
  };

  const suggestions = useMemo(() => {
    return suggestionsFilter();
  }, [productsForSale]);

  const [minimumWasUnder] = useState(() => {
    return underMinimum;
  });

  if (suggestions.length === 0) return null;
  const { t } = useTranslation();

  if (!minimumWasUnder) return null;
  return (
    <MinimumOrderContainer>
      <Typography variant="paragraphSmallBold" mt="xxs" as="p">
        {t('consumer:OtherProductsByProducerHeader', {
          producerName
        })}
      </Typography>
      <HorizontalScrollContainer>
        <>
          {suggestions.map(product => {
            return (
              <SmallProductCard
                product={product}
                key={product._id}
                onClick={() => {
                  onCardClick(product._id);
                }}
                deliveryDate={deliveryDate}
              />
            );
          })}
        </>
      </HorizontalScrollContainer>
    </MinimumOrderContainer>
  );
};

export default SuggestedProducts;
