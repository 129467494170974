import { createBrowserRouter, redirect } from 'react-router-dom';
import CreateConsumerPage from '../_common/pages/create-role/CreateConsumerPage';
import CreateProducerPage from '../_common/pages/create-role/CreateProducerPage';
import RootlessUserPage from './RootlessUserPage';
import CookiesInfoPage from '_common/pages/CookiesInfoPage';
import LogoutPage from '_common/pages/LogoutPage';
import ResetPasswordPage from '_common/pages/ResetPasswordPage';
import VerifyUserEmail from '_common/pages/VerifyUserEmail';
import Signup from '_unauthenticated/pages/signup/Signup';

export const authRouter = () => {
  return createBrowserRouter([
    {
      path: '/',
      children: [
        {
          index: true,
          element: <RootlessUserPage />
        },

        { path: '/logout', element: <LogoutPage /> },
        { path: '/signup', element: <Signup /> },
        { path: '/reset-password', element: <ResetPasswordPage /> },
        { path: '/cookies', element: <CookiesInfoPage /> },
        { path: '/user/verify/:token', element: <VerifyUserEmail /> },
        { path: '/create-consumer', element: <CreateConsumerPage /> },
        { path: '/create-producer', element: <CreateProducerPage /> },

        {
          path: '*',
          loader: () => {
            return redirect('/');
          }
        }
      ]
    }
  ]);
};
