import { useTranslation, tr } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import Theme from '@dagensmat/carrot/Theme';
import { forSaleStatuses } from '@dagensmat/core';
import i18n from 'i18next';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';

import CalendarMonths from './CalendarMonths';
import SaleStatusFilters from './SaleStatusFilters';
import SeasonRow from './SeasonRow';
import { getYearProgressPercentage } from 'utils/date/format';
import { ForSaleStatus, ProducerProduct, ProductForSale } from 'types/Product';
import Button from '_common/components/button/Button.style';

const CalendarContainer = styled.div`
  margin: ${Theme.Spacings.l}px 0;
`;

const Calendar = styled.div`
  position: relative;
  width: 100%;
  margin: ${Theme.Spacings.l}px 0 ${Theme.Spacings.s}px;
`;

const RowsContainer = styled.div`
  padding-top: ${Theme.Spacings.m}px;
`;

const DateIndicator = styled.div<{ left: number }>`
  position: absolute;
  top: 0;
  left: ${({ left }) => {
    return left;
  }}%;

  width: 2px;
  background: ${Theme.Colours.black};
  height: 100%;
`;

const DateIndicatorTag = styled.div<{ left: number }>`
  text-transform: uppercase;
  position: absolute;
  top: ${-Theme.Spacings.m}px;
  left: ${({ left }) => {
    return `${left}%`;
  }};
  transform: translate3d(-50%, 0, 0);
  padding: 0 ${Theme.Spacings.xs}px;
  background: ${Theme.Colours.black};
  color: ${Theme.Colours.white};
  border-radius: ${Theme.BorderRadius.small}px;
  font-size: ${Theme.FontSizes.allCapsTitle}px;
  line-height: ${Theme.Spacings.m}px;
`;

type MultiProductSeasonCalendarProps = {
  products?: (ProducerProduct | ProductForSale)[];
  isProducer?: boolean;
  heading?: ReactNode;
  countProductsToShow?: number;
  onClick?: (productId: string) => void;
};

const SHORT_LIST_CAP = 7;
const MultiProductSeasonCalendar = ({
  products = [],
  isProducer = false,
  heading = tr(i18n, 'common:overview'),
  countProductsToShow = SHORT_LIST_CAP,
  onClick
}: MultiProductSeasonCalendarProps) => {
  const [showAll, toggleShowAll] = useState(false);
  const [saleStatusFilter, setSaleStatusFilter] = useState<ForSaleStatus[]>([
    forSaleStatuses.FOR_SALE,
    forSaleStatuses.ON_REQUEST
  ]);
  const left = getYearProgressPercentage();
  const filteredProducts = products.filter(({ forSaleStatus }) => {
    return saleStatusFilter.includes(forSaleStatus);
  });
  const productsToShow = showAll
    ? filteredProducts
    : filteredProducts.slice(0, countProductsToShow);
  const { t } = useTranslation();
  return (
    <CalendarContainer>
      <Typography variant="tertiaryHeading">{heading}</Typography>
      {isProducer && (
        <SaleStatusFilters
          activeFilters={saleStatusFilter}
          setActiveFilters={value => {
            return setSaleStatusFilter(value as ForSaleStatus[]);
          }}
        />
      )}
      <Calendar>
        <CalendarMonths multi />
        <RowsContainer>
          {productsToShow.map(product => {
            return (
              <SeasonRow
                key={product._id}
                product={product}
                isProducer={isProducer}
                onClick={onClick}
              />
            );
          })}
        </RowsContainer>
        <DateIndicator left={left} />
        <DateIndicatorTag left={left}>{t('common:today')}</DateIndicatorTag>
      </Calendar>
      {products.length > countProductsToShow && (
        <Button
          variant="borderless"
          onClick={() => {
            toggleShowAll(!showAll);
          }}
        >
          {showAll ? t('common:showLessDot') : t('common:showAllDot')}
        </Button>
      )}
    </CalendarContainer>
  );
};

export default MultiProductSeasonCalendar;
