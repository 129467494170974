import { Form } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { Container as CarrotContainer } from '@dagensmat/carrot/Layout';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { FormField } from '../../../carrot/form-field';
import { FormFieldType } from '../../../carrot/form-field/types';
import { BillingAccount } from '../../../types/BillingAccount';
import { validateEmail } from '../../../utils/validation';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { isValidEan } from '../../../utils/billingAccount';
import REQ, { ReqType } from 'utils/REQ';
import { postBillingAccountUpdate } from 'api';
import { Page } from '@components/page';
import { BottomActions } from '@components/bottom-actions';
import { ActionButton } from '@components/action-button';
import {
  billingAccountUpdated,
  billingAddressUpdated
} from '_common/reducers/auth';
import PageHeader from '@components/page-header/PageHeader';
import InputWithLabel from '_common/components/input/InputWithLabel';

const FormWrapper = styled.div`
  max-width: 490px;
  width: 100%;
  position: relative;
`;

const BillingAccountPage = () => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const dispatch = useAppDispatch();
  const {
    type,
    billingAccountId,
    name,
    emails,
    addressLine1,
    postalCode,
    city,
    country,
    bankAccountNumber,
    department,
    reminderEmail,
    organizationNumber,
    eanNumber
  } = useAppSelector(({ auth: { _type: type, billingAccount } }) => {
    const {
      _id: billingAccountId,
      name,
      emails,
      address,
      bankAccountNumber,
      department,
      reminderEmail,
      organizationNumber,
      eanNumber
    } = billingAccount ?? {};
    const { addressLine1, postalCode, city, country } = address ?? {};
    return {
      type,
      billingAccountId,
      name,
      emails,
      addressLine1,
      postalCode,
      city,
      country,
      bankAccountNumber,
      department,
      reminderEmail,
      organizationNumber,
      eanNumber
    };
  });
  const [hasChanges, setHasChanges] = useState(false);
  const [validEmail, setEmailValid] = useState(true);
  const [validReminder, setReminderValid] = useState(true);

  useEffect(() => {
    setEmailValid(Boolean(emails?.every(validateEmail)));
    setReminderValid(
      reminderEmail !== undefined && validateEmail(reminderEmail)
    );
  }, [emails, reminderEmail]);

  const onSubmit = async () => {
    setHasChanges(false);
    setReq(REQ.PENDING);

    try {
      await postBillingAccountUpdate({
        billingAccountId,
        name,
        department,
        address: {
          addressLine1,
          postalCode,
          city,
          country
        },
        eanNumber,
        bankAccountNumber,
        emails,
        reminderEmail
      });
      setReq(REQ.SUCCESS);
    } catch (error) {
      setReq(REQ.ERROR);
    }
  };

  const onChange = (
    fields: Partial<
      Pick<
        BillingAccount,
        | 'name'
        | 'department'
        | 'bankAccountNumber'
        | 'eanNumber'
        | 'emails'
        | 'reminderEmail'
      >
    >
  ) => {
    dispatch(billingAccountUpdated(fields));
    setHasChanges(true);
  };

  const onAddressChange = (
    fields: Partial<
      Pick<
        BillingAccount['address'],
        'addressLine1' | 'postalCode' | 'city' | 'country'
      >
    >
  ) => {
    dispatch(billingAddressUpdated(fields));
    setHasChanges(true);
  };

  const { t } = useTranslation();

  return (
    <Page
      bottom={
        <BottomActions>
          <ActionButton.Save
            onClick={onSubmit}
            disabled={!hasChanges || !validReminder || !validEmail}
            saveReq={req}
            redirectTo={-1}
          >
            {t('common:BillingAccountSaveButton')}
          </ActionButton.Save>
        </BottomActions>
      }
    >
      <PageHeader headerText={t('common:BillingAccountHeader')} />
      <FormWrapper>
        <Typography variant="secondaryHeading" mb="m">
          {t('common:GeneralInformationHeader')}
        </Typography>
        <Form>
          <FormField
            label={t('common:Name')}
            inputProps={{
              value: name,
              placeholder: t('common:Name'),
              onChange: ({ target: { value } }) => {
                onChange({ name: value });
              }
            }}
            helpText={t('common:NameHelper')}
            type={FormFieldType.TEXT}
          />
          <FormField
            label={t('common:Department')}
            inputProps={{
              value: department,
              placeholder: t('common:Department'),
              onChange: ({ target: { value } }) => {
                onChange({ department: value });
              }
            }}
            helpText={t('common:DepartmentHelper')}
            type={FormFieldType.TEXT}
            optional
          />
          <InputWithLabel
            labelText={t('common:OrganizationNumber')}
            value={organizationNumber}
            margin={0}
            disabled
          />
        </Form>
        <Typography variant="secondaryHeading" mb="m">
          {t('common:BillingAddressHeader')}
        </Typography>
        <Form>
          <FormField
            label={t('common:Address')}
            inputProps={{
              value: addressLine1,
              placeholder: t('common:Address'),
              onChange: ({ target: { value } }) => {
                onAddressChange({ addressLine1: value });
              }
            }}
            helpText={t('common:AddressHelper')}
            type={FormFieldType.TEXT}
          />
          <CarrotContainer flex direction="row" wrap="nowrap" gap="s">
            <CarrotContainer>
              <FormField
                inputProps={{
                  value: postalCode,
                  placeholder: t('common:PostalCode'),
                  onChange: ({ target: { value } }) => {
                    onAddressChange({ postalCode: value });
                  }
                }}
                helpText={t('common:PostalCodeHelper')}
                type={FormFieldType.TEXT}
              />
            </CarrotContainer>
            <CarrotContainer>
              <FormField
                inputProps={{
                  value: city,
                  placeholder: t('common:City'),
                  onChange: ({ target: { value } }) => {
                    onAddressChange({ city: value });
                  }
                }}
                helpText={t('common:CityHelper')}
                type={FormFieldType.TEXT}
              />
            </CarrotContainer>
          </CarrotContainer>
        </Form>
        {(country === 'dk' || type === 'producers') && (
          <>
            <Typography variant="secondaryHeading" mb="m">
              {t('common:BankAccountHeader')}
            </Typography>
            <Form>
              {country === 'dk' && type === 'consumers' && (
                <FormField
                  label="EAN nummer"
                  type={FormFieldType.TEXT}
                  inputProps={{
                    value: eanNumber,
                    placeholder: t('common:EanNumberInputPlaceholder'),
                    onChange: ({ target: { value } }) => {
                      return onChange({ eanNumber: value });
                    },
                    invalid: !isValidEan(eanNumber)
                  }}
                  maxLength={13}
                  valueLength={eanNumber ? eanNumber.length : 0}
                  helpText={t('common:EanNumberExplanation')}
                  errorMessage={t('common:EanNumberErrorMessage')}
                  showError={!isValidEan(eanNumber)}
                />
              )}
              {type === 'producers' && (
                <FormField
                  label={t('common:BankAccountNumber')}
                  inputProps={{
                    value: bankAccountNumber,
                    placeholder:
                      country === 'dk' ? 'xxxx xxxxxxxxxx' : 'xxxx xx xxxxx',
                    onChange: ({ target: { value } }) => {
                      onChange({ bankAccountNumber: value });
                    }
                  }}
                  type={FormFieldType.TEXT}
                />
              )}
            </Form>
          </>
        )}
        <Typography variant="secondaryHeading" mb="m">
          {t('common:InvoicingSettingsHeader')}
        </Typography>
        <Form>
          <FormField
            label={t('common:InvoiceEmailLabel')}
            inputProps={{
              value: emails?.join(', '),
              placeholder: t('common:InvoiceEmailPlaceholder'),
              onChange: ({ target: { value } }) => {
                onChange({ emails: value.split(', ') });
              },
              invalid: !validEmail
            }}
            showError={!validEmail}
            type={FormFieldType.TEXT}
          />
          <FormField
            label={t('common:InvoiceReminderEmailLabel')}
            inputProps={{
              value: reminderEmail,
              placeholder: t('common:InvoiceReminderEmailPlaceholder'),
              onChange: ({ target: { value } }) => {
                onChange({ reminderEmail: value });
              },
              invalid: !validReminder
            }}
            helpText={t('common:InvoiceReminderEmailHelper')}
            showError={!validReminder}
            type={FormFieldType.TEXT}
          />
        </Form>
      </FormWrapper>
    </Page>
  );
};

export default BillingAccountPage;
