import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import Theme from '@dagensmat/carrot/Theme';
import { capitalize } from '@dagensmat/core';
import styled from 'styled-components';
import { updateSelectedDeliveryDate } from '_consumer/reducers/productsForSale';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import CloseButton from '_common/components/button/CloseButton';
import { formatDate } from 'utils/date/format';
import { track, UNSELECT_DELIVERY_DAY } from 'utils/mixpanel';

const Banner = styled.div`
  width: 100%;
  margin-top: ${Theme.Spacings.s}px;
  padding: 0px ${Theme.Spacings.s}px;
  z-index: 998;
  background-color: ${Theme.Colours.noteworthy};
  border-radius: ${Theme.BorderRadius.regular}px;
`;

const DeliveryDateBanner = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedDeliveryDate } = useAppSelector(({ productsForSale }) => {
    return {
      selectedDeliveryDate: productsForSale.selectedDeliveryDate
    };
  });

  const resetDeliveryDate = () => {
    track(UNSELECT_DELIVERY_DAY);
    dispatch(updateSelectedDeliveryDate(undefined));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Banner>
      <Container flex alignItems="baseline" justify="space-between">
        <Typography variant="paragraph">
          {t('consumer:ProduceForDeliveryOnDate', {
            selectedDeliveryDate: capitalize(
              formatDate(selectedDeliveryDate, 'iii d. MMM')
            )
          })}
        </Typography>
        <CloseButton
          onClick={resetDeliveryDate}
          style={{
            marginRight: -Theme.Spacings.s,
            padding: Theme.Spacings.s
          }}
        />
      </Container>
    </Banner>
  );
};

export default DeliveryDateBanner;
