import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import LinkListElement from '_common/components/link-list-element/LinkListElement';
import OrderStatusTag from '_common/components/status-tag/OrderStatusTag';
import { getTotalOrderPrice, isOrderSmall } from '_common/reducers/orders';
import { Order } from 'types/Order';
import { PRODUCER_HOME_TOP_UP_ORDER_LINK, track } from 'utils/mixpanel';
import { formatNok2Decimals } from 'utils/texts';

type ProducerOrderGroupProps = {
  headerText?: string;
  orders: Order[];
  showTags?: boolean;
};

const ProducerOrderGroup = ({
  headerText = '',
  orders = [],
  showTags = true
}: ProducerOrderGroupProps) => {
  return (
    <>
      {headerText && (
        <Container flex justify="space-between" alignItems="baseline">
          <Typography mr="s" mb="xs" variant="secondaryHeading">
            {headerText}
          </Typography>
        </Container>
      )}
      <>
        {orders.map(order => {
          const {
            _id,
            secret,
            consumer: { name: consumerName },
            orderLines,
            orderNumberString,
            messageFromConsumerToProducer,
            statuses
          } = order;
          const orderTotal = getTotalOrderPrice(orderLines);
          const isSmallOrder = isOrderSmall(order);
          const trackClick = () => {
            track(PRODUCER_HOME_TOP_UP_ORDER_LINK, {
              consumerName,
              orderTotal,
              isSmallOrder
            });
          };

          return (
            <LinkListElement
              key={_id}
              header={`${consumerName} (${orderNumberString})`}
              subTitle={formatNok2Decimals(orderTotal)}
              to={`/orders/${secret}`}
              state={{ returnPath: true }}
              onClick={trackClick}
            >
              {showTags && (
                <OrderStatusTag
                  orderId={_id}
                  statuses={statuses}
                  producer
                  messageFromConsumerToProducer={messageFromConsumerToProducer}
                  isSmallOrder={isSmallOrder}
                />
              )}
            </LinkListElement>
          );
        })}
      </>
    </>
  );
};

export default ProducerOrderGroup;
