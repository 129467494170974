import { useTranslation } from '@dagens/frontend-i18n';

import { ProcessedState } from '../../../types/Product';
import NewRadioButtons from '_common/components/radio-buttons/NewRadioButtons';

type ProcessedStateSelectorProps = {
  onChange: (buttonOption: ProcessedState) => void;
  value: ProcessedState | undefined;
};

const ProcessedStateSelector = ({
  onChange,
  value
}: ProcessedStateSelectorProps) => {
  const { t } = useTranslation();

  return (
    <NewRadioButtons
      labelText={t('producer:ProcessedStateLabel')}
      options={[
        {
          key: 'RAW',
          value: t('producer:Raw'),
          explanationText: t('producer:ExplanationTextRawProcessedState')
        },
        {
          key: 'PROCESSED',
          value: t('producer:Processed'),
          explanationText: t('producer:ExplanationTextProcessedProcessState')
        }
      ]}
      currentValue={value}
      radioGroupName="processedState"
      onChange={newValue => {
        onChange(newValue as ProcessedState);
      }}
    />
  );
};
export default ProcessedStateSelector;
