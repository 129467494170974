import { tv } from 'tailwind-variants';

const style = tv({
  base: `
    text-allCaps
    inline-flex
    h-s
    items-center
    justify-center
    rounded-[12px]
    bg-dagensPurple
    px-xxs
    py-[2px]
    !text-black

    print:hidden
  `,
  variants: {
    warning: {
      true: 'bg-warning'
    }
  },
  slots: {
    // This is a fun one:
    // Letter spacing adds space to the right side of the box that contains each character
    // This space is usual pretty small, so it isn't that noticeable in most places
    // But here, where the text is usually 1 character long, and the text is centered,
    // it causes the text to be slightly off-centered.
    // Padding on the left for the same amount fixes this.
    text: 'pl-[2px]'
  }
});

type Props = {
  text: string;
  warning?: boolean;
};

export const Badge = ({ text, warning }: Props) => {
  const { base, text: textStyle } = style();
  return (
    <div className={base({ warning })}>
      <div className={textStyle()}>{text}</div>
    </div>
  );
};
