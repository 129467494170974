import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { initialRange } from '_common/components/date-range-picker/DateRangePicker';
import DateRangePickerWithButtons from '_common/components/date-range-picker/DateRangePickerWithButtons';
import SectionHOC from '_common/components/section-hoc/SectionHOC';
import useWindowWidth from '_common/hooks/useWindowWidth';
import { Period } from 'types/Product';
import { slugifyPeriod } from 'utils/date/convert';
import { parseDate } from 'utils/date/format';
import { numberOfMonths } from 'utils/dayPicker';
import { formatPeriod } from 'utils/season';

type ProfileUnavailableSectionProps = {
  dates?: Period;
  onFieldsChange: (fields: any) => Promise<any>;
  isSaving: boolean;
};

const ProfileUnavailableSection = ({
  dates,
  onFieldsChange,
  isSaving
}: ProfileUnavailableSectionProps) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth() ?? 0;
  const [period, setPeriod] = useState<DateRange>(initialRange);

  useEffect(() => {
    if (dates) {
      setPeriod({ from: parseDate(dates.from), to: parseDate(dates.to) });
    }
  }, []);

  return (
    <>
      {dates?.from && dates?.to && (
        <Typography variant="paragraph">
          <strong>{t('producer:tempClosedPeriod')}</strong>&nbsp;
          {formatPeriod(dates)}
        </Typography>
      )}
      <DateRangePickerWithButtons
        onSave={newPeriod => {
          return onFieldsChange({
            temporarilyUnavailable: slugifyPeriod(newPeriod)
          });
        }}
        value={period}
        onChange={newRange => {
          return setPeriod(newRange);
        }}
        monthsToShow={numberOfMonths({ width: windowWidth, desktop: 3 })}
        resetButtonText={t('common:reset')}
        saveButtonText={t('producer:tempClosedSaveButtonText')}
      />
      {isSaving && (
        <Typography variant="paragraph" my="s" color="secondary">
          {t('common:Saving')}
        </Typography>
      )}
    </>
  );
};

export default SectionHOC(ProfileUnavailableSection);
