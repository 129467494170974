import { Typography } from '@dagensmat/carrot/Components';
import Theme from '@dagensmat/carrot/Theme';
import styled from 'styled-components';

export const DayTotalContainer = styled.div`
  margin-top: ${Theme.Spacings.m}px;
`;

export const DayTotal = styled(Typography)`
  order: 1;
  text-align: right;
  flex: auto;
`;
