import { tv } from 'tailwind-variants';

import { LinkComponent, LinkComponentProps } from '../components/as-link';

const style = tv({
  base: `
    !text-paragraphBold
    text-black
  `,
  variants: {
    type: {
      secondary: '!text-paragraph'
    }
  }
});

type Props = Omit<LinkComponentProps, 'styles' | 'className' | 'as'> & {
  secondary?: boolean;
};

export const MenuItem = ({ secondary, ...props }: Props) => {
  return (
    <LinkComponent
      {...props}
      as="link"
      variant="borderless"
      size="small"
      alignment="left"
      className={style({ type: secondary ? 'secondary' : undefined })}
    />
  );
};
