import Theme from '@dagensmat/carrot/Theme';
import React from 'react';
import Button from './Button.style';
import { MarginProps } from 'utils/style';

import Icon from '_common/components/utils/Icon';

type SelectedButtonProps = {
  onClick: () => void;
  children?: React.ReactNode;
} & MarginProps;

const SelectedButton = ({ children, ...props }: SelectedButtonProps) => {
  return (
    <Button variant="secondary" {...props}>
      {children}
      <Icon icon="clear" ml={Theme.Spacings.xxs} />
    </Button>
  );
};

export default SelectedButton;
