import { useTranslation } from '@dagens/frontend-i18n';
import { Button, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { CountryCode } from '@dagensmat/core';
import ReactPlayer from 'react-player/vimeo';
import usePageView from '_common/hooks/usePageView';
import { useMediaQuery } from '_common/hooks/useMediaQueries';
import ResponsiveImage from '_common/components/utils/ResponsiveImage.style';
import { Illustrations } from 'utils/Illustrations';
import {
  INTRO_SCREEN_ONE_CLICK_NEXT,
  INTRO_SCREEN_ONE_CLICK_SKIP_INTRO,
  INTRO_SCREEN_ONE_PAGE_VIEW,
  track
} from 'utils/mixpanel';

type PageOneProps = {
  contactPerson: string | undefined;
  name: string | undefined;
  next: () => void;
  skip: () => void;
  country: string;
};

const PageOne = ({
  contactPerson,
  name,
  next,
  skip,
  country
}: PageOneProps) => {
  usePageView(INTRO_SCREEN_ONE_PAGE_VIEW);
  const { t } = useTranslation();
  const mediaQuery = useMediaQuery();

  const getImagePadding = () => {
    if (mediaQuery.desktopUp) return 'jumbo';
    return mediaQuery.tabletUp ? 'xl' : undefined;
  };
  return (
    <Container
      flex
      flexGrow={1}
      direction="column"
      alignItems="stretch"
      justify="space-between"
      wrap="nowrap"
    >
      <Typography variant="primaryHeading" mb="s" mt="s">
        {t('consumer:WelcomeToDagensContactPerson', {
          contactPerson
        })}
      </Typography>
      <Typography variant="leadText" mb="s">
        {t('consumer:ExcitedForYouAndYourTeamAt', { name })}
      </Typography>
      <Container
        flex
        flexGrow={1}
        justify="center"
        direction="column"
        alignItems="stretch"
        mb="s"
      >
        {country === CountryCode.DK ? (
          <div
            style={{
              position: 'relative',
              paddingTop: '56.25%'
            }}
          >
            <ReactPlayer
              controls
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: 5,
                overflow: 'hidden'
              }}
              url={
                country === CountryCode.DK
                  ? 'https://vimeo.com/595878378'
                  : 'https://vimeo.com/595876243'
              }
              width="100%"
              height="100%"
              playing
            />
          </div>
        ) : (
          <Container px={getImagePadding()}>
            <ResponsiveImage
              src={Illustrations.PRODUCERS_COMMUNITY_NETWORKING}
              alt="Many producers gathered"
            />
          </Container>
        )}
      </Container>
      <Container flex alignItems="center" justify="space-between">
        <Button
          variant="borderless"
          onClick={() => {
            track(INTRO_SCREEN_ONE_CLICK_SKIP_INTRO);
            skip();
          }}
          text={t('consumer:SkipIntro')}
        />

        <Button
          variant="primary"
          onClick={() => {
            track(INTRO_SCREEN_ONE_CLICK_NEXT);
            next();
          }}
          text={t('common:NextWithArrow')}
        />
      </Container>
    </Container>
  );
};

export default PageOne;
