import { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';

export const cellStyle = tv({
  base: `
    flex
    flex-col

    desktop:px-xs

    not(:first-child):pl-xxs
    not(:last-child):pr-xxs
  `,
  variants: {
    verticalAlign: {
      top: 'justify-start',
      middle: 'justify-center',
      bottom: 'justify-end'
    }
  },
  defaultVariants: {
    verticalAlign: 'top'
  }
});

type Props = PropsWithChildren & {
  verticalAlign?: keyof typeof cellStyle.variants.verticalAlign;
};

export const Cell = ({ verticalAlign, children }: Props) => {
  return <td className={cellStyle({ verticalAlign })}>{children}</td>;
};
