import { useTranslation } from '@dagens/frontend-i18n';
import NewRadioButtons from '_common/components/radio-buttons/NewRadioButtons';
import { TemperatureZone } from 'types/Product';

type Props = {
  onChange: (change: TemperatureZone) => void;
  value: TemperatureZone | undefined;
};

const TemperatureZoneSelector = ({ onChange, value }: Props) => {
  const { t } = useTranslation();
  return (
    <NewRadioButtons
      radioGroupName="temperatureZone"
      labelText={t('producer:productTemperatureZoneSelectorHeader')}
      options={[
        {
          key: TemperatureZone.FROZEN,
          value: t('producer:productTemperatureZoneSelectorOption1')
        },
        {
          key: TemperatureZone.REFRIGERATED,
          value: t('producer:productTemperatureZoneSelectorOption2')
        },
        {
          key: TemperatureZone.DRY,
          value: t('producer:productTemperatureZoneSelectorOption3')
        }
      ]}
      currentValue={value}
      onChange={newValue => {
        return onChange(newValue as TemperatureZone);
      }}
    />
  );
};
export default TemperatureZoneSelector;
