import { SearchFunctionOptions, setupAlgolia } from './shared';
import { producersSearchIndex } from 'config';

const getProducerAttributesToRetrieve = (onlyIds: boolean) => {
  return onlyIds
    ? ['_id']
    : [
        '_id',
        '_updatedAt',
        'name',
        'contactPerson',
        'phone',
        'instagram',
        'profileArea',
        'profileBio',
        'image',
        'distributionAreas',
        'handle',
        'rawRank',
        'minimumOrderAmount'
      ];
};

const getProducerSearchOptions = (options: SearchFunctionOptions) => {
  const hideForMeyers = options.isMeyersAccount
    ? ' AND NOT hideForMeyers:true'
    : '';

  return {
    attributesToRetrieve: getProducerAttributesToRetrieve(
      Boolean(options.onlyIds)
    ),
    filters: `availableIn:${options.distributionAreaId} ${hideForMeyers}`
  };
};

const getProducerSearchFunction = (options: SearchFunctionOptions) => {
  const client = setupAlgolia(producersSearchIndex, options.roleId);
  return async (searchString: string) => {
    const { hits } = await client.search(
      searchString,
      getProducerSearchOptions(options)
    );

    return hits;
  };
};

export default getProducerSearchFunction;
