import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { roundNumber } from '@dagensmat/core';
import EzPicker from '../picker/EzPicker';
import {
  CardImageContainer as ImageContainer,
  SmallCardContainer as Container,
  SmallCardContent as Content
} from '_common/components/product/Product.style';
import ProductTag from '_common/components/product/ProductTag';
import { ProductForSale } from 'types/Product';
import { getImageUrl } from 'utils/imageUrls';
import { isSimplePricing } from 'utils/pricing';
import { formatNok2Decimals } from 'utils/texts';

type SmallProductCardProps = {
  product: ProductForSale;
  deliveryDate?: string;
  onClick: () => void;
};

const SmallProductCard = ({
  product,
  deliveryDate,
  onClick
}: SmallProductCardProps) => {
  const { image, name, type, producer, pricing } = product;
  const imageUrl = getImageUrl(image, { width: 500 });
  const { t } = useTranslation();

  return (
    <Container onClick={onClick}>
      <ImageContainer imageUrl={imageUrl} name={name} />
      <ProductTag product={product} />
      <Content>
        {deliveryDate ? (
          <>
            <Typography variant="paragraphSmall">
              {name} {type}
            </Typography>
            <Typography variant="paragraphSmall" color="secondary">
              {!isSimplePricing(pricing) && (
                <>
                  {`${roundNumber(pricing.pricedUnitsPerOrderedUnit)} ${t(
                    `units:${pricing.pricedUnit}`,
                    { count: 1 }
                  )}`}
                  {` á `}
                </>
              )}
              {formatNok2Decimals(pricing.nokPerPricedUnit)}
              {` per ${t(`units:${pricing.pricedUnit}`, { count: 1 })}`}
            </Typography>
            <EzPicker
              product={product}
              selectedDate={deliveryDate}
              variant="smallCard"
            />
          </>
        ) : (
          <>
            <Typography variant="paragraphSmall" color="secondary">
              {producer.name}
            </Typography>
            <Typography variant="paragraphSmall">
              {name} {type}
            </Typography>
          </>
        )}
      </Content>
    </Container>
  );
};

export default SmallProductCard;
