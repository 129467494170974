import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';

import { Page } from '../../../components/page';
import REQ from '../../../utils/REQ';
import { ConsumerOrdersPageHOC } from './ConsumerOrdersPageHOC';
import { useProductRequests } from '_common/reducers/productRequests';
import ProductRequestList from '_common/components/product-request-list/ProductRequestList';

type Props = {
  productRequests: {
    _id: string;
    _createdAt: string;
    hasNewMessage: boolean;
    isOpened: boolean;
    counterPartName: string;
    pathname: string;
  }[];
};

const ConsumerProductRequestsPage = ({ productRequests = [] }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {productRequests.length === 0 && (
        <Typography variant="paragraph" color="secondary" my="s">
          {t('common:NoActiveProductRequests')}
        </Typography>
      )}
      {productRequests.length > 0 && (
        <ProductRequestList items={productRequests} />
      )}
    </>
  );
};

const ConsumerProductRequestsPageFetcher = () => {
  const { productRequests, req } = useProductRequests();
  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }
  return (
    <ConsumerOrdersPageHOC>
      <ConsumerProductRequestsPage productRequests={productRequests} />
    </ConsumerOrdersPageHOC>
  );
};

export default ConsumerProductRequestsPageFetcher;
