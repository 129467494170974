import { uniq } from 'lodash';

import { ProductForSale } from '../types/Product';

export const getAvailableCertifications = (products = []) => {
  return uniq(
    products
      .map(({ certifications = [] }) => {
        return certifications[0];
      })
      .filter(Boolean)
      .map(({ displayNameKey }) => {
        return displayNameKey;
      })
  );
};

export const getProductsProcessedStates = (products = []) => {
  return uniq(
    products
      .map(({ processedState = '' }) => {
        return processedState;
      })
      .filter(Boolean)
  );
};

export const getCountProducersInSearch = (
  productsInSearch: ProductForSale[] = []
) => {
  return productsInSearch.length === 0
    ? 0
    : uniq(
        productsInSearch
          .map(({ producer: { _id } }) => {
            return _id;
          })
          .filter(Boolean)
      ).length;
};
