import Theme from '@dagensmat/carrot/Theme';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ColorValue } from 'theme';
import { MediaQuery } from 'utils/mediaQueries';
import margins, { MarginProps } from 'utils/style';

export const ButtonContainer = styled.div<{
  verticalSpacer?: number;
  horizontalSpacer?: number;
  paddingY?: number;
  textAlign?: 'left' | 'right' | 'center';
}>`
  padding: ${({ paddingY = Theme.Spacings.s }) => {
    return `${paddingY}px 0`;
  }};
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    margin-bottom: ${({ verticalSpacer = 10 }) => {
      return `${verticalSpacer}px`;
    }};
    :last-child {
      margin-bottom: 0;
    }
  }

  ${MediaQuery.tabletUp} {
    display: block;
    text-align: ${({ textAlign = 'left' }) => {
      return textAlign;
    }};
    width: auto;

    > * {
      margin-left: ${({ horizontalSpacer = 10 }) => {
        return `${horizontalSpacer}px`;
      }};
      :first-child {
        margin-left: 0;
      }
    }
  }
`;

export type Variant =
  | 'primary'
  | 'secondary'
  | 'borderless'
  | 'error'
  | 'ghost'
  | 'icon';

type VariantStyle = {
  bg: ColorValue;
  borderColor: ColorValue;
  color: ColorValue;
  padding: string;
};

const variants: Record<Variant, VariantStyle> = {
  primary: {
    bg: Theme.Colours.dagensPurple,
    borderColor: Theme.Colours.dagensPurple,
    color: Theme.Colours.black,
    padding: `${Theme.Spacings.s - 2}px`
  },
  secondary: {
    bg: Theme.Colours.black,
    borderColor: Theme.Colours.black,
    color: Theme.Colours.white,
    padding: `${Theme.Spacings.s - 2}px`
  },
  borderless: {
    bg: Theme.Colours.transparent,
    borderColor: Theme.Colours.transparent,
    color: Theme.Colours.deepPurple,
    padding: `${Theme.Spacings.s - 2}px ${Theme.Spacings.xxs}px`
  },
  error: {
    bg: Theme.Colours.transparent,
    borderColor: Theme.Colours.error,
    color: Theme.Colours.error,
    padding: `${Theme.Spacings.s - 2}px`
  },
  ghost: {
    bg: Theme.Colours.transparent,
    borderColor: Theme.Colours.black,
    color: Theme.Colours.black,
    padding: `${Theme.Spacings.s - 2}px`
  },
  icon: {
    bg: Theme.Colours.transparent,
    borderColor: Theme.Colours.transparent,
    color: Theme.Colours.black,
    padding: `${Theme.Spacings.xs - 2}px`
  }
};

export type ButtonProps = {
  variant?: Variant;
} & MarginProps;

const buttonStyle = css<ButtonProps>`
  position: relative;
  display: inline-block;
  white-space: nowrap;

  padding: ${({ variant = 'ghost' }) => {
    return variants[variant].padding;
  }};

  ${margins}

  background-color: ${({ variant = 'ghost' }) => {
    return variants[variant].bg;
  }};
  color: ${({ variant = 'ghost' }) => {
    return variants[variant].color;
  }};

  border: 2px solid
    ${({ variant = 'ghost' }) => {
      return variants[variant].borderColor;
    }};
  border-radius: ${Theme.BorderRadius.regular}px;

  font-size: ${Theme.FontSizes.button}px;
  font-weight: ${Theme.FontWeights.medium}px;
  line-height: ${Theme.Spacings.s}px;
  letter-spacing: 0.25px;

  transition:
    color ${Theme.Animations.regular},
    background-color ${Theme.Animations.regular},
    border-color ${Theme.Animations.regular},
    opacity ${Theme.Animations.regular};

  :disabled {
    color: ${Theme.Colours.secondary};
    border-color: ${Theme.Colours.lightGrey};
    background-color: ${Theme.Colours.lightGrey};
    cursor: initial;
  }

  :active {
    background-color: ${({ variant = 'ghost' }) => {
      if (variant === 'borderless') return `${Theme.Colours.deepPurple}`;
      return null;
    }};
    color: ${({ variant = 'ghost' }) => {
      if (variant === 'borderless') return `${Theme.Colours.white}`;
      return null;
    }};
  }

  @media (hover: hover) {
    :disabled:hover {
      opacity: 1;
    }
    :active:hover {
      opacity: ${({ variant = 'ghost' }) => {
        if (variant === 'borderless') return '1';
        return '0.5';
      }};
    }
    :hover {
      opacity: 0.5;
    }
  }

  ${MediaQuery.print} {
    display: none;
  }
`;

const Button = styled.button`
  ${buttonStyle}
`;

export const ButtonLink = styled(Link)`
  ${buttonStyle}
`;

export const ButtonAnchor = styled.a`
  ${buttonStyle}
`;

export const UnstyledButton = styled.button`
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  background-color: transparent;
`;

export const TagButton = styled(Button)`
  margin: 0 ${Theme.Spacings.xxs}px ${Theme.Spacings.xxs}px 0;
`;

export const HorisontalScrollRowButton = styled(Button)`
  margin: 0 ${Theme.Spacings.xxs}px 0 0;
  &:last-child {
    margin-right: 0;
  }
`;

export default Button;
