import { ForwardedRef, forwardRef, TextareaHTMLAttributes } from 'react';
import { inputStyle } from '../styles';

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

type Props = {
  placeholder?: TextareaProps['placeholder'];
  rows?: TextareaProps['rows'];
  error?: boolean;
  value?: string;
  onChange?: TextareaProps['onChange'];
};

const Component = (
  { error, ...props }: Props,
  ref: ForwardedRef<HTMLTextAreaElement>
) => {
  const { container, input } = inputStyle({ error });

  return (
    <label className={container()}>
      <textarea {...props} ref={ref} className={input()} />
    </label>
  );
};

export const Textarea = forwardRef(Component);
