import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';

import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { Order } from '../../../types/Order';
import REQ from '../../../utils/REQ';
import { ConsumerOrdersPageHOC } from './ConsumerOrdersPageHOC';
import { formatDate } from 'utils/date/format';
import { Page } from '@components/page';
import {
  groupOrdersByDeliveryDate,
  splitActiveAndPastOrders
} from '_common/reducers/orders';
import OrderOverviewGroup from '_common/components/order-overview/OrderOverviewGroup';

type Props = {
  orderGroups: {
    key: string;
    deliveryDate: string;
    orders: Order[];
  }[];
};

const ConsumerOrdersOverviewPage = ({ orderGroups = [] }: Props) => {
  const { t } = useTranslation();

  return orderGroups.length < 1 ? (
    <Typography variant="paragraph" color="secondary" my="s">
      {t('common:NoActiveOrder')}
    </Typography>
  ) : (
    <>
      {orderGroups.map(({ deliveryDate, orders }) => {
        return (
          <OrderOverviewGroup
            key={deliveryDate}
            header={t('common:DateOfDelivery', {
              date: formatDate(deliveryDate)
            })}
            orders={orders}
          />
        );
      })}
    </>
  );
};

const ConsumerOrdersOverviewPageFetcher = () => {
  const { req, orderGroups } = useAppSelector(({ orders: { items, req } }) => {
    const { activeOrders } = splitActiveAndPastOrders(items);
    const orderGroups = groupOrdersByDeliveryDate(activeOrders);

    return {
      req,
      orderGroups
    };
  });

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return (
    <ConsumerOrdersPageHOC>
      <ConsumerOrdersOverviewPage orderGroups={orderGroups} />
    </ConsumerOrdersPageHOC>
  );
};

export default ConsumerOrdersOverviewPageFetcher;
