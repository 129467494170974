import { Container } from '@dagensmat/carrot/Layout';
import { FC, ReactNode } from 'react';

import { FormFieldExplanation } from './explanation';
import { FormFieldLabel } from './label';
import { FormFieldOptional } from './optional';

type Props = {
  label?: string | null;
  optional?: boolean;
  explanation?: ReactNode;
};

export const LabelContainer: FC<Props> = ({ explanation, label, optional }) => {
  if (!label && !optional && !explanation) {
    return null;
  }

  return (
    <Container flex gap="xs" wrap="wrap">
      {label && <FormFieldLabel>{label}</FormFieldLabel>}
      {optional && <FormFieldOptional />}
      <FormFieldExplanation>{explanation}</FormFieldExplanation>
    </Container>
  );
};
