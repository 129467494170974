import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import Theme from '@dagensmat/carrot/Theme';
import React, { Component } from 'react';
import FormLabel from '_common/components/input/FormLabel';
import Button, { Variant } from '_common/components/button/Button.style';

type ConfirmButtonProps = {
  confirmText: string;
  confirmedText: string;
  buttonText: React.ReactNode;
  buttonVariant?: Variant;
  rejectButtonText: string;
  confirmButtonText: string;
  confirmButtonVariant?: Variant;
  onConfirm: () => void;
  onClick?: () => void;
  onReject?: () => void;
  disabled?: boolean;
  display?: 'block' | 'inline-block';
};

type ConfirmButtonState = {
  confirm: 'INIT' | 'PENDING' | 'CONFIRMED';
};

class ConfirmButton extends Component<ConfirmButtonProps> {
  state: ConfirmButtonState = {
    confirm: 'INIT'
  };

  render() {
    const { confirm } = this.state;
    const {
      confirmText,
      confirmedText,
      buttonText,
      buttonVariant,
      rejectButtonText,
      confirmButtonText,
      confirmButtonVariant = 'secondary',
      onConfirm,
      onClick,
      onReject,
      disabled,
      display = 'block'
    } = this.props;

    return (
      <div style={{ display }}>
        {confirm === 'INIT' && (
          <Button
            variant={buttonVariant}
            disabled={disabled}
            onClick={() => {
              this.setState({ confirm: 'PENDING' });
              if (onClick) onClick();
            }}
          >
            {buttonText}
          </Button>
        )}
        {confirm === 'PENDING' && (
          <>
            <FormLabel labelText={confirmText} />
            <Container flex justify="flex-start">
              <Button
                onClick={() => {
                  this.setState({ confirm: 'INIT' });
                  if (onReject) onReject();
                }}
                mr={Theme.Spacings.xs}
              >
                {rejectButtonText}
              </Button>
              <Button
                variant={confirmButtonVariant}
                onClick={() => {
                  this.setState({ confirm: 'CONFIRMED' });
                  onConfirm();
                }}
              >
                {confirmButtonText}
              </Button>
            </Container>
          </>
        )}
        {confirm === 'CONFIRMED' && (
          <Typography variant="paragraph" color="secondary">
            {confirmedText}
          </Typography>
        )}
      </div>
    );
  }
}

export default ConfirmButton;
