import { Trans, useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import Theme from '@dagensmat/carrot/Theme';

import { tv } from 'tailwind-variants';
import { Illustrations } from '../utils/Illustrations';
import { ButtonLink } from '_common/components/button/Button.style';
import ImageToTheRightContainer from '_common/components/utils/ImageToTheRightContainer.style';
import ResponsiveImage from '_common/components/utils/ResponsiveImage.style';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { Page } from '@components/page';
import { RootState } from 'store';

const illustrationStyle = tv({
  base: 'p-xl'
});

const RootlessUserPage = () => {
  const { loginEmail } = useAppSelector(({ auth }: RootState) => {
    return {
      loginEmail: auth.loginEmail
    };
  });
  const { t } = useTranslation();

  return (
    <Page>
      <ImageToTheRightContainer>
        <div>
          <Typography variant="primaryHeading" my="xl">
            {t('common:UserIsRootless')}
          </Typography>
          <Typography variant="paragraph" my="m">
            {t('common:AskUserWithAccessToInvite')}
          </Typography>
          <Typography variant="paragraph" my="m">
            <Trans
              t={t}
              i18nKey="common:1LogIntoDagens"
              components={[<strong key="1LogIntoDagens" />]}
            />
            <br />
            <Trans
              t={t}
              i18nKey="common:2GoToProfile"
              components={[<strong key="2GoToProfile" />]}
            />
            <br />
            <Trans
              t={t}
              i18nKey="common:3ScrollToInviteUser"
              components={[<strong key="3ScrollToInviteUser" />]}
            />
            <br />
            <Trans
              t={t}
              values={{ loginEmail }}
              i18nKey="common:4Invite"
              components={[<strong key="4Invite" />]}
            />
          </Typography>
          <Typography variant="paragraph">
            {t('common:DontHaveAnAccountYet')}
          </Typography>
          <ButtonLink to="create-consumer" variant="borderless">
            {t('common:CreateAccount')}
          </ButtonLink>
        </div>
        <div className={illustrationStyle()}>
          <ResponsiveImage
            src={Illustrations.INTERESTED_CHEF}
            alt="Rootless user illustration"
            style={{ padding: Theme.Spacings.s }}
          />
        </div>
      </ImageToTheRightContainer>
    </Page>
  );
};

export default RootlessUserPage;
