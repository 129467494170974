import Theme from '@dagensmat/carrot/Theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormSectionIds } from '_producer/components/product-form/ProductForm';
import ProductReference from '_common/components/product-reference/ProductReference';
import { isSeasonPeriod, ProducerProduct, ProductForSale } from 'types/Product';
import {
  calculatePeriodPosition,
  isAlwaysInSeason,
  isValidThisYear
} from 'utils/season';

const RowContainer = styled.div`
  position: relative;
  height: ${Theme.Spacings.l + Theme.Spacings.xs}px;
  width: 100%;
  margin: ${Theme.Spacings.xs}px 0;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`;

const InSeasonBlock = styled.div<{ left: string | number; width: string }>`
  position: absolute;
  top: 0;
  left: ${({ left }) => {
    return left;
  }};
  width: ${({ width }) => {
    return width;
  }};
  height: inherit;
  background-color: ${Theme.Colours.availability};
  border-radius: ${Theme.BorderRadius.regular}px;
`;

type SeasonRowContentProps = {
  product: ProducerProduct | ProductForSale;
};
const SeasonRowContent = ({ product }: SeasonRowContentProps) => {
  const { seasonCalendar = [] } = product;
  return (
    <>
      {isAlwaysInSeason(seasonCalendar) ? (
        <InSeasonBlock left={0} width="100%" />
      ) : (
        seasonCalendar.filter(isValidThisYear).map(period => {
          if (isSeasonPeriod(period)) {
            const { left, width } = calculatePeriodPosition(period);
            return (
              <InSeasonBlock key={period._key} left={left} width={width} />
            );
          }
          return null;
        })
      )}
      <ProductReference
        product={product}
        variant="small"
        style={{
          position: 'absolute',
          top: Theme.Spacings.xs,
          left: Theme.Spacings.xs
        }}
      />
    </>
  );
};

type SeasonRowInModalProps = {
  product: ProducerProduct | ProductForSale;
  onClick?: (productId: string) => void;
};

const SeasonRowInModal = ({ product, onClick }: SeasonRowInModalProps) => {
  return (
    <RowContainer
      onClick={() => {
        onClick?.(product._id);
      }}
    >
      <SeasonRowContent product={product} />
    </RowContainer>
  );
};

type SeasonRowForProducerProps = {
  product: ProducerProduct | ProductForSale;
  onClick?: (productId: string) => void;
};

const SeasonRowForProducer = ({
  product,
  onClick
}: SeasonRowForProducerProps) => {
  return (
    <Link
      to={{
        pathname: `/my-products/${product._id}`,
        hash: FormSectionIds.AVAILABILITY
      }}
      state={{ returnPath: true }}
      onClick={() => {
        onClick?.(product._id);
      }}
    >
      <RowContainer>
        <SeasonRowContent product={product} />
      </RowContainer>
    </Link>
  );
};

type SeasonRowProps = {
  product: ProducerProduct | ProductForSale;
  isProducer: boolean;
  onClick?: (productId: string) => void;
};

const SeasonRow = ({
  product,
  isProducer = false,
  onClick
}: SeasonRowProps) => {
  return isProducer ? (
    <SeasonRowForProducer product={product} onClick={onClick} />
  ) : (
    <SeasonRowInModal product={product} onClick={onClick} />
  );
};

export default SeasonRow;
