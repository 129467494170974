import { useMediaQuery, Nav, NavLinkProps } from '@dagens/carrot';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../_common/hooks/reduxHooks';

type MainLinkArgs = {
  authenticated: boolean | undefined;
  desktopUp: boolean;
  showLogo: boolean;
};

const useMainLink = ({ authenticated, desktopUp, showLogo }: MainLinkArgs) => {
  const navigate = useNavigate();
  if (!authenticated) {
    return <Nav.LogoLandingPage />;
  }
  if (desktopUp && showLogo) {
    return <Nav.LogoHome />;
  }
  if (showLogo) {
    return null;
  }
  return (
    <Nav.IconButton
      onClick={e => {
        e.preventDefault();
        navigate(-1);
      }}
      icon="back"
    />
  );
};

type CenterContentArgs = {
  contextName: string | undefined;
  desktopUp: boolean;
  links: readonly NavLinkProps[] | undefined;
};

const useCenterContent = ({
  contextName,
  desktopUp,
  links
}: CenterContentArgs) => {
  if (desktopUp && links) {
    return <Nav.Links links={links} />;
  }
  if (contextName) {
    return <Nav.Context text={contextName} />;
  }
  return null;
};

type ActiveActionsArgs = {
  actions: ReactNode;
  desktopUp: boolean;
  showActionsOnMobile: boolean;
};

const useActiveActions = ({
  actions,
  desktopUp,
  showActionsOnMobile
}: ActiveActionsArgs) => {
  if (desktopUp || showActionsOnMobile) {
    return actions;
  }
  return null;
};

type Props = {
  actions: ReactNode;
  contextActions?: ReactNode;
  contextName?: string;
  links?: readonly NavLinkProps[];
  showActionsOnMobile: boolean;
  showLogo: boolean;
};

export const Navbar = ({
  actions,
  contextName,
  links,
  showLogo,
  showActionsOnMobile
}: Props) => {
  const { authenticated } = useAppSelector(({ auth }) => {
    return {
      authenticated: auth.authenticated
    };
  });

  const { desktopUp } = useMediaQuery();
  const mainLink = useMainLink({
    authenticated,
    desktopUp,
    showLogo
  });
  const centerContent = useCenterContent({
    contextName,
    desktopUp,
    links
  });
  const activeActions = useActiveActions({
    actions,
    desktopUp,
    showActionsOnMobile
  });

  return (
    <Nav.Bar left={mainLink} center={centerContent} right={activeActions} />
  );
};
