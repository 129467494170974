import { capitalize } from '@dagensmat/core';
import { HorisontalScrollRowButton } from '_common/components/button/Button.style';
import HorisontalScrollContainer from '_common/components/utils/HorisontalScrollContainer';
import { formatDate } from 'utils/date/format';

export type DeliveryDate = string;

type DeliveryDateOptionsProps = {
  availableDeliveryDates?: DeliveryDate[];
  setDeliveryDate: (deliveryDate: DeliveryDate) => void;
  unavailableDeliveryDates?: DeliveryDate[];
};

const DeliveryDateOptions = ({
  availableDeliveryDates = [],
  setDeliveryDate,
  unavailableDeliveryDates = []
}: DeliveryDateOptionsProps) => {
  return (
    <HorisontalScrollContainer>
      <>
        {availableDeliveryDates.map(deliveryDate => {
          return (
            <HorisontalScrollRowButton
              key={deliveryDate}
              onClick={() => {
                return setDeliveryDate(deliveryDate);
              }}
              disabled={!!unavailableDeliveryDates.includes(deliveryDate)}
            >
              {capitalize(formatDate(deliveryDate, 'iii d. MMM'))}
            </HorisontalScrollRowButton>
          );
        })}
      </>
    </HorisontalScrollContainer>
  );
};

export default DeliveryDateOptions;
