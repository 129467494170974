import Theme from '@dagensmat/carrot/Theme';
import styled from 'styled-components';

export const ProductLine = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  border-bottom: 1px solid ${Theme.Colours.lightGrey};
`;

export const AmountAdjuster = styled.div<{ selected: boolean }>`
  margin-bottom: ${Theme.Spacings.l}px;
  display: flex;
  justify-content: space-between;
  ${({ selected }) => {
    return selected ? '' : 'display: none;';
  }}
`;
