import { tr } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { TypographyNames } from '@dagensmat/carrot/Components/Typography/Typography.types';
import Theme from '@dagensmat/carrot/Theme';
import i18n from 'i18next';
import React from 'react';

import { Select } from '../../../carrot/select';
import { InputContainer } from '_common/components/input/Input.style';

type SelectProps = {
  options: { value: string; name: string }[];
  selectedValue?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  width?: string;
};

type SelectWithLabelProps = {
  labelText: string;
  saved?: boolean;
  savedText?: string | null;
  explanationText?: React.ReactNode;
  labelType?: TypographyNames;
} & SelectProps;

const SelectWithLabel = ({
  labelText,
  saved = false,
  savedText = tr(i18n, 'common:Saved'),
  explanationText,
  labelType = 'inputLabel',
  ...rest
}: SelectWithLabelProps) => {
  return (
    <InputContainer margin={0} marginRight={Theme.Spacings.xs}>
      <Typography variant={labelType} id={labelText} mb="xxs">
        {labelText}
      </Typography>
      <Select {...rest} id={labelText} />
      {explanationText && (
        <Typography variant="paragraphSmall">{explanationText}</Typography>
      )}
      {saved && (
        <Typography
          variant="paragraphSmall"
          mt="xxs"
          style={{ textAlign: 'left' }}
        >
          {savedText}
        </Typography>
      )}
    </InputContainer>
  );
};

export default SelectWithLabel;
