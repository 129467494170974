import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';

// eslint-disable-next-line tailwindcss/no-custom-classname
const style = tv({
  slots: {
    dialog: `
      relative
      z-20
    `,
    backdrop: `
      fixed
      inset-0
      bg-black/75
      transition-opacity

      data-[closed]:opacity-0

      data-[enter]:duration-300
      data-[enter]:ease-out

      data-[leave]:duration-200
      data-[leave]:ease-in
    `,
    content: `
      fixed
      inset-0
      z-10
      flex
      w-screen
      items-end
      justify-center
      px-s
      py-xl

      desktop:items-center
    `,
    panel: `
      flex
      w-full
      max-w-[480px]
      flex-col
      rounded
      bg-white
    `
  }
});

type Props = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const Modal = ({ open, children, onClose }: Props) => {
  const { dialog, backdrop, content, panel } = style();
  return (
    <Dialog
      transition
      className={dialog}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <DialogBackdrop transition className={backdrop()} />
      <div className={content()}>
        <DialogPanel className={panel()}>{children}</DialogPanel>
      </div>
    </Dialog>
  );
};
