import { Input } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { ComboboxInput } from '@headlessui/react';

type Props = {
  setQuery: (query: string) => void;
};

export const CommandPaletteInput = ({ setQuery }: Props) => {
  const { t } = useTranslation();
  return (
    <ComboboxInput
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      as={Input}
      onChange={event => setQuery(event.target.value)}
      placeholder={t('common:Search')}
    />
  );
};
