import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';

const OrderLineTableHeader = () => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr>
        <th>
          <Typography variant="paragraphSmallBold" color="secondary">
            {t('common:Product_one')}
          </Typography>
        </th>
        <th>
          <Typography variant="paragraphSmallBold" color="secondary">
            {t('common:Quantity')}
          </Typography>
        </th>
      </tr>
    </thead>
  );
};

export default OrderLineTableHeader;
