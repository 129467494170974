import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';
import { inputStyle } from '../styles';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

type InputTypes = Extract<
  InputProps['type'],
  'text' | 'email' | 'password' | 'tel'
>;

type Props = {
  type?: InputTypes;
  placeholder?: InputProps['placeholder'];
  maxLength?: InputProps['maxLength'];
  error?: boolean;
  value?: string;
  onChange?: InputProps['onChange'];
};

const Component = (
  { error, ...props }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const { container, input } = inputStyle({ error });

  return (
    <label className={container()}>
      <input {...props} ref={ref} className={input()} />
    </label>
  );
};

export const BasicInput = forwardRef(Component);
