import { tv } from 'tailwind-variants';
import { NavLink, NavLinkProps } from './link';

const style = tv({
  base: `
    flex
    size-full
    items-center
    justify-center
    gap-xxs
  `
});

type Props = {
  links: readonly NavLinkProps[];
};

export const NavLinks = ({ links }: Props) => {
  return (
    <div className={style()}>
      {links.map(link => (
        <NavLink key={link.label} {...link} />
      ))}
    </div>
  );
};
