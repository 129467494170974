import { useTranslation, tr } from '@dagens/frontend-i18n';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import Theme from '@dagensmat/carrot/Theme';
import { AvailableLanguages } from '@dagensmat/core';
import i18n from 'i18next';
import { useState } from 'react';
import { ProductCapacity } from '../../../types/Product';
import Button from '_common/components/button/Button.style';
import { StyledDeliveryDatePicker } from '_common/components/delivery-date-picker/DeliveryDatePicker.style';
import FormLabel from '_common/components/input/FormLabel';
import useWindowWidth from '_common/hooks/useWindowWidth';
import { arrayDoesNotInclude } from 'utils/array';
import {
  formatDate,
  getLocaleFromLanguage,
  slugifyDate
} from 'utils/date/format';
import { numberOfMonths } from 'utils/dayPicker';
import { toSoldOutCapacityDTO } from 'utils/season';

const buttonMargins = { mr: Theme.Spacings.xxs, mb: Theme.Spacings.xs };

const ProductCapacityInput = ({
  onChange,
  soldOutDates = [],
  deliveryDates = []
}: {
  onChange: (dates: ProductCapacity[]) => void;
  soldOutDates: ProductCapacity[];
  deliveryDates: string[];
}) => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [selectedSoldOutDateIndex, setSelectedSoldOutDateIndex] =
    useState<number>(-1);
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    undefined
  );
  const windowWidth = useWindowWidth() ?? 0;

  const isNotDeliveryDate = arrayDoesNotInclude(deliveryDates);
  const isSaveDisabled =
    !selectedDate ||
    !!soldOutDates.find(({ deliveryDate }) => {
      return selectedDate === deliveryDate;
    });

  const saveButtonLabel = (index: number, slug: string) => {
    return index === -1
      ? `${tr(i18n, 'producer:productSoldOutSet', { date: `${slug}` })}`
      : `${tr(i18n, 'producer:productSoldOutUpdate', {
          date: `${slug}`
        })}`;
  };

  const openCalendar = (soldOutDateIndex: number, date: string | undefined) => {
    setShowCalendar(true);
    setSelectedSoldOutDateIndex(soldOutDateIndex);
    setSelectedDate(date);
  };

  const resetAndCloseDatePicker = () => {
    setShowCalendar(false);
    setSelectedSoldOutDateIndex(-1);
    setSelectedDate(undefined);
  };

  const onSave = () => {
    if (showCalendar && selectedSoldOutDateIndex > -1 && selectedDate) {
      onChange(
        soldOutDates.map((date, i) => {
          return selectedSoldOutDateIndex === i
            ? toSoldOutCapacityDTO(selectedDate)
            : date;
        })
      );
    }

    if (
      selectedSoldOutDateIndex > -1 &&
      selectedDate === soldOutDates[selectedSoldOutDateIndex].deliveryDate
    ) {
      onChange(
        soldOutDates.filter((_, i) => {
          return selectedSoldOutDateIndex !== i;
        })
      );
    }

    if (selectedSoldOutDateIndex === -1 && selectedDate) {
      onChange([...soldOutDates, toSoldOutCapacityDTO(selectedDate)]);
    }

    resetAndCloseDatePicker();
  };
  const { t } = useTranslation();
  return (
    <>
      <FormLabel
        labelText={t('producer:productSoldOutLabel')}
        helpText={t('producer:productSoldOutExplanation')}
      />
      {soldOutDates.map(({ deliveryDate }, i) => {
        return (
          <Button
            {...buttonMargins}
            key={deliveryDate}
            variant={selectedSoldOutDateIndex === i ? 'secondary' : 'ghost'}
            onClick={() => {
              if (selectedSoldOutDateIndex === i) {
                resetAndCloseDatePicker();
              } else {
                openCalendar(i, deliveryDate);
              }
            }}
          >
            {formatDate(deliveryDate, 'dd.MM.yy')}
          </Button>
        );
      })}
      <Button
        {...buttonMargins}
        variant={
          showCalendar && selectedSoldOutDateIndex === -1
            ? 'secondary'
            : 'ghost'
        }
        onClick={() => {
          if (showCalendar && selectedSoldOutDateIndex === -1) {
            resetAndCloseDatePicker();
          } else {
            openCalendar(-1, undefined);
          }
        }}
      >
        {t('producer:productSoldOutButton')}
      </Button>
      {showCalendar && (
        <>
          <StyledDeliveryDatePicker
            onDayClick={day => {
              return setSelectedDate(slugifyDate(day));
            }}
            disabled={day => {
              const slugified = slugifyDate(day);
              return (
                !!soldOutDates.find(({ deliveryDate }) => {
                  return deliveryDate === slugified;
                }) || isNotDeliveryDate(slugified)
              );
            }}
            modifiers={{
              soldOut: day => {
                return !!soldOutDates.find(({ deliveryDate }) => {
                  return deliveryDate === slugifyDate(day);
                });
              }
            }}
            modifiersClassNames={{ soldOut: 'rdp-day_soldOut' }}
            selected={selectedDate ? new Date(selectedDate) : undefined}
            locale={getLocaleFromLanguage(
              i18n.resolvedLanguage as AvailableLanguages
            )}
            numberOfMonths={numberOfMonths({ width: windowWidth })}
          />
          {selectedDate && isNotDeliveryDate(selectedDate) && (
            <Callout colour="warning" mb="m">
              {t('producer:productSoldOutCallOut')}
            </Callout>
          )}
          <Typography variant="paragraphSmall" mb="s">
            {t('producer:productSoldOutExplanation1')}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {selectedSoldOutDateIndex > -1 && (
              <Button
                onClick={onSave}
                mr={10}
                disabled={
                  selectedDate === undefined ||
                  selectedDate !==
                    soldOutDates[selectedSoldOutDateIndex].deliveryDate
                }
              >
                {t('common:reset')}
              </Button>
            )}
            <Button
              variant="secondary"
              onClick={onSave}
              disabled={isSaveDisabled}
            >
              {saveButtonLabel(
                selectedSoldOutDateIndex,
                selectedDate ? formatDate(selectedDate, 'dd.MM.yy') : ''
              )}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default ProductCapacityInput;
