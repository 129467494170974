import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../_common/hooks/reduxHooks';
import { clearMessages, setUpMessages } from '_common/reducers/messages';
import { clearOrders, fetchOrders } from '_common/reducers/orders';
import {
  clearProductRequests,
  setUpProductRequests
} from '_common/reducers/productRequests';
import { clearProducers, fetchProducers } from '_consumer/reducers/producers';
import {
  clearProductsForSale,
  fetchProductsForSale
} from '_consumer/reducers/productsForSale';
import {
  clearStatistics,
  fetchStatistics
} from '_consumer/reducers/statistics';
import { CommandPalette } from '@components/command-palette';

export const ConsumerLayout = () => {
  const { userId, distributionAreaId, roleLang, isMeyersAccount } =
    useAppSelector(({ auth }) => {
      return {
        userId: auth._id,
        distributionAreaId: auth.distributionAreaId,
        roleLang: auth.roleLang,
        isMeyersAccount: auth.isMeyersAccount
      };
    });
  const dispatch = useAppDispatch();

  const fetchUserData = () => {
    dispatch(fetchOrders({ consumerId: userId }) as any);
    if (userId && distributionAreaId && roleLang) {
      dispatch(
        fetchProductsForSale({
          userId,
          distributionAreaId,
          roleLang,
          isMeyersAccount
        }) as any
      );
    }
    if (distributionAreaId) {
      dispatch(fetchProducers({ distributionAreaId, isMeyersAccount }) as any);
    }
    dispatch(setUpProductRequests(userId) as any);
    dispatch(setUpMessages(userId) as any);
    if (userId) {
      dispatch(fetchStatistics({ consumerId: userId }) as any);
    }
  };

  useEffect(() => {
    fetchUserData();
    return () => {
      dispatch(clearOrders());
      dispatch(clearProductsForSale());
      dispatch(clearProducers());
      dispatch(clearProductRequests());
      dispatch(clearMessages());
      dispatch(clearStatistics());
    };
  }, [userId]);

  return (
    <>
      <Outlet />
      <CommandPalette />
    </>
  );
};
