import { useTranslation } from '@dagens/frontend-i18n';
import { FC } from 'react';

import { FormField } from '../../carrot/form-field';
import { useConsumersToOptions } from './hooks/use-consumers-to-options';
import { useGetOptionsFromValue } from './hooks/use-get-options-from-value';
import { useLoadConsumers } from './hooks/use-load-consumers';
import { useOnChange } from './hooks/use-on-change';
import { FormFieldInputProps, FormFieldType } from 'carrot/form-field/types';

type Props = FormFieldInputProps & {
  disabledValues: string[];
  value: readonly string[];
  onChange: (value: readonly string[]) => void;
};

export const ConsumerSelect: FC<Props> = ({
  disabledValues,
  optional,
  showError,
  value,
  onChange
}) => {
  const { t } = useTranslation();
  const { consumers } = useLoadConsumers();
  const options = useConsumersToOptions({ consumers, disabledValues, value });

  const selectedOptions = useGetOptionsFromValue({ value, options });
  const onChangeLocal = useOnChange({ previousValue: value, onChange });

  return (
    <FormField
      type={FormFieldType.SELECT_MULTI}
      inputProps={{
        options,
        value: selectedOptions,
        onChange: onChangeLocal
      }}
      label={t('producer:SelectCustomers')}
      explanation={t('producer:SelectCustomersExplanationText')}
      errorMessage={t('common:requiredField')}
      optional={optional}
      showError={showError}
    />
  );
};
