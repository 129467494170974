import { createBrowserRouter, redirect } from 'react-router-dom';
import CookiesInfoPage from '_common/pages/CookiesInfoPage';
import LogoutPage from '_common/pages/LogoutPage';
import ResetPasswordPage from '_common/pages/ResetPasswordPage';
import RootResolver from '_common/pages/RootResolver';
import VerifyUserEmail from '_common/pages/VerifyUserEmail';
import Login from '_unauthenticated/pages/login/Login';
import Signup from '_unauthenticated/pages/signup/Signup';
import SignupConsumers from '_unauthenticated/pages/signup/SignupConsumers';
import SignupProducers from '_unauthenticated/pages/signup/SignupProducers';

export const unauthRouter = () => {
  return createBrowserRouter([
    {
      path: '/',
      children: [
        {
          index: true,
          loader: () => {
            return redirect('/login');
          }
        },

        { path: '/login', element: <Login /> },
        { path: '/logout', element: <LogoutPage /> },
        { path: '/signup', element: <Signup /> },
        { path: '/reset-password', element: <ResetPasswordPage /> },
        { path: '/cookies', element: <CookiesInfoPage /> },
        { path: '/user/verify/:token', element: <VerifyUserEmail /> },
        { path: '/buy', element: <SignupConsumers /> },
        { path: '/sell', element: <SignupProducers /> },

        ...['/create-consumer', 'letsgo', 'komigang'].map(path => {
          return {
            path,
            loader: () => {
              return redirect('/buy');
            }
          };
        }),
        ...['/create-producer', 'signup-producers', 'blimed'].map(path => {
          return {
            path,
            loader: () => {
              return redirect('/sell');
            }
          };
        }),

        { path: '/:handle', element: <RootResolver /> },
        {
          path: '/producers/:handle',
          loader: ({ params }) => {
            return redirect(`/${params.handle}`);
          }
        },
        {
          path: '*',
          loader: () => {
            return redirect('/');
          }
        }
      ]
    }
  ]);
};
