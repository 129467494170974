import { useTranslation } from '@dagens/frontend-i18n';
import styled from 'styled-components';
import DeferChangeHOC from '_common/components/defer-change-hoc/DeferChangeHOC';
import InputWithLabel from '_common/components/input/InputWithLabel';
import SectionHOC from '_common/components/section-hoc/SectionHOC';
import TextAreaWithButton from '_common/components/text-area/TextAreaWithButton';
import ProfileImageInput from '_producer/pages/profile/ProfileImageInput';

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 490px;
`;

const DeferredInputWithLabel = DeferChangeHOC(InputWithLabel);

type ProfileAboutSectionProps = {
  name?: string;
  instagram?: string;
  profileArea?: string;
  profileBio?: string;
  onFieldsChange: (fields: any) => Promise<any>;
  producerId?: string;
  profileImage: any;
  imageUpdated: (fields: any) => void;
};

const ProfileAboutSection = ({
  name = '',
  instagram = '',
  profileArea = '',
  profileBio = '',
  onFieldsChange,
  producerId = '',
  profileImage,
  imageUpdated
}: ProfileAboutSectionProps) => {
  const { t } = useTranslation();
  return (
    <MaxWidthContainer>
      <DeferredInputWithLabel
        labelText={t('producer:myCompanyNameLabelText')}
        placeholder={t('producer:myCompanyNamePlaceholder')}
        value={name}
        savedText={t('producer:myCompanyNameSavedText')}
        validationFunction={val => {
          return val.length > 0;
        }}
        onChange={value => {
          onFieldsChange({ name: value });
        }}
      />
      <DeferredInputWithLabel
        labelText={t('producer:profileAreaLabelText')}
        placeholder={t('producer:profileAreaPlaceholder')}
        value={profileArea}
        onChange={value => {
          onFieldsChange({ profileArea: value });
        }}
      />
      <ProfileImageInput
        profileImage={profileImage}
        producerId={producerId}
        imageUpdated={imageUpdated}
      />
      <TextAreaWithButton
        labelText={t('producer:profileBioLabelText')}
        placeholder={t('producer:profileBioPlaceholder')}
        value={profileBio}
        submitText={value => {
          return onFieldsChange({
            profileBio: value
          });
        }}
        maxLength={2000}
      />
      <DeferredInputWithLabel
        labelText={t('common:instagramProfile')}
        placeholder={t('common:yourInstagramProfile')}
        value={instagram}
        onChange={value => {
          onFieldsChange({ instagram: value });
        }}
      />
    </MaxWidthContainer>
  );
};

export default SectionHOC(ProfileAboutSection);
