import { useTranslation } from '@dagens/frontend-i18n';
import { useNavigate } from 'react-router-dom';
import RoleListElement from './RoleListElement';
import CalloutLinkListElement from '_common/components/link-list-element/CalloutLinkListElement';
import PageHeader from '@components/page-header/PageHeader';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { type User } from '_common/reducers/auth';
import { changeUserRole } from '_common/reducers/auth';
import { clearOrders } from '_common/reducers/orders';
import { Page } from '@components/page';

const SwitchRolePage = () => {
  const { t } = useTranslation();
  const { currentRoleId, roles } = useAppSelector(
    ({ auth: { _id, options = [] } }) => {
      return {
        currentRoleId: _id,
        roles: [...options].sort((a, b) => a.name.localeCompare(b.name))
      };
    }
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onChange = (changeId: User['_id']) => {
    if (currentRoleId !== changeId) {
      dispatch(changeUserRole(changeId));
      dispatch(clearOrders());
    }
    navigate('/');
  };

  return (
    <Page>
      <PageHeader headerText={t('common:header.switchRole')} />
      {roles.map(({ _id, _type, name }) => {
        const subtitle =
          _type === 'consumers'
            ? t('common:Consumer')
            : _type === 'producers'
              ? t('common:Producer')
              : '';

        return (
          <RoleListElement
            key={_id}
            active={currentRoleId === _id}
            guest={_id === 'guest-consumer'}
            onClick={() => {
              onChange(_id);
            }}
            header={name}
            subTitle={subtitle}
          />
        );
      })}
      <CalloutLinkListElement
        to="/create-consumer"
        background="messages"
        header={t('signup:CreateConsumer')}
        subTitle={t('signup:RegisterAsBuyer')}
      />
      <CalloutLinkListElement
        to="/create-producer"
        background="messages"
        header={t('signup:CreateProducer')}
        subTitle={t('signup:RegisterAsSeller')}
      />
    </Page>
  );
};

export default SwitchRolePage;
