import { useTranslation } from '@dagens/frontend-i18n';
import { Button, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import styled from 'styled-components';
import { Illustrations } from '../../../utils/Illustrations';
import ResponsiveImage from '../utils/ResponsiveImage.style';
import { MediaQuery } from 'utils/mediaQueries';
import { CONSUMER_RESET_FILTERS_DELIVERY_DATE, track } from 'utils/mixpanel';

import { updateSelectedDeliveryDate } from '_consumer/reducers/productsForSale';
import { useMediaQuery } from '_common/hooks/useMediaQueries';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';

const ResetFiltersButton = styled(Button)`
  ${MediaQuery.mobileOnly} {
    width: 100%;
  }
`;

type EmptySearchProps = {
  clearFilters: () => void;
  noFiltersApplied: boolean;
};

const EmptySearch = ({ clearFilters, noFiltersApplied }: EmptySearchProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const mediaQuery = useMediaQuery();
  const { selectedDeliveryDate } = useAppSelector(({ productsForSale }) => {
    return {
      selectedDeliveryDate: productsForSale.selectedDeliveryDate
    };
  });

  const resetSearch = () => {
    clearFilters();
    if (selectedDeliveryDate) {
      dispatch(updateSelectedDeliveryDate(undefined));
      track(CONSUMER_RESET_FILTERS_DELIVERY_DATE);
    }
  };

  return (
    <Container
      flex
      direction={mediaQuery.tabletUp ? 'row' : 'column'}
      justify="space-between"
    >
      <Container maxWidth="480px" px="l" py="l">
        {noFiltersApplied ? (
          <>
            <Typography variant="paragraphBold" as="p" mb="s">
              {t('consumer:NoSearchResultWithoutFilter')}
            </Typography>
            <Typography variant="paragraph">
              {t('consumer:NoResultOnSearchString')}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="paragraphBold" as="p" mb="s">
              {t('consumer:NoSearchResultPage')}
            </Typography>
            <Typography variant="paragraph">
              {t('consumer:UpdateSearchString')}
            </Typography>
            <ResetFiltersButton
              text={t('consumer:ClearAllFilters')}
              mt="s"
              onClick={resetSearch}
              variant="primary"
            />
          </>
        )}
      </Container>
      <Container maxWidth="320px" px="l" py="l">
        <ResponsiveImage
          src={Illustrations.INTERESTED_CHEF}
          alt="No search result"
        />
      </Container>
    </Container>
  );
};

export default EmptySearch;
