import { useTranslation } from '@dagens/frontend-i18n';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import Theme from '@dagensmat/carrot/Theme';
import { useNavigate } from 'react-router-dom';
import { Illustrations } from '../../../utils/Illustrations';
import SignupProducerFields from '_common/components/signup/SignupProducerFields';
import SignupRequestButton from '_common/components/signup/SignupRequestButton';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { useSignupProducer } from '_common/hooks/useCreateRole';
import usePageView from '_common/hooks/usePageView';
import { loggedIn } from '_common/reducers/auth';
import SignupIntroduction from '_unauthenticated/pages/signup/SignupIntroduction';
import { Page } from '@components/page';
import { createProducer, getIdentity } from 'api';
import { updateLocalStorage } from 'utils/clientcache';
import {
  PRODUCER_SIGNUP_FAILED,
  PRODUCER_SIGNUP_PAGE_VIEW,
  PRODUCER_SIGNUP_SUBMITTED,
  track
} from 'utils/mixpanel';
import REQ from 'utils/REQ';

const CreateProducerPage = () => {
  usePageView(PRODUCER_SIGNUP_PAGE_VIEW);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { authenticated, userId, loginEmail } = useAppSelector(({ auth }) => {
    return {
      authenticated: auth.authenticated,
      userId: auth.uid,
      loginEmail: auth.loginEmail
    };
  });

  const [
    req,
    setReq,
    fields,
    onFieldChange,
    validation,
    validate,
    organization,
    onOrganizationChanged
  ] = useSignupProducer(authenticated);

  const { t } = useTranslation();

  const onSubmit = async () => {
    if (!validate()) return;

    const { name, contactPerson, address, phone, orgNumber } = fields;

    setReq(REQ.PENDING);
    track(PRODUCER_SIGNUP_SUBMITTED);

    try {
      const { _id } = await createProducer({
        name,
        contactPerson,
        address,
        phone,
        orgNumber,
        email: loginEmail,
        userId
      });

      setReq(REQ.SUCCESS);

      updateLocalStorage('currentRoleId', _id);
      const userData = await getIdentity({});

      dispatch(loggedIn(userData));

      navigate('/');
    } catch (e) {
      setReq(REQ.ERROR);
      track(PRODUCER_SIGNUP_FAILED);
    }
  };

  const { name, contactPerson, address, phone, orgNumber, acceptTerms } =
    fields;

  const { field: validationErrorField, message: validationErrorMessage } =
    validation;

  return (
    <Page>
      <SignupIntroduction
        illustration={Illustrations.PROUD_PRODUCER}
        header={t('signup:producerPageHeader')}
        introductionText={t('signup:ProducerSignupIntroductionText')}
        forOtherUser={t('signup:SignUpForConsumers')}
        otherUserQuery="/create-consumer"
      />
      <Callout mb="xl">
        <Typography variant="paragraph">
          {t('signup:CreateProducerCalloutText', {
            loginEmail
          })}
        </Typography>
      </Callout>
      <form
        onSubmit={async e => {
          e.preventDefault();
          await onSubmit();
        }}
        style={{ marginTop: Theme.Spacings.l }}
      >
        <SignupProducerFields
          name={name}
          contactPerson={contactPerson}
          address={address}
          phone={phone}
          orgNumber={orgNumber}
          acceptTerms={acceptTerms}
          validationErrorField={validationErrorField}
          isLoggedIn={authenticated}
          onChange={onFieldChange}
          organization={organization}
          onOrganizationChanged={onOrganizationChanged}
        />
        <SignupRequestButton
          req={req}
          onSubmit={onSubmit}
          buttonLabel={t('signup:userReadyToSell')}
          successMessage={t('signup:userCreatedSuccessMessage')}
          validationErrorMessage={validationErrorMessage}
        />
      </form>
    </Page>
  );
};

export default CreateProducerPage;
