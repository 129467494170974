/* eslint-disable readable-tailwind/sort-classes */
/* eslint-disable readable-tailwind/multiline */
import Theme from '@dagensmat/carrot/Theme';
import styled, { css } from 'styled-components';
import margins from 'utils/style';

const stateStyles = ({
  success,
  warning,
  error,
  showError
}: {
  success?: boolean;
  warning?: boolean;
  error?: boolean;
  showError?: boolean;
}) => {
  const initialBorderColor = Theme.Colours.lightGrey;
  const style = css`
    border: 2px solid ${initialBorderColor};
    &:disabled:hover {
      border-color: ${initialBorderColor};
    }
  `;

  if (error || showError) {
    return css`
      ${style}
      border-color:${Theme.Colours.error};

      &:hover {
        border-color: ${Theme.Colours.error};
      }

      &:focus:enabled {
        border-color: ${Theme.Colours.error};
      }
    `;
  }

  if (warning) {
    return css`
      ${style}
      border-color:${Theme.Colours.warning};

      &:hover {
        border-color: ${Theme.Colours.warning};
      }
    `;
  }

  if (success) {
    return css`
      ${style}
      border-color: ${Theme.Colours.black};
    `;
  }

  return style;
};

export const InputStyles = styled.input<{
  success?: boolean;
  error?: boolean;
  autoSave?: boolean;
  showError?: boolean;
}>`
  padding: ${Theme.Spacings.xxs + Theme.Spacings.xs}px;
  margin: 0;
  margin-bottom: ${({ autoSave, success, error }) => {
    return (
      [success, error, autoSave].some(Boolean) && `${Theme.Spacings.xxs}px`
    );
  }};
  outline: none;
  border-radius: 5px;
  background: ${Theme.Colours.white};
  font-size: ${Theme.FontSizes.inputText}px;
  font-weight: ${Theme.FontWeights.medium};
  width: 100%;

  ::placeholder {
    color: ${Theme.Colours.secondary};
  }

  &:hover {
    border-color: ${Theme.Colours.secondary};
  }

  &:focus:enabled {
    color: ${Theme.Colours.black};
    border-color: ${Theme.Colours.black};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  transition: border-color ${Theme.Animations.regular};

  ${stateStyles};
  ${margins};
`;
