import { Trans, useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { formatPhoneNumber } from '@dagensmat/core';
import { find } from 'lodash';
import { useAppSelector } from '_common/hooks/reduxHooks';

type ContactProducerTextProps = {
  producerId: string;
};

const PhoneLink = ({ phone }: { phone: string }) => {
  return (
    <Typography variant="link" href={`tel:${phone}`}>
      {formatPhoneNumber(phone)}
    </Typography>
  );
};

const ContactProducerText = ({ producerId }: ContactProducerTextProps) => {
  const { t } = useTranslation();

  const { producer } = useAppSelector(({ producers: { items } }) => {
    return {
      producer: find(items, { _id: producerId })
    };
  });

  if (!producer) return null;

  const { contactPerson, phone } = producer;

  return (
    <Typography variant="paragraphSmall" my="m">
      <Trans
        t={t}
        i18nKey="consumer:PleaseContactProducer"
        components={{
          PhoneLink: <PhoneLink phone={phone} />
        }}
        values={{
          contactPerson
        }}
      />
    </Typography>
  );
};

export default ContactProducerText;
