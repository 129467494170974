import { BasketItem, BasketState } from 'types/Basket';
import { ProductForSale } from 'types/Product';

const hasLocalStorage = () => {
  try {
    return 'localStorage' in window && window.localStorage !== null;
  } catch (e) {
    return false;
  }
};

const findBasketItemAmongSellableProducts = (
  item: BasketItem,
  sellableProducts: ProductForSale[]
) => {
  return sellableProducts.find(
    ({ _id, deliveryDates = [], soldOutDates = [] }) => {
      return (
        item.productId === _id &&
        deliveryDates.includes(item.deliveryDate) &&
        !soldOutDates.find(date => {
          return date === item.deliveryDate;
        })
      );
    }
  );
};

type LocalStorageKey =
  | 'defaultOverviewGroup'
  | 'cookieAccept'
  | 'basket'
  | 'currentRoleId'
  | 'preferredLabelQuantity'
  | 'fromHeader'
  | 'labelFormat'
  | string; // Can't set localStorage keys with user ID if not

export const getItemFromLocalStorage = (key: LocalStorageKey) => {
  const value = hasLocalStorage() ? window.localStorage.getItem(key) : null;
  return value ? JSON.parse(value) : null;
};

export const updateLocalStorage = (key: LocalStorageKey, data: any) => {
  return (
    hasLocalStorage() && window.localStorage.setItem(key, JSON.stringify(data))
  );
};

export const getBasketFromLocalStorage = (
  sellableProducts: ProductForSale[]
) => {
  const storedBasket: BasketState = getItemFromLocalStorage('basket')?.items
    ? getItemFromLocalStorage('basket')
    : { items: [], isAboveMinimumOrderThreshold: true };

  const validItems = storedBasket.items.filter(item => {
    return findBasketItemAmongSellableProducts(item, sellableProducts);
  });

  const validBasket = { ...storedBasket, items: validItems };
  updateLocalStorage('basket', validBasket);

  return validBasket;
};

export const getCurrentRoleIdFromLocalStorage = () => {
  return getItemFromLocalStorage('currentRoleId');
};

export const removeItemFromLocalStorage = (key: LocalStorageKey) => {
  return hasLocalStorage() && window.localStorage.removeItem(key);
};
