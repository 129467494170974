import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { Dinero } from 'dinero.js';
import { capitalize } from 'lodash';
import Separator from '_common/components/utils/Separator.style';
import { formatDate } from 'utils/date/format';
import { fmtMoney } from 'utils/texts';

type CheckoutFooterDateLineProps = {
  deliveryDate: string;
  dayTotal: Dinero<number>;
};

const CheckoutFooterDateLine = ({
  deliveryDate,
  dayTotal
}: CheckoutFooterDateLineProps) => {
  return (
    <>
      <Container flex justify="space-between" alignItems="baseline" mb="xs">
        <Typography variant="paragraphSmall">
          {capitalize(formatDate(deliveryDate))}
        </Typography>
        <Typography variant="paragraphSmall">{fmtMoney(dayTotal)}</Typography>
      </Container>
      <Separator />
    </>
  );
};

export default CheckoutFooterDateLine;
