import { useTranslation } from '@dagens/frontend-i18n';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { Order } from 'types/Order';

type OrderMessageProps = {
  order: Order;
  pointOfView: 'consumer' | 'producer';
};

const OrderMessage = ({ order, pointOfView }: OrderMessageProps) => {
  const { t } = useTranslation();
  const {
    producer,
    consumer,
    messageFromConsumerToProducer,
    messageFromProducerToConsumer
  } = order;

  const producerHeaderKey =
    pointOfView === 'producer'
      ? 'common:YourMessageTo'
      : 'common:MessageFromName';
  const consumerHeaderKey =
    pointOfView === 'producer'
      ? 'common:MessageFromName'
      : 'common:YourMessageTo';

  const headerKey = messageFromProducerToConsumer
    ? producerHeaderKey
    : consumerHeaderKey;

  const message =
    messageFromProducerToConsumer || messageFromConsumerToProducer;
  const { name } = pointOfView === 'producer' ? consumer : producer;

  return message ? (
    <Callout mb="xs" colour="messages">
      <Typography variant="paragraphBold" as="p">
        {t(headerKey, { name })}
      </Typography>
      <Typography variant="paragraph">{message}</Typography>
    </Callout>
  ) : null;
};
export default OrderMessage;
