import { tv } from 'tailwind-variants';
import { ReactComponent as DagensLogo } from './logo.svg';

const style = tv({
  base: 'w-[64px]'
});

export const Logo = () => {
  return <DagensLogo className={style()} />;
};
