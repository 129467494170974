import { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';
import { ThemeColor } from '../../theme/colors';

const style = tv({
  base: `
    rounded
    p-s
    text-paragraph
  `,
  slots: {
    title: `text-paragraphBold`
  },
  variants: {
    variant: {
      lightGrey: `bg-lightGrey`,
      noteworthy: `bg-noteworthy`
    },
    small: {
      true: {
        base: `text-paragraphSmall`,
        title: `text-paragraphSmallBold`
      }
    }
  },
  defaultVariants: {
    variant: 'noteworthy'
  }
});

type Props = PropsWithChildren<{
  small?: boolean;
  title?: string;
  variant?: Extract<ThemeColor, 'lightGrey' | 'noteworthy'>;
}>;

export const Callout = ({ small, title, variant, children }: Props) => {
  const { base, title: titleStyle } = style({ small, variant });
  return (
    <div className={base()}>
      {title && <div className={titleStyle()}>{title}</div>}
      {children}
    </div>
  );
};
