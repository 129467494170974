import { boxStyles } from '@dagens/carrot';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

const style = tv({
  base: `
    flex
    justify-between
    py-s
    pb-m
  `,
  variants: {
    border: {
      true: `
        border-t
        border-t-secondary
      `
    }
  }
});

type Props = PropsWithChildren<{
  border?: boolean;
}>;

export const BottomActions = ({ border, children }: Props) => {
  return (
    <div className={twMerge(boxStyles.fullWidth(), style({ border }))}>
      {children}
    </div>
  );
};
