import { ButtonComponent, ButtonComponentProps } from './as-button';
import { LinkComponent, LinkComponentProps } from './as-link';
import { NavLinkComponent, NavLinkComponentProps } from './as-nav-link';

type InternalProps =
  | LinkComponentProps
  | NavLinkComponentProps
  | ButtonComponentProps;

export const ButtonWrapperInternal = (props: InternalProps) => {
  if (props.as === 'link') {
    return <LinkComponent {...props} />;
  }

  if (props.as === 'nav-link') {
    return <NavLinkComponent {...props} />;
  }

  return <ButtonComponent {...props} />;
};

export type ButtonWrapperProps = InternalProps & { className?: never };

export const ButtonWrapper = (props: ButtonWrapperProps) => {
  return <ButtonWrapperInternal {...props} />;
};
