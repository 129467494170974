import { tv } from 'tailwind-variants';

const style = tv({
  base: `
    whitespace-nowrap
    text-paragraphSmall
    text-secondary
  `
});

type Props = {
  maxLength: number;
  valueLength: number;
};

export const FormFieldCharCount = ({ maxLength, valueLength }: Props) => {
  return (
    <div className={style()}>
      {valueLength} / {maxLength}
    </div>
  );
};
