import { forSaleStatuses } from '@dagensmat/core';
import { pick } from 'lodash';
import { ForSaleStatus, SeasonCalendar } from 'types/Product';
import { daysUntilAvailabilityChanges } from 'utils/season';

export const byFunction = <T>(func: (val: T) => any) => {
  return (a: T, b: T) => {
    return func(a) < func(b) ? -1 : 1;
  };
};

export const byKey = (key: string) => {
  return (a: any, b: any) => {
    return a[key] < b[key] ? -1 : 1;
  };
};

export const byDescending = (key: string) => {
  return (a: any, b: any) => {
    return a[key] < b[key] ? 1 : -1;
  };
};

export const byUniqueId = (
  val: { _id: string },
  index: number,
  self: any[]
) => {
  return (
    self.findIndex(a => {
      return a._id === val._id;
    }) === index
  );
};

const arrayIncludes = <T>(arr: T[] = []) => {
  return (val: T) => {
    return arr.includes(val);
  };
};

export const arrayDoesNotInclude = <T>(arr: T[] = []) => {
  return (val: T) => {
    return !arrayIncludes(arr)(val);
  };
};

type WithItemKey<T extends string, U> = { [Key in T]: U[] };

export const grouper = <
  T extends { key: string },
  TKeepKeys extends keyof T,
  TItemsKey extends string = 'items'
>(
  arr: T[],
  keep: TKeepKeys[] = [],
  itemsKey: TItemsKey = 'items' as TItemsKey
) => {
  return arr.reduce(
    (acc, curr: T) => {
      const { key } = curr;
      // @ts-expect-error: TODO(strict)
      acc[key] = acc[key] || {
        ...pick(curr, ['key', ...keep]),
        [itemsKey]: []
      };
      // @ts-expect-error: TODO(strict)
      acc[key][itemsKey].push(curr);

      return acc;
    },
    {} as Record<
      T['key'],
      WithItemKey<TItemsKey, T> & WithKey<T> & Pick<T, TKeepKeys>
    >
  );
};

type WithKey<T> = T & { key: string };
export const addKey = <T>(
  func: (val: T) => string
): ((val: T) => WithKey<T>) => {
  return (val: T) => {
    return {
      ...val,
      key: func(val)
    };
  };
};

export const filterSearchHits = <T extends { _id: string }>(
  hits: string[],
  data: T[]
) => {
  return hits
    ? data.filter(({ _id }) => {
        return hits.some(hitId => {
          return hitId === _id;
        });
      })
    : data;
};

type HasSeasonCalendar = {
  seasonCalendar: SeasonCalendar;
};

export const byClosestDate = (today = new Date()) => {
  return (productA: HasSeasonCalendar, productB: HasSeasonCalendar) => {
    const daysFromChangeUntilToday = daysUntilAvailabilityChanges(today);
    return (
      daysFromChangeUntilToday(productA) - daysFromChangeUntilToday(productB)
    );
  };
};

const indexOfForSaleStatus = (status: ForSaleStatus) => {
  return Object.keys(forSaleStatuses).indexOf(status);
};

export const byForSaleStatus = (
  a: { forSaleStatus: ForSaleStatus },
  b: { forSaleStatus: ForSaleStatus }
) => {
  return (
    indexOfForSaleStatus(a.forSaleStatus) -
    indexOfForSaleStatus(b.forSaleStatus)
  );
};

export const truncate = <T>({
  list,
  numToShow = 2,
  addendum
}: {
  list: T[];
  numToShow?: number;
  addendum: string;
}): string => {
  return (
    list.slice(0, numToShow).join(', ') +
    (list.length > numToShow ? ` + ${list.length - numToShow} ${addendum}` : '')
  );
};
