import { Typography } from '@dagensmat/carrot/Components';
import ProductReference from '../product-reference/ProductReference';
import { ClickActionIconContainer } from '_common/components/table/CommonTable.style';
import Icon from '_common/components/utils/Icon';
import {
  getDeliveredUnitsWithUnitText,
  hasOrderLineBeenUpdated
} from '_common/reducers/orders';
import PricingDisplay from '_producer/components/pricing/PricingDisplay';
import { OrderLine } from 'types/Order';
import { formatTextWithUnits } from 'utils/texts';

type ChangedQuantityCellProps = {
  line: OrderLine;
};

type OrderLineTableRowProps = {
  line: OrderLine;
  onClick: () => void;
  isReadOnly?: boolean;
};

const ChangedQuantityCell = ({ line }: ChangedQuantityCellProps) => {
  const {
    nrOfUnits,
    pricingAtTimeOfOrder: { orderedUnit }
  } = line;
  const orderedUnitsWithUnit = formatTextWithUnits(orderedUnit, nrOfUnits);

  return (
    <>
      <Typography variant="paragraphSmall">
        {getDeliveredUnitsWithUnitText(line)}
      </Typography>
      {hasOrderLineBeenUpdated(line) && (
        <Typography
          variant="paragraphSmall"
          color="secondary"
          style={{ textDecoration: 'line-through' }}
        >
          {orderedUnitsWithUnit}
        </Typography>
      )}
    </>
  );
};

const OrderLineTableRow = ({
  line,
  onClick,
  isReadOnly = false
}: OrderLineTableRowProps) => {
  const { pricingAtTimeOfOrder, product, batchIdentification } = line;

  return (
    <tr style={!isReadOnly ? { cursor: 'pointer' } : {}} onClick={onClick}>
      <td>
        <ProductReference product={product} variant="small" />
        <PricingDisplay price={pricingAtTimeOfOrder} color="secondary" />
        <Typography variant="paragraphSmall" color="secondary">
          {batchIdentification}
        </Typography>
      </td>
      <td
        style={{
          position: 'relative',
          paddingRight: isReadOnly ? undefined : '30px'
        }}
      >
        <ChangedQuantityCell line={line} />
        {!isReadOnly && (
          <ClickActionIconContainer>
            <Icon icon="edit" $size="m" fill="deepPurple" />
          </ClickActionIconContainer>
        )}
      </td>
    </tr>
  );
};

export default OrderLineTableRow;
