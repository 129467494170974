import { Button, Icon, IconType } from '@dagens/carrot';
import { ForwardedRef, forwardRef } from 'react';

type Props = {
  text?: string;
  icon?: IconType;
  to?: string;
  focused: boolean;
};

const Component = (
  { text, icon, to, focused }: Props,
  ref: ForwardedRef<HTMLAnchorElement>
) => {
  if (!to) {
    return null;
  }

  return (
    <Button
      linkRef={ref}
      size="regular"
      variant="borderless"
      alignment="left"
      focused={focused}
      as="link"
      to={to}
    >
      {icon && <Icon icon={icon} size="medium" />}
      {text}
    </Button>
  );
};

export const CommandPaletteResult = forwardRef(Component);
