import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    mb-xl
    flex
    flex-col
    gap-xl
  `
});
