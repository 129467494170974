import { useCallback, useState } from 'react';
import { CommandPaletteInput } from '@components/command-palette/input';
import { CommandPaletteModal } from '@components/command-palette/modal';
import { CommandPalettePanel } from '@components/command-palette/panel';
import { CommandPaletteResults } from '@components/command-palette/results';

export const CommandPalette = () => {
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
    setQuery('');
  }, []);

  return (
    <CommandPaletteModal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={onClose}
    >
      <CommandPalettePanel onValueChange={onClose}>
        <CommandPaletteInput setQuery={setQuery} />
        <CommandPaletteResults query={query} />
      </CommandPalettePanel>
    </CommandPaletteModal>
  );
};
