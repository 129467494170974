import { ComboboxOptions } from '@headlessui/react';
import { tv } from 'tailwind-variants';
import { useAddressResults } from '../delivery-information-form/use-address-results';
import { DeliveryAddressResult } from './DeliveryAddressResult';

const style = tv({
  base: `
    flex
    max-h-[200px]
    flex-col
    justify-start
    overflow-y-auto
    rounded-small
    border
    border-solid
    border-brightPurple
    bg-white
    shadow-lg
  `
});

type Props = {
  query: string;
};

/**
 * @package
 */
export const DeliveryAddressResults = ({ query }: Props) => {
  const { addressesReq, addressesResults } = useAddressResults(query);
  if (query === '') {
    return null;
  }
  return (
    <ComboboxOptions static transition as="ul" className={style()}>
      <DeliveryAddressResult req={addressesReq} results={addressesResults} />
    </ComboboxOptions>
  );
};
