import { useTranslation } from '@dagens/frontend-i18n';
import { capitalize, CountryCode, getCountry } from '@dagensmat/core';
import { AnyAction } from '@reduxjs/toolkit';
import { find, omit, pick } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector
} from '../../../_common/hooks/reduxHooks';
import PageHeader from '@components/page-header/PageHeader';
import usePageView from '_common/hooks/usePageView';
import ProductForm from '_producer/components/product-form/ProductForm';
import {
  clearProduct,
  InitialProductState,
  updateProduct as updateProductEdits
} from '_producer/reducers/newProduct';
import { productEdited as updateProductInStore } from '_producer/reducers/products';
import { ActionButton } from '@components/action-button';
import { BottomActions } from '@components/bottom-actions';
import { Page } from '@components/page';
import {
  deleteProduct,
  getProducerDeliveryDates,
  postProductFieldsUpdate
} from 'api';
import {
  Category,
  EditableProduct,
  EditableProductFieldChanger,
  ProducerProduct
} from 'types/Product';
import { formatDateWithTime } from 'utils/date/format';
import { EDIT_PRODUCT_PAGE_VIEW } from 'utils/mixpanel';
import {
  convertPrices,
  isMeasureUnitValid,
  pricesComplete,
  shouldPriceAllowZeroWeight
} from 'utils/pricing';
import REQ, { ReqType } from 'utils/REQ';

const useUpdateProductOnInit = (
  initialProduct: ProducerProduct | undefined,
  dispatch: Dispatch<AnyAction>
) => {
  useEffect(() => {
    dispatch(updateProductEdits({ ...initialProduct, readyToEdit: true }));
    return () => {
      dispatch(clearProduct());
    };
  }, [initialProduct, dispatch]);
};

const useOnFieldChange = (
  setHasChanges: Dispatch<SetStateAction<boolean>>,
  setSaveReq: Dispatch<SetStateAction<ReqType>>,
  dispatch: Dispatch<AnyAction>
) => {
  const onFieldChanged: EditableProductFieldChanger = key => {
    return change => {
      setHasChanges(true);
      setSaveReq(REQ.INIT);
      dispatch(updateProductEdits({ [key]: change }));
    };
  };

  return onFieldChanged;
};

const useOnSave = ({
  productId,
  newProduct,
  setHasChanges,
  setSaveReq,
  dispatch
}: {
  productId?: string;
  newProduct?: InitialProductState;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
  setSaveReq: Dispatch<SetStateAction<ReqType>>;
  dispatch: Dispatch<AnyAction>;
}) => {
  const onSave = useCallback(async () => {
    try {
      setSaveReq(REQ.PENDING);
      await postProductFieldsUpdate({
        productId,
        ...pick(newProduct, [
          'name',
          'type',
          'description',
          'categoryId',
          'image',
          'certificationIds',
          'forSaleStatus',
          'seasonCalendar',
          'temperatureZone',
          'capacities',
          'processedState'
        ]),
        prices: convertPrices(newProduct?.prices ?? [])
      });
      dispatch(
        updateProductInStore(omit(newProduct, ['isReady']) as ProducerProduct)
      );
      setHasChanges(false);
      setSaveReq(REQ.SUCCESS);
    } catch (error) {
      setSaveReq(REQ.ERROR);
    }
  }, [dispatch, newProduct, setHasChanges, setSaveReq]);

  return onSave;
};

const useOnDelete = (
  dispatch: Dispatch<AnyAction>,
  setSaveReq: Dispatch<SetStateAction<ReqType>>
) => {
  const navigate = useNavigate();
  const onDelete = useCallback(
    async (productId: string) => {
      try {
        await deleteProduct({ productId });
        dispatch(
          updateProductInStore({
            _id: productId,
            _updatedAt: new Date().toISOString(),
            deleted: true
          })
        );
        navigate('/my-products');
      } catch (error) {
        setSaveReq(REQ.ERROR);
      }
    },
    [dispatch, navigate]
  );

  return onDelete;
};

const useFetchDeliveryDates = (producerId: string | undefined) => {
  const [deliveryDates, setDeliveryDates] = useState([]);

  useEffect(() => {
    async function fetchDeliveryDates() {
      const dates = await getProducerDeliveryDates({
        producerId
      });
      setDeliveryDates(dates);
    }
    fetchDeliveryDates();
  }, [producerId]);

  return deliveryDates;
};

const getIsValid = (
  product: Partial<EditableProduct>,
  categories: Category[]
) => {
  const {
    name,
    forSaleStatus,
    prices = [],
    processedState,
    categoryId,
    seasonCalendar
  } = product;

  const allowZeroWeight = shouldPriceAllowZeroWeight(categoryId, categories);
  const allPricesHaveWeights = prices.every(pricing => {
    return pricing && isMeasureUnitValid(pricing);
  });

  const isValid = [
    pricesComplete(prices || []),
    forSaleStatus,
    name,
    processedState,
    categoryId,
    seasonCalendar && seasonCalendar.length > 0,
    allowZeroWeight || allPricesHaveWeights
  ].every(Boolean);
  return isValid;
};

type Props = {
  product: ProducerProduct | undefined;
  countryCode: CountryCode | undefined;
  newProduct: InitialProductState;
  producerId: string | undefined;
};

const EditProductPage = ({
  product,
  newProduct,
  producerId,
  countryCode
}: Props) => {
  usePageView(EDIT_PRODUCT_PAGE_VIEW);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [initialProduct] = useState(product);
  const [saveReq, setSaveReq] = useState<ReqType>(REQ.INIT);
  const [, /* hasChanges */ setHasChanges] = useState(false);
  const { _updatedAt, _id: productId } = product ?? {};
  const { categories } = useAppSelector(({ producerCategories }) => {
    return { categories: producerCategories.categories };
  });

  useUpdateProductOnInit(initialProduct, dispatch);
  const deliveryDates = useFetchDeliveryDates(producerId);
  const onFieldChange = useOnFieldChange(setHasChanges, setSaveReq, dispatch);
  const onSave = useOnSave({
    productId,
    newProduct,
    setHasChanges,
    setSaveReq,
    dispatch
  });
  const onDelete = useOnDelete(dispatch, setSaveReq);

  const isValid = getIsValid(newProduct, categories);
  return (
    <Page
      bottom={
        <BottomActions>
          <ActionButton.Save
            saveReq={saveReq}
            redirectTo="/my-products"
            onClick={onSave}
            disabled={!isValid}
            feedbackText={{
              [REQ.PENDING]: t('producer:ProductSavingChangesButtonFeedback'),
              [REQ.SUCCESS]: t('producer:ProductChangesSavedButtonFeedback')
            }}
          >
            {isValid
              ? t('producer:ProductSaveChangesButtonFeedback')
              : t('producer:ProductSaveChangesMissingFieldsFeedback')}
          </ActionButton.Save>
        </BottomActions>
      }
    >
      <PageHeader
        headerText={newProduct.name}
        subTitle={`${t('producer:productLastEdited')}  ${
          _updatedAt ? capitalize(formatDateWithTime(_updatedAt)) : ''
        }`}
      />
      <ProductForm
        fieldChanged={onFieldChange}
        product={newProduct}
        onDelete={() => {
          if (productId) {
            onDelete(productId);
          }
        }}
        /* hasChanges={hasChanges} */
        deliveryDates={deliveryDates}
        countryCode={countryCode}
      />
    </Page>
  );
};

const EditProductPageFetcher = () => {
  const { id } = useParams();
  const { countryCode, newProduct, producerId, product, req } = useAppSelector(
    ({
      producerProducts: { items, req },
      newProduct,
      auth: { _id: producerId, organization }
    }) => {
      const product = find(items, { _id: id });
      return {
        req: req === REQ.SUCCESS && !product ? REQ.NOT_FOUND : req,
        product,
        newProduct,
        producerId,
        countryCode: organization ? getCountry(organization) : undefined
      };
    }
  );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return (
    <EditProductPage
      key={id}
      product={product}
      countryCode={countryCode}
      newProduct={newProduct}
      producerId={producerId}
    />
  );
};

export default EditProductPageFetcher;
