import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import Theme from '@dagensmat/carrot/Theme';
import { map } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import FormValidationMessage from '../../../_common/components/input/FormValidationMessage';
import { isMeasureUnitValid } from '../../../utils/pricing';
import { ButtonLink } from '_common/components/button/Button.style';
import ConfirmButton from '_common/components/confirm-button/ConfirmButton';
import Icon from '_common/components/utils/Icon';
import Tag from '_common/components/utils/Tag';
import { useAppSelector } from '_common/hooks/reduxHooks';
import PricingDisplay from '_producer/components/pricing/PricingDisplay';
import { findConsumersById } from '_producer/reducers/consumers';
import { EditablePricing, Pricing } from 'types/Product';
import { truncate } from 'utils/array';
import {
  EDIT_PRODUCT_CLICK_CONFIRM_TO_DELETE_OFFER,
  EDIT_PRODUCT_CLICK_EDIT_OFFER,
  track
} from 'utils/mixpanel';

export type OfferType = 'regular' | 'custom';

type OfferTagProps = {
  offerType: OfferType;
};

type PricingPreviewProps = {
  allowZeroWeight?: boolean;
  productId: string | undefined;
  pricing: EditablePricing | Pricing;
  numberOfPrices: number;
  onDelete?: () => void;
  offerType: OfferType;
  showError?: boolean;
};

const PricingPreviewStyle = styled.div<{
  error?: boolean;
}>`
  position: relative;
  display: block;
  padding: ${Theme.Spacings.s}px;
  border: ${Theme.BorderWeights.regular}px solid
    ${({ error }) => {
      return error ? Theme.Colours.error : Theme.Colours.black;
    }};
  border-radius: ${Theme.BorderRadius.regular}px;
`;

const TagContainer = styled.div`
  position: absolute;
  right: ${Theme.Spacings.s}px;
`;

const OfferTag = ({ offerType }: OfferTagProps) => {
  if (offerType === 'regular') return null;
  const { t } = useTranslation();

  return (
    <TagContainer>
      <Tag text={t('common:CustomOffer')} bgColor={Theme.Colours.noteworthy} />
    </TagContainer>
  );
};

const PricingPreview = ({
  allowZeroWeight,
  productId,
  pricing,
  numberOfPrices,
  onDelete,
  offerType,
  showError
}: PricingPreviewProps) => {
  const { t } = useTranslation();
  const [pendingDelete, setPendingDelete] = React.useState(false);
  const consumers = useAppSelector(({ consumers: { items } }) => {
    return items;
  });
  const selectedConsumerNames = map(
    findConsumersById(map(pricing.specialConsumers, '_ref'), consumers),
    'name'
  );

  const isWeightValid = isMeasureUnitValid(pricing) || allowZeroWeight;
  return (
    <Container flex direction="column">
      <PricingPreviewStyle error={!isWeightValid && showError}>
        <OfferTag offerType={offerType} />
        <Typography variant="paragraphBold" as="p" mb="xxs">
          {selectedConsumerNames.length > 0
            ? truncate({
                list: selectedConsumerNames,
                numToShow: 1,
                addendum: t('common:more')
              })
            : t('common:AllBuyers')}
        </Typography>
        <Container mb="xxs">
          <PricingDisplay price={pricing} />
        </Container>
        {!pendingDelete && onDelete && (
          <ButtonLink
            variant="borderless"
            to={`/my-products/${productId}/edit-pricing/${pricing._key}`}
            onClick={() => {
              track(EDIT_PRODUCT_CLICK_EDIT_OFFER);
            }}
          >
            <Icon icon="editSmall" mr={Theme.Spacings.xxs} fill="deepPurple" />
            {t('common:Edit')}
          </ButtonLink>
        )}
        {numberOfPrices > 1 && onDelete && (
          <ConfirmButton
            buttonVariant="borderless"
            buttonText={
              <>
                <Icon icon="delete" fill="deepPurple" />
                {t('common:Delete')}
              </>
            }
            confirmText={t('producer:SureDeletePrice')}
            confirmButtonText={t('producer:YesDeletePrice')}
            rejectButtonText={t('producer:NoKeepPrice')}
            confirmedText={t('producer:DeletingPrice')}
            onClick={() => {
              setPendingDelete(true);
            }}
            onConfirm={() => {
              track(EDIT_PRODUCT_CLICK_CONFIRM_TO_DELETE_OFFER);
              onDelete();
            }}
            onReject={() => {
              setPendingDelete(false);
            }}
            display="inline-block"
          />
        )}
      </PricingPreviewStyle>

      {!isWeightValid && showError && (
        <FormValidationMessage
          message={t('producer:productInputMissingRequiredWeight')}
          fill="error"
        />
      )}
    </Container>
  );
};

export default PricingPreview;
