import { FormHTMLAttributes, PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';

type FormProps = FormHTMLAttributes<HTMLFormElement>;

export const styles = tv({
  base: `
    flex
    flex-col
    gap-jumbo
  `
});

type Props = PropsWithChildren<{
  onSubmit?: FormProps['onSubmit'];
}>;

export const FormContainer = ({ onSubmit, children }: Props) => {
  return (
    <form className={styles()} onSubmit={onSubmit}>
      {children}
    </form>
  );
};
