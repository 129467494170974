import { PropsWithChildren, Ref } from 'react';
import { NavLink as ReactRouterNavLink, NavLinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';
import { Simplify } from 'type-fest';

import { buttonStyle, ButtonStyles } from '../styles';

const NAV_LINK_ACTIVE_CUSTOM_CLASS = 'active';

const contentStyle = tv({
  base: `
    relative
    inline-flex
    size-full
    items-center
    justify-center
    gap-x-xxs
    p-xxs
  `
});

export type NavLinkComponentProps = Simplify<
  PropsWithChildren<
    ButtonStyles & {
      as: 'nav-link';
      navLinkRef?: Ref<HTMLAnchorElement>;
      active?: boolean;

      className?: NavLinkProps['className'];
      to: NavLinkProps['to'];
    }
  >
>;

export const NavLinkComponent = ({
  // style props
  active,
  alignment,
  fillParent,
  focused,
  size,
  variant,

  // link props
  to,

  // react props
  children,
  className,
  navLinkRef
}: NavLinkComponentProps) => {
  return (
    <ReactRouterNavLink
      ref={navLinkRef}
      className={({ isActive, isPending, isTransitioning }) => {
        const navLinkActive = (isActive || active) ?? false;
        return twMerge(
          buttonStyle({
            alignment,
            fillParent,
            focused,
            size,
            variant
          }),
          navLinkActive && NAV_LINK_ACTIVE_CUSTOM_CLASS,
          typeof className === 'function'
            ? className({ isActive: navLinkActive, isPending, isTransitioning })
            : className
        );
      }}
      to={to}
    >
      <div className={contentStyle()}>{children}</div>
    </ReactRouterNavLink>
  );
};
