import { useTranslation } from '@dagens/frontend-i18n';
import Theme from '@dagensmat/carrot/Theme';
import styled from 'styled-components';
import { NumberInput } from '../../../carrot/input-number';
import { TemperatureZone } from 'types/Product';
import { MediaQuery } from 'utils/mediaQueries';

type IParcelInput = {
  orderId: string;
  count: number;
  temperatureZone: TemperatureZone;
  onChange: (val: {
    orderId: string;
    count: number;
    temperatureZone: TemperatureZone;
  }) => void;
};

const ParcelLabelInputContainer = styled.div`
  margin-right: ${Theme.Spacings.xxs}px;

  :last-child {
    margin-right: 0;
  }

  ${MediaQuery.tabletUp} {
    :first-child {
      margin-left: ${Theme.Spacings.m + Theme.Spacings.xxs}px;
    }
  }
`;

const ParcelInput = ({
  orderId,
  count,
  temperatureZone,
  onChange
}: IParcelInput) => {
  const { t } = useTranslation();
  return (
    <ParcelLabelInputContainer>
      <NumberInput
        unit={t(`common:${temperatureZone}`)}
        width={104}
        value={count}
        onChange={amount => {
          onChange({
            orderId,
            count: Number(amount),
            temperatureZone
          });
        }}
        id={orderId}
      />
    </ParcelLabelInputContainer>
  );
};
export default ParcelInput;
