import { Trans, useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import styled from 'styled-components';
import SectionHOC from '_common/components/section-hoc/SectionHOC';
import { ButtonLink } from '_common/components/button/Button.style';
import { B } from 'utils/fonts';

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 490px;
`;

type ProfileMinimumOrderSectionProps = {
  minimumOrderAmount?: number;
};

const ProfileMinimumOrderSection = ({
  minimumOrderAmount = 0
}: ProfileMinimumOrderSectionProps) => {
  const { t } = useTranslation();
  return (
    <MaxWidthContainer>
      <Typography variant="paragraph" mb="s">
        {minimumOrderAmount ? (
          <Trans
            t={t}
            i18nKey="producer:MinimumOrderAmountLinkDescriptionWhenSet"
            values={{ minimumOrderAmount }}
            components={{ B: <B /> }}
          />
        ) : (
          t('producer:MinimumOrderAmountLinkDescriptionWhenNotSet')
        )}
      </Typography>

      <ButtonLink
        to={{
          pathname: '/profile/minimum-order'
        }}
        state={{ returnPath: true }}
        variant="ghost"
      >
        {minimumOrderAmount
          ? t('producer:MinimumOrderLinkText')
          : t('producer:MinimumOrderSetLinkText')}
      </ButtonLink>
    </MaxWidthContainer>
  );
};

export default SectionHOC(ProfileMinimumOrderSection);
