import 'react-day-picker/dist/style.css';

import Theme from '@dagensmat/carrot/Theme';
import { Locale } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import styled from 'styled-components';

export const StyledDateRangePicker = styled(DayPicker)<{
  numberOfMonths: number;
  pagedNavigation: boolean;
  weekStartsOn: number;
  mode: 'range';
  onDayClick: (day: Date) => void;
  locale: Locale;
}>`
  margin: 10px 0;
  display: block;

  &--today {
    color: ${Theme.Colours.secondary};
  }

  .rdp-caption {
    text-transform: capitalize;
  }

  .rdp-caption_label {
    font-weight: ${Theme.FontWeights.semiBold};
    line-height: ${Theme.Spacings.m}px;
    font-size: ${Theme.FontSizes.paragraph}px;
    letter-spacing: 0.1px;
  }

  .rdp-months {
    justify-content: left;
  }

  .rdp-day {
    margin-top: ${Theme.Spacings.xxs / 2}px;
    margin-bottom: ${Theme.Spacings.xxs / 2}px;
  }

  .rdp-button {
    border-radius: ${Theme.BorderRadius.regular}px;
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: ${Theme.Colours.brightPurple};
  }

  .rdp-day_range_middle {
    color: ${Theme.Colours.black};
    background-color: ${Theme.Colours.availability};
    border-radius: 0px;
  }

  .rdp-day_range_start {
    color: ${Theme.Colours.black};
    background-color: ${Theme.Colours.availability};
    border-top-left-radius: ${Theme.BorderRadius.regular}px;
    border-bottom-left-radius: ${Theme.BorderRadius.regular}px;
  }

  .rdp-day_range_end {
    color: ${Theme.Colours.black};
    background-color: ${Theme.Colours.availability};
    border-top-right-radius: ${Theme.BorderRadius.regular}px;
    border-bottom-right-radius: ${Theme.BorderRadius.regular}px;
  }
`;
