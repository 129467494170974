import { t } from 'i18next';
import { createBrowserRouter, redirect } from 'react-router-dom';

import OrdersHistoryPage from '../_common/pages/order-history/OrdersHistoryPage';
import { ConsumerLayout } from './layout';
import CheckoutPage from './pages/checkout-page/CheckoutPage';
import FavoritesPage from './pages/favorites/FavoritesPage';
import CustomerLandingPage from './pages/landing-page/CustomerLandingPage';
import OnboardingPage from './pages/onboarding/OnboardingPage';
import ConsumerOrdersOverviewPage from './pages/orders/ConsumerOrdersOverviewPage';
import ConsumerOrdersPage from './pages/orders/ConsumerOrdersPage';
import ConsumerProductRequestPage from './pages/orders/ConsumerProductRequestPage';
import ConsumerProductRequestsPage from './pages/orders/ConsumerProductRequestsPage';
import ProducerListPage from './pages/producer-list-page/ProducerListPage';
import ConsumerProfilePage from './pages/profile/ConsumerProfilePage';
import { ExtendedRouteObject } from 'types/routes';
import LoginPage from '_unauthenticated/pages/login/Login';
import VerifyUserEmail from '_common/pages/VerifyUserEmail';
import SwitchRolePage from '_common/pages/switch-role-page/SwitchRolePage';
import RootResolver from '_common/pages/RootResolver';
import OrderPageFetcher from '_common/pages/order/OrderPageFetcher';
import LogoutPage from '_common/pages/LogoutPage';
import DeliveryInformationPage from '_common/pages/deliveryInformation/DeliveryInformationPage';
import CreateProducerPage from '_common/pages/create-role/CreateProducerPage';
import CreateConsumerPage from '_common/pages/create-role/CreateConsumerPage';
import CookiesInfoPage from '_common/pages/CookiesInfoPage';
import BillingAccountPage from '_common/pages/billing-account/BillingAccountPage';

export const consumerRoutes = () => [
  {
    path: '/',
    element: <ConsumerLayout />,
    children: [
      {
        index: true,
        title: t('common:Home'),
        icon: 'home',
        element: <CustomerLandingPage />
      },
      {
        path: '/profile',
        title: t('consumer:MyProfile'),
        icon: 'profile',
        element: <ConsumerProfilePage />
      },
      {
        path: '/billing-account',
        title: t('common:BillingAccountHeader'),
        icon: 'profile',
        element: <BillingAccountPage />
      },
      {
        path: '/delivery-information',
        title: t('common:DeliveryInformation'),
        icon: 'profile',
        element: <DeliveryInformationPage />
      },
      {
        path: '/producers',
        title: t('common:header.producers'),
        icon: 'profile',
        element: <ProducerListPage />
      },
      {
        path: '/orders',
        title: t('common:MyOrders'),
        icon: 'orders',
        element: <ConsumerOrdersPage />
      },
      {
        path: '/orders/overview',
        title: `${t('common:MyOrders')}, ${t('common:Product_other')}`,
        icon: 'orders',
        element: <ConsumerOrdersOverviewPage />
      },
      {
        path: '/orders/history',
        title: t('common:OrderHistory'),
        icon: 'orders',
        element: <OrdersHistoryPage />
      },
      {
        path: '/orders/product-requests',
        title: `${t('common:MyOrders')}, ${t('consumer:Requests')}`,
        icon: 'messages',
        element: <ConsumerProductRequestsPage />
      },
      {
        path: '/orders/product-requests/:id',
        element: <ConsumerProductRequestPage />
      },
      { path: '/orders/:secret', element: <OrderPageFetcher /> },
      {
        path: '/basket',
        title: t('consumer:Checkout'),
        icon: 'basket',
        element: <CheckoutPage />
      },
      {
        path: '/switch-role',
        title: t('common:header.switchRole'),
        icon: 'profile',
        element: <SwitchRolePage />
      },
      {
        path: '/favourites',
        title: t('consumer:MyFavorites'),
        icon: 'favorite',
        element: <FavoritesPage />
      },
      { path: '/onboarding/:step', element: <OnboardingPage /> },
      { path: '/login', element: <LoginPage /> },
      { path: '/logout', element: <LogoutPage /> },
      { path: '/cookies', element: <CookiesInfoPage /> },
      { path: '/user/verify/:token', element: <VerifyUserEmail /> },
      { path: '/create-consumer', element: <CreateConsumerPage /> },
      { path: '/create-producer', element: <CreateProducerPage /> },
      { path: '/:handle', element: <RootResolver /> },
      {
        path: '/producers/:handle',
        loader: ({ params }) => {
          return redirect(`/${params.handle}`);
        }
      },
      {
        path: '*',
        loader: () => {
          return redirect('/');
        }
      }
    ] satisfies ExtendedRouteObject[]
  }
];

export const consumerRouter = () => {
  return createBrowserRouter(consumerRoutes());
};
