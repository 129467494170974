import { useTranslation } from '@dagens/frontend-i18n';
import { Button, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '../utils/Icon';
import REQ, { ReqType } from 'utils/REQ';

const EmailContainer = styled(Container)<{ removing?: boolean }>`
  text-decoration: ${({ removing }) => {
    return removing ? 'line-through' : 'none';
  }};
`;

type Props = {
  email: string;
  hideRemoveButton: boolean;
  onRemove: (email: string) => Promise<void>;
  onRemoveSuccess: (email: string) => void;
  onRemoveError: (email: string) => void;
};

export const Email: FC<Props> = ({
  email,
  hideRemoveButton,
  onRemove,
  onRemoveSuccess,
  onRemoveError
}) => {
  const { t } = useTranslation();
  const [requestStatus, setRequestStatus] = React.useState<ReqType>(REQ.INIT);

  const onClickRemove = async () => {
    try {
      setRequestStatus(REQ.PENDING);
      await onRemove(email);
      setRequestStatus(REQ.SUCCESS);
      setTimeout(() => {
        onRemoveSuccess(email);
      }, 3000);
    } catch (e) {
      onRemoveError(email);
      setRequestStatus(REQ.ERROR);
    }
  };

  return (
    <Container flex gap="xs" alignItems="center" mb="xs" minHeight="32px">
      <EmailContainer removing={requestStatus === REQ.SUCCESS}>
        <Typography variant="paragraph" as="li">
          {email}
        </Typography>
      </EmailContainer>
      {!hideRemoveButton && requestStatus === REQ.INIT && (
        <Button
          variant="borderless"
          color="error"
          onClick={onClickRemove}
          icon={<Icon icon="clear" fill="deepPurple" />}
          text={t('common:Remove')}
        />
      )}
      {requestStatus === REQ.PENDING && (
        <Typography variant="paragraphSmall" color="secondary">
          {t('common:Removing')}
        </Typography>
      )}
      {requestStatus === REQ.SUCCESS && (
        <Container flex gap="xxs" alignItems="center">
          <Icon icon="success" fill="success" />
          <Typography variant="paragraphSmall" color="secondary">
            {t('common:Removed')}
          </Typography>
        </Container>
      )}
      {requestStatus === REQ.ERROR && (
        <Container flex gap="xxs" alignItems="center">
          <Icon icon="alert" fill="error" />
          <Typography variant="paragraphSmall" color="secondary">
            {t('common:FailedToRemove')}
          </Typography>
        </Container>
      )}
    </Container>
  );
};
