import { useTranslation } from '@dagens/frontend-i18n';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { useState } from 'react';
import FormField from '_common/components/input/FormField';
import FormValidationMessage from '_common/components/input/FormValidationMessage';
import NewRadioButtons from '_common/components/radio-buttons/NewRadioButtons';
import SectionHOC from '_common/components/section-hoc/SectionHOC';
import { ActionButton } from '@components/action-button';
import { postProducerShopUrl, postProducerUpdate } from 'api';
import REQ, { ReqType } from 'utils/REQ';
import { booleanToYesNoUndecided } from 'utils/texts';
import { validateShopUrl } from 'utils/validation';

type ProfileMyShopProps = {
  onChange: (val: {
    shopUrl: string;
    hideShop: boolean;
    handle: string | undefined;
  }) => void;
  userId?: string;
  dagensId?: string;
  handle?: string;
  shopUrl?: string;
  hideShop?: boolean;
};

const ProfileMyShop = ({
  onChange,
  userId,
  dagensId,
  handle,
  shopUrl = '',
  hideShop = false
}: ProfileMyShopProps) => {
  const { t } = useTranslation();
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [editableShopUrl, setEditableShopUrl] = useState(shopUrl);
  const [editableHideShop, setEditableHideShop] = useState(hideShop);
  const [req, setReq] = useState<ReqType>(REQ.INIT);

  const onSubmit = async () => {
    setReq(REQ.PENDING);

    try {
      await postProducerUpdate({
        userId,
        shopUrl: editableShopUrl === '' ? null : undefined,
        hideShop: editableHideShop
      });

      if (editableShopUrl) {
        await postProducerShopUrl({ userId, shopUrl: editableShopUrl });
      }
      setReq(REQ.SUCCESS);
      onChange({
        shopUrl: editableShopUrl,
        hideShop: editableHideShop,
        handle: editableShopUrl || dagensId
      });
    } catch (e) {
      setReq(REQ.ERROR);
    }
  };

  const activeLink = `${window.location.host}/${handle}`;

  return (
    <Container width="halfTabletUp">
      <Typography variant="paragraph" mb="m">
        Set your Dagens-webshop link for easy sharing. Use it to promote your
        produce, and encourage customer to order — both existing and potential.
      </Typography>
      <FormField
        margin={{ mb: 'xs' }}
        name="shopUrl"
        type="text"
        labelText={t('producer:profileShopUrlLabelText')}
        error={!validateShopUrl(editableShopUrl)}
        errorFeedback={t('producer:ShopUrlCharacterValidationErrorMessage')}
        placeholder={t('producer:profileShopUrlPlaceholder')}
        helpText={t('producer:profileShopUrlHelpText')}
        onChange={({ target: { value } }) => {
          setEditableShopUrl(value);
          setFieldsChanged(true);
          setReq(REQ.INIT);
        }}
        value={editableShopUrl}
      />
      <Typography variant="paragraphSmall" mb="l" color="secondary">
        {fieldsChanged &&
          validateShopUrl(editableShopUrl) &&
          t('producer:profileShopUrlYourLink', { url: editableShopUrl })}
      </Typography>
      {req === REQ.ERROR && (
        <Container
          flex
          justify="space-between"
          alignItems="baseline"
          direction="row"
        >
          <FormValidationMessage
            message={t('producer:ShopUrlDuplicateValidationErrorMessage')}
          />
        </Container>
      )}
      <Container mb="m">
        <NewRadioButtons
          labelText={t('producer:ShopVisibility')}
          radioGroupName="hideShop"
          onChange={option => {
            setEditableHideShop(option === t('common:yes'));
            setFieldsChanged(true);
            setReq(REQ.INIT);
          }}
          currentValue={booleanToYesNoUndecided(editableHideShop)}
          options={[
            {
              key: t('common:no'),
              value: t('producer:Public'),
              explanationText: t('producer:PublicExplanationText')
            },
            {
              key: t('common:yes'),
              value: t('producer:DagensUsersOnly'),
              explanationText: t('producer:DagensUsersOnlyExplanationText')
            }
          ]}
        />
      </Container>
      {fieldsChanged && (
        <Container flex justify="space-between" alignItems="baseline">
          <Container py="s">
            <ActionButton.Save
              size="regular"
              onClick={onSubmit}
              disabled={!validateShopUrl(editableShopUrl)}
              saveReq={req}
            >
              {t('common:SaveChanges')}
            </ActionButton.Save>
          </Container>
        </Container>
      )}
      <Callout>
        <Typography variant="paragraph" as="span" mr="xxs">
          {t('producer:ViewAndShareYourShop')}
        </Typography>
        <Typography variant="routerLink" color="deepPurple" to={`/${handle}`}>
          {activeLink}
        </Typography>
      </Callout>
    </Container>
  );
};

export default SectionHOC(ProfileMyShop);
