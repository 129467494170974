import { useTranslation } from '@dagens/frontend-i18n';
import { Theme } from '@dagensmat/carrot';
import { useMemo } from 'react';

import { ALL_CUSTOMERS } from '../../../config';
import { Consumer } from '../../../types/Consumer';

type Args = {
  consumers: Consumer[];
  disabledValues: string[];
  value: readonly string[];
};

export const useConsumersToOptions = ({
  consumers,
  disabledValues,
  value
}: Args) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return [
      {
        label: t('producer:AllCustomers'),
        value: ALL_CUSTOMERS,
        borderBottom: `2px solid ${Theme.Colours.lightGrey}`,
        isDisabled: disabledValues.includes(ALL_CUSTOMERS)
      },
      ...consumers.map(consumer => {
        return {
          value: consumer._id,
          label: consumer.name,
          isDisabled:
            value.includes(consumer._id) ||
            disabledValues.includes(consumer._id)
        };
      })
    ];
  }, [consumers, disabledValues]);

  return options;
};
