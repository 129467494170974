import { Container } from '@dagensmat/carrot/Layout';
import { FC } from 'react';

import { FormFieldCharCount } from './character-count';
import { FormFieldHelpText } from './help-text';

type Props = {
  helpText?: string | null;
  maxLength?: number;
  valueLength?: number;
};

export const HelpTextContainer: FC<Props> = ({
  helpText,
  maxLength,
  valueLength
}) => {
  if (!helpText && maxLength === undefined) {
    return null;
  }

  return (
    <Container flex justify="space-between">
      <FormFieldHelpText>{helpText}</FormFieldHelpText>
      {maxLength !== undefined && (
        <FormFieldCharCount
          maxLength={maxLength}
          valueLength={valueLength ?? 0}
        />
      )}
    </Container>
  );
};
