import { ButtonGroup, Button } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import {
  Container as CarrotContainer,
  Container
} from '@dagensmat/carrot/Layout';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Illustrations } from '../../../utils/Illustrations';
import PageHeader from '@components/page-header/PageHeader';
import { Page } from '@components/page';
import { CommonNavbar, CommonNavTabs } from '@components/navbar';
import { useMediaQuery } from '_common/hooks/useMediaQueries';

type LocationState =
  | {
      details: string;
    }
  | undefined;

const Illustration = styled.img`
  width: auto;
  height: auto;
  max-height: 318px;
  max-width: 240px;
  object-fit: contain;
`;

const DeliveryRouteRequestPageConfirmation = () => {
  const mediaQuery = useMediaQuery();
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as LocationState;
  const { details } = state ?? {};

  if (!details) {
    return <Navigate to="/profile/delivery-route-request/start" />;
  }

  return (
    <Page header={<CommonNavbar showLogo />} bottom={<CommonNavTabs />}>
      <PageHeader
        headerText={t('producer:DeliveryRouteRequest.ConfirmationPageHeader')}
      />
      <CarrotContainer
        flex
        gap="m"
        justify="space-between"
        wrap="nowrap"
        direction={mediaQuery.mobileOnly ? 'column-reverse' : 'row'}
        mt="xl"
      >
        <CarrotContainer>
          <Typography variant="paragraph" mb="m" color="secondary">
            {details}
          </Typography>
          <Typography variant="paragraph">
            {t('producer:DeliveryRouteRequest.SuccessText')}
          </Typography>
        </CarrotContainer>
        <CarrotContainer px="m">
          <Illustration
            src={Illustrations.BIKE_DELIVERY}
            alt={t('common:Illustration')}
          />
        </CarrotContainer>
      </CarrotContainer>

      <Container py="s">
        <ButtonGroup.Wrap>
          <Button as="link" to="/profile" variant="primary" size="regular">
            {t('producer:DeliveryRouteRequest.SeeActiveDeliveryRoutes')}
          </Button>
          <Button
            as="link"
            to="/profile/delivery-route-request/start"
            size="small"
            variant="borderless"
          >
            {t('producer:DeliveryRouteRequest.RequestAnotherDeliveryRoute')}
          </Button>
        </ButtonGroup.Wrap>
      </Container>
    </Page>
  );
};

export default DeliveryRouteRequestPageConfirmation;
