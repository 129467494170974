import { tv } from 'tailwind-variants';
import { PropsWithChildren } from 'react';
import { FormFieldErrorMessage } from './parts/error-message';
import { FormFieldFeedback } from './parts/feedback';
import { HelpTextContainer } from './parts/help-text-container';
import { LabelContainer } from './parts/label-container';

const style = tv({
  base: `
    flex
    flex-col
    gap-xxs
  `
});

type Props = PropsWithChildren<{
  errorMessage?: string;
  explanation?: string;
  feedback?: string;
  helpText?: string;
  label: string;
  maxLength?: number;
  optional?: string;
  valueLength?: number;
}>;

export const FormField = ({
  errorMessage,
  explanation,
  feedback,
  helpText,
  label,
  maxLength,
  optional,
  valueLength,
  children
}: Props) => {
  return (
    <div className={style()}>
      <LabelContainer
        label={label}
        optional={optional}
        explanation={explanation}
      />
      <HelpTextContainer
        helpText={helpText}
        maxLength={maxLength}
        valueLength={valueLength}
      />

      {children}

      {feedback && <FormFieldFeedback>{feedback}</FormFieldFeedback>}
      {errorMessage && (
        <FormFieldErrorMessage>{errorMessage}</FormFieldErrorMessage>
      )}
    </div>
  );
};
