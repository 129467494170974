import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import Theme from '@dagensmat/carrot/Theme';
import React from 'react';
import styled from 'styled-components';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../_common/hooks/reduxHooks';
import { NumberInput } from '../../../../carrot/input-number';
import MinimumOrderPageCallout from './MinimumOrderPageCallout';
import ExpandableCallout from '_common/components/callout/ExpandableCallout';
import { InputContainer } from '_common/components/input/Input.style';
import PageHeader from '@components/page-header/PageHeader';
import usePageView from '_common/hooks/usePageView';
import { minimumOrderAmountUpdated } from '_common/reducers/auth';
import { ActionButton } from '@components/action-button';
import { BottomActions } from '@components/bottom-actions';
import { Page } from '@components/page';
import { postProducerMinimumOrder } from 'api';
import { MINIMUM_ORDER_FORM_PAGE_VIEW } from 'utils/mixpanel';
import REQ, { ReqType } from 'utils/REQ';

const FormSection = styled.div`
  margin-bottom: ${Theme.Spacings.jumbo}px;
`;

const FormWrapper = styled.div`
  max-width: 490px;
  width: 100%;
  position: relative;
`;

const MinimumOrderPage = () => {
  const dispatch = useAppDispatch();
  usePageView(MINIMUM_ORDER_FORM_PAGE_VIEW);
  const { producerId, minimumOrderAmount } = useAppSelector(
    ({ auth: { _id: producerId, minimumOrderAmount } }) => {
      return {
        producerId,
        minimumOrderAmount
      };
    }
  );

  const { t } = useTranslation();
  const [validMinimumAmount, setValidMinimumAmount] = React.useState(false);
  const [req, setReq] = React.useState<ReqType>(REQ.INIT);

  const fieldChanged = (value: number | string) => {
    setReq(REQ.INIT);
    dispatch(minimumOrderAmountUpdated({ minimumOrderAmount: Number(value) }));

    if (Number.isSafeInteger(value)) {
      setValidMinimumAmount(true);
    } else {
      setValidMinimumAmount(false);
    }
  };

  const save = async () => {
    setReq(REQ.PENDING);

    try {
      await postProducerMinimumOrder({
        userId: producerId,
        producerId,
        minimumOrderAmount
      });
      setReq(REQ.SUCCESS);
    } catch (e) {
      setReq(REQ.ERROR);
    }
  };

  return (
    <Page
      bottom={
        validMinimumAmount && (
          <BottomActions>
            <ActionButton.Save
              onClick={save}
              disabled={!validMinimumAmount}
              redirectTo={-1}
              saveReq={req}
            >
              {t('producer:MinimumOrderSaveButtonText')}
            </ActionButton.Save>
          </BottomActions>
        )
      }
    >
      <PageHeader headerText={t('producer:MinimumOrderPageHeader')} />
      <FormWrapper>
        <FormSection>
          <Typography variant="paragraph" mt="s" mb="l">
            {t('producer:MinimumOrderPageInfoMessage')}
          </Typography>
          <ExpandableCallout expandedContent={<MinimumOrderPageCallout />}>
            <Typography variant="paragraphBold">
              {t('producer:MinimumOrderPageCalloutHeader')}
            </Typography>
          </ExpandableCallout>
          <InputContainer margin={Theme.Spacings.s}>
            <Typography variant="inputLabel" mb="xxs">
              {t('producer:MinimumOrderAmountLabelText')}
            </Typography>
            <Typography variant="paragraphSmall" color="secondary" mb="xxs">
              {t('producer:MinimumOrderAmountHelperText')}
            </Typography>
            <NumberInput
              unit={t('producer:MinimumOrderAmountUnit')}
              value={minimumOrderAmount}
              placeholder={t('producer:MinimumOrderAmountPlaceholder')}
              onChange={value => {
                fieldChanged(value);
              }}
              width={180}
            />
          </InputContainer>
        </FormSection>
      </FormWrapper>
    </Page>
  );
};

export default MinimumOrderPage;
