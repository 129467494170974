import { ButtonHTMLAttributes, forwardRef, Ref } from 'react';

import { Icon, IconType } from '../../theme/icon';
import { Button } from '../button';

type Props = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'children' | 'className'
> & {
  icon: IconType;
};

const Component = ({ icon, ...props }: Props, ref: Ref<HTMLButtonElement>) => {
  return (
    <Button.NavIcon buttonRef={ref} {...props}>
      <Icon icon={icon} size="medium" />
    </Button.NavIcon>
  );
};

export const NavIconButton = forwardRef(Component);
