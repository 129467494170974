import { Typography } from '@dagensmat/carrot/Components';
import Container from '@dagensmat/carrot/Layout/Container';
import { FC, PropsWithChildren } from 'react';

import Icon from '../../_common/components/utils/Icon';

export const FormFieldErrorMessage: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container flex gap="xs">
      <Icon icon="alert" $size="m" />
      <Typography variant="paragraph" as="span">
        {children}
      </Typography>
    </Container>
  );
};
