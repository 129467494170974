import { ReactNode } from 'react';
import { To, useLocation } from 'react-router-dom';

import { ThemeAnimation } from '../../theme/animations';
import { Icon, IconType } from '../../theme/icon';
import { Button } from '../button';

const useIsActive = (activeOn: string[] = []) => {
  const location = useLocation();
  return activeOn.some(path => location.pathname === path);
};

export type NavLinkProps = {
  badge?: ReactNode;
  icon: IconType;
  iconAnimation?: ThemeAnimation;
  label: string;
  mobile?: boolean;
  to: To;
  activeOn?: string[];
};

export const NavLink = ({
  badge,
  icon,
  iconAnimation,
  label,
  mobile,
  to,
  activeOn
}: NavLinkProps) => {
  const isOtherRoutesActive = useIsActive(activeOn);
  return (
    <Button.NavLink
      mobile={mobile}
      active={isOtherRoutesActive}
      to={to}
      badge={badge}
    >
      <Icon icon={icon} animation={iconAnimation} size="medium" />
      {label}
    </Button.NavLink>
  );
};
