import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import { roundNumber } from '@dagensmat/core';
import { add, dinero, toSnapshot } from 'dinero.js';
import { DeliveryFeesResponse } from '../../../types/Basket';
import {
  FooterContainer,
  SystemFeedbackContainer
} from './CheckoutFooter.style';
import CheckoutFooterDateLine from './CheckoutFooterDateLine';
import { addMany, toMinorUnit, zeroDinero } from 'utils/money';
import REQ, { ReqType } from 'utils/REQ';
import { fmtMoney } from 'utils/texts';

import { ActionButton } from '@components/action-button';
import {
  ProductWithBasket,
  sumPriceForProductsInBasket
} from '_consumer/reducers/productsForSale';

type CheckoutButtonProps = {
  isApprovedToOrder: boolean;
  submitReq: ReqType;
  placeOrder: () => void;
  deliveryDateCount: number;
};

const CheckoutButton = ({
  isApprovedToOrder,
  submitReq,
  placeOrder,
  deliveryDateCount
}: CheckoutButtonProps) => {
  const { t } = useTranslation();
  if (!isApprovedToOrder) {
    return (
      <SystemFeedbackContainer>
        <Typography variant="paragraphSmall" as="span" color="error">
          {t('consumer:MissingOrderRights')}
        </Typography>
      </SystemFeedbackContainer>
    );
  }

  return (
    <ActionButton.Save
      onClick={placeOrder}
      saveReq={submitReq}
      feedbackText={{
        [REQ.PENDING]: t('consumer:SendingOrder'),
        [REQ.ERROR]: t('consumer:SomethingWentWrongTryAgain')
      }}
    >
      {t('consumer:SendInYourOrder', {
        count: deliveryDateCount
      })}
    </ActionButton.Save>
  );
};

type CheckoutFooterProps = {
  isApprovedToOrder: boolean;
  submitReq: ReqType;
  placeOrder: () => void;
  basketByDeliveryDate: {
    deliveryDate: string;
    products: ProductWithBasket[];
  }[];
  deliveryFees: DeliveryFeesResponse | undefined;
  newExemptions: string[];
};

const CheckoutFooter = ({
  isApprovedToOrder,
  submitReq,
  placeOrder,
  basketByDeliveryDate,
  deliveryFees,
  newExemptions
}: CheckoutFooterProps) => {
  const { t } = useTranslation();
  const dayTotals = deliveryFees
    ? basketByDeliveryDate.map(({ deliveryDate, products }) => {
        const feeToPayToday = deliveryFees.deliveryDayFees.find(day => {
          return (
            day.date === deliveryDate &&
            day.exemption === '' &&
            !newExemptions.includes(day.date)
          );
        });

        const dagensFeeToPayForDate = feeToPayToday
          ? dinero(feeToPayToday.total)
          : zeroDinero(deliveryFees.totalDeliveryFees.currency);

        const dayBasketSum = dinero({
          amount: roundNumber(
            toMinorUnit(sumPriceForProductsInBasket(products))
          ),
          currency: toSnapshot(dagensFeeToPayForDate).currency
        });

        const dayTotal = add(dayBasketSum, dagensFeeToPayForDate);
        return { deliveryDate, dayTotal };
      })
    : [];

  const allDaysTotal = addMany(
    dayTotals.map(({ dayTotal }) => {
      return dayTotal;
    })
  );

  return (
    <FooterContainer>
      {dayTotals.map(({ deliveryDate, dayTotal }) => {
        return (
          <CheckoutFooterDateLine
            key={deliveryDate}
            deliveryDate={deliveryDate}
            dayTotal={dayTotal}
          />
        );
      })}
      <Container flex justify="space-between" alignItems="baseline" mb="xs">
        <Typography variant="paragraphSmallBold">
          {t('common:CheckoutTotalExVAT')}
        </Typography>
        <Typography variant="paragraphSmallBold">
          {fmtMoney(allDaysTotal)}
        </Typography>
      </Container>
      <CheckoutButton
        deliveryDateCount={basketByDeliveryDate.length}
        isApprovedToOrder={isApprovedToOrder}
        submitReq={submitReq}
        placeOrder={placeOrder}
      />
    </FooterContainer>
  );
};

export default CheckoutFooter;
