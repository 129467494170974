import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@dagens/frontend-i18n';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box, Button } from '@dagens/carrot';
import { getOrganization, GetOrganizationFoundResponse } from '../../../../api';
import { FormField } from '../../../../carrot/form-field';
import { FormFieldType } from '../../../../carrot/form-field/types';
import { BottomActions } from '../../../../components/bottom-actions';
import { ButtonLink } from '../../../../_common/components/button/Button.style';
import {
  FeatureFlags,
  hasUserFeatureFlag
} from '../../../../services/FeatureFlagService';
import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { BillingCompaniesList } from './parts/BillingCompaniesList';
import { CommonNavbar } from '@components/navbar';
import { Page } from '@components/page';
import PageHeader from '@components/page-header/PageHeader';

const Start = () => {
  const [orgNumber, setOrgNumber] = useState<string>('');
  const [organization, setOrganization] =
    useState<GetOrganizationFoundResponse | null>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { featureFlags } = useAppSelector(({ auth }) => {
    return { featureFlags: auth.features };
  });

  const canContinue = useMemo(() => {
    return organization && !organization.exists;
  }, [organization]);

  useEffect(() => {
    const fetchOrganization = async () => {
      if (!orgNumber) {
        setOrganization(null);
        return;
      }
      const org = await getOrganization({ orgNumber });
      if (org.status === 'ORGANIZATION_NOT_FOUND') {
        setOrganization(null);
        return;
      }
      setOrganization(org);
    };

    fetchOrganization();
  }, [orgNumber]);

  if (!hasUserFeatureFlag(FeatureFlags.ADD_CUSTOMER_FLOW, featureFlags)) {
    return <Navigate to="/customers" />;
  }
  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t('producer:AddCustomer.startPageHeader')}
              annotation={`${t('producer:AddCustomer.headerAnnotation')} (1/4)`}
            />
            <FormField
              type={FormFieldType.TEXT}
              label="EAN nummer"
              helpText={t('producer:AddCustomer:startFieldCvrDescription')}
              maxLength={9}
              valueLength={orgNumber.length}
              inputProps={{
                value: orgNumber,
                placeholder: t('producer:AddCustomer.startFieldCvrPlaceholder'),
                onChange: ({ target: { value } }) => {
                  return setOrgNumber(value);
                }
              }}
            />
          </Box.FullWidth>
        </>
      }
      bottom={
        organization === null || !canContinue ? undefined : (
          <BottomActions border>
            <Button
              size="regular"
              variant="ghost"
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('common:BackWithArrow')}
            </Button>
            <ButtonLink
              to="/customers/add/review-billing-information"
              variant="primary"
              state={{
                organization
              }}
            >
              {t('common:NextWithArrow')}
            </ButtonLink>
          </BottomActions>
        )
      }
    >
      {orgNumber && (
        <BillingCompaniesList
          foundOrganization={organization}
          matchingCustomers={[]}
        />
      )}
    </Page>
  );
};

export default Start;
