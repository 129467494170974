import { useTranslation } from '@dagens/frontend-i18n';
import { Callout, Typography } from '@dagensmat/carrot/Components';

import { useAppSelector } from '../../../_common/hooks/reduxHooks';
import { DeliveryType } from '../../../types/Logistics';
import DeliveryOption from '../logistics-request/DeliveryOption';
import ProducerDeliveryRoutes from './ProducerDeliveryRoutes';
import SectionHOC from '_common/components/section-hoc/SectionHOC';
import ExpandableCallout from '_common/components/callout/ExpandableCallout';
import { ButtonLink } from '_common/components/button/Button.style';

const ProfileLogisticsSection = () => {
  const { t } = useTranslation();

  const { logisticsRoutes } = useAppSelector(({ auth }) => {
    return {
      logisticsRoutes: auth.logisticsRoutes || []
    };
  });

  return (
    <>
      <Typography variant="paragraph" mb="s">
        {t('producer:DeliveryRouteDescription')}
      </Typography>
      <ExpandableCallout
        mb="s"
        expandedContent={
          <DeliveryOption
            deliveryTypesToShow={[
              DeliveryType.DAGENS_DELIVERY,
              DeliveryType.SELF_DELIVERY
            ]}
          />
        }
      >
        <Typography variant="paragraphBold">
          {t('producer:profileLogisticsExpandableCalloutTitle')}
        </Typography>
      </ExpandableCallout>
      {logisticsRoutes.length < 1 ? (
        <Callout colour="warning" mb="s">
          <Typography variant="paragraphBold" mb="s">
            {t('producer:NoDeliveryRouteSetup')}
          </Typography>
          <Typography variant="paragraph">
            {t('producer:RequestDeliveryRouteText')}
          </Typography>
        </Callout>
      ) : (
        <ProducerDeliveryRoutes />
      )}
      <ButtonLink
        to={{
          pathname: '/profile/delivery-route-request/start'
        }}
        state={{ returnPath: true }}
        variant="primary"
      >
        {t('producer:DeliveryRouteRequestPage.ButtonText')}
      </ButtonLink>
    </>
  );
};

export default SectionHOC(ProfileLogisticsSection);
