import Theme from '@dagensmat/carrot/Theme';
import styled from 'styled-components';
import { MediaQuery } from 'utils/mediaQueries';
import { MarginProps } from 'utils/style';

const ScrollContainer = styled.div<Pick<MarginProps, 'ml' | 'mr'>>`
  padding-bottom: ${Theme.Spacings.xs + Theme.Spacings.xxs}px;
  overflow-y: hidden;
  overflow-x: scroll;

  margin-left: ${({ ml = -20 }) => {
    return `${ml}px`;
  }};

  ${MediaQuery.mobileOnly} {
    margin-right: ${({ mr = -20 }) => {
      return `${mr}px`;
    }};
  }

  ${MediaQuery.desktopUp} {
    margin: 0;
  }

  ${MediaQuery.desktopUp} {
    mask-image: linear-gradient(
      to right,
      black calc(100% - 48px),
      transparent 100%
    );
  }
`;

const ScrollContentContainer = styled.div<Pick<MarginProps, 'ml' | 'mr'>>`
  display: flex;
  width: fit-content;
  white-space: initial;

  > *:first-child {
    margin-left: ${({ ml = 20 }) => {
      return `${ml}px`;
    }};
  }

  > *:last-child {
    margin-right: ${({ mr = 20 }) => {
      return `${mr}px`;
    }};
  }

  ${MediaQuery.desktopUp} {
    > *:first-child {
      margin-left: 0;
    }

    > *:last-child {
      margin-right: 0;
    }
  }
`;

type HorisontalScrollContainerProps = {
  children: React.ReactChild;
} & MarginProps;

const HorisontalScrollContainer = ({
  children,
  ...props
}: HorisontalScrollContainerProps) => {
  return (
    <ScrollContainer {...props}>
      <ScrollContentContainer>{children}</ScrollContentContainer>
    </ScrollContainer>
  );
};

export default HorisontalScrollContainer;
