import { Callout } from '@dagensmat/carrot/Components';
import Theme from '@dagensmat/carrot/Theme';
import { ColourNames } from '@dagensmat/carrot/Theme/Colours';
import { MarginProps } from '@dagensmat/carrot/utils/MarginProps';
import React from 'react';
import styled from 'styled-components';
import Icon from '_common/components/utils/Icon';
import ExpandCollapseAnimationContainer from '_common/components/container/ExpandCollapseAnimationContainer';

const ExpandableCalloutClickableArea = styled.div`
  background: transparent;
  padding: ${Theme.Spacings.s}px;
  margin: -${Theme.Spacings.s}px -${Theme.Spacings.s}px 0;
  appearance: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type ExpandableCalloutProps = {
  expandedContent: React.ReactNode;
  children: React.ReactNode;
  color?: ColourNames;
} & MarginProps;

const ExpandableCallout = ({
  expandedContent,
  children,
  color = 'noteworthy',
  ...rest
}: ExpandableCalloutProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <Callout colour={color} {...rest} pb={isExpanded ? 's' : 'none'}>
      <ExpandableCalloutClickableArea
        onClick={() => {
          return setIsExpanded(!isExpanded);
        }}
      >
        {children}
        <Icon icon="chevronSmall" rotate={isExpanded ? 0 : 180} />
      </ExpandableCalloutClickableArea>
      <ExpandCollapseAnimationContainer shown={isExpanded}>
        {expandedContent}
      </ExpandCollapseAnimationContainer>
    </Callout>
  );
};

export default ExpandableCallout;
