import { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';

const style = tv({
  base: `
    transition-regular
    col-span-full
    grid
    grid-cols-subgrid
    py-s
    transition-[color,background-color,border-color,opacity]
  `,
  variants: {
    withBorder: {
      true: `
        border-y-thin
        border-y-lightGrey
      `
    },
    withHover: {
      true: 'hover:bg-brightPurple'
    }
  }
});

type Props = PropsWithChildren<{
  noBorder?: boolean;
  noHover?: boolean;
}>;

export const Row = ({ noBorder, noHover, children }: Props) => {
  return (
    <tr className={style({ withBorder: !noBorder, withHover: !noHover })}>
      {children}
    </tr>
  );
};
