import { type InputMeasureUnit, type UnitType } from '@dagensmat/core';
import { t } from 'i18next';

export const unitTypesToOptions = <Unit extends UnitType | InputMeasureUnit>(
  unitTypes: Unit[],
  includePer = true
) => {
  return unitTypes.map(unit => {
    return {
      value: unit,
      name: `${includePer ? `${t('common:per')} ` : ''} ${t(
        `units:${unit}_long`,
        {
          count: 1
        }
      )}`
    };
  });
};
