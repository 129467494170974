import { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';

const style = tv({
  base: `
    sticky
    top-0
    z-10
    col-span-full
    grid
    grid-cols-subgrid
    bg-white
  `
});

type Props = PropsWithChildren;

export const Head = ({ children }: Props) => {
  return <thead className={style()}>{children}</thead>;
};
