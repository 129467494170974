import { useTranslation } from '@dagens/frontend-i18n';
import { Container } from '@dagensmat/carrot/Layout';
import React, { FC } from 'react';
import Button from '_common/components/button/Button.style';
import FormField from '_common/components/input/FormField';
import { useAppSelector } from '_common/hooks/reduxHooks';
import REQ, { ReqType } from 'utils/REQ';

type Props = {
  onInvite: (email: string) => Promise<void>;
  onInviteInputChange: () => void;
};

export const InviteUser: FC<Props> = ({ onInvite, onInviteInputChange }) => {
  const MIN_EMAIL_LENGTH = 7;
  const [requestStatus, setRequestStatus] = React.useState<ReqType>(REQ.INIT);
  const [email, setEmail] = React.useState<string>('');
  const { t } = useTranslation();
  const { accountName } = useAppSelector(({ auth }) => {
    return {
      accountName: auth.name
    };
  });

  const onSubmit = async (value: string) => {
    try {
      setRequestStatus(REQ.PENDING);
      await onInvite(value);
      setEmail('');
      setRequestStatus(REQ.SUCCESS);
    } catch (e) {
      setRequestStatus(REQ.ERROR);
    }
  };

  return (
    <Container mt="l" mb="xs">
      <FormField
        name="invite-user-input"
        type="text"
        labelText={t('common:InviteUserLabelText')}
        placeholder={t('common:InviteUserPlaceholderText')}
        helpText={t('common:InviteUserHelpText', { accountName })}
        value={email}
        onChange={e => {
          if (requestStatus === REQ.PENDING) return;
          setRequestStatus(REQ.INIT);
          setEmail(e.target.value);
          onInviteInputChange();
        }}
        margin={{ mb: 'xs' }}
      />
      {email.length > 0 && (
        <Button
          variant="primary"
          onClick={() => {
            return onSubmit(email);
          }}
          disabled={
            email.length < MIN_EMAIL_LENGTH || requestStatus !== REQ.INIT
          }
        >
          {t('common:InviteUserSubmitText')}
        </Button>
      )}
    </Container>
  );
};
