import { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';

const style = tv({
  base: `
    flex
    flex-col
    gap-xs
  `
});

type Props = PropsWithChildren;

export const ModalActions = ({ children }: Props) => {
  return <div className={style()}>{children}</div>;
};
