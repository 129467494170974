import { Trans, useTranslation } from '@dagens/frontend-i18n';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ProducerProduct } from '../../../../types/Product';
import { byForSaleStatus } from 'utils/array';
import { B } from 'utils/fonts';
import { isMeasureUnitValid } from 'utils/pricing';

import { useAppSelector } from '_common/hooks/reduxHooks';

const StyledProductLink = styled(Typography)`
  white-space: nowrap;
`;

type ProductWithouBaseUnitLinkProps = {
  product: ProducerProduct;
};

const ProductWithouBaseUnitLink: FC<ProductWithouBaseUnitLinkProps> = ({
  product
}) => {
  const { _id, name, type, prices } = product;
  const invalidPrice = prices.find(price => {
    return !isMeasureUnitValid(price);
  });
  return (
    <StyledProductLink
      variant="routerLink"
      to={`/my-products/${_id}/edit-pricing/${invalidPrice?._key}`}
    >
      {[name, type].filter(Boolean).join(' - ')}
    </StyledProductLink>
  );
};

const ProductLinkList = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ProductsWithoutBaseUnitListProps = {
  products: ProducerProduct[];
};

const ProductsWithoutBaseUnitList: FC<ProductsWithoutBaseUnitListProps> = ({
  products
}) => {
  return (
    <ProductLinkList>
      {products.slice(0, 3).map((product, idx, arr) => {
        return (
          <React.Fragment key={product._id}>
            <ProductWithouBaseUnitLink product={product} />
            {idx < arr.length - 1 && ', '}
          </React.Fragment>
        );
      })}
    </ProductLinkList>
  );
};

const ProducerMessagesWeightVolume = () => {
  const { t } = useTranslation();
  const { productsWithoutBaseUnit } = useAppSelector(
    ({
      producerProducts: { items: producerProductItems },
      producerCategories: { categories }
    }) => {
      return {
        productsWithoutBaseUnit: producerProductItems
          ? producerProductItems
              .filter(product => {
                const productCategory = categories.find(category => {
                  return category._id === product.categoryId;
                });
                return (
                  Boolean(
                    product.prices.find(price => {
                      return !isMeasureUnitValid(price);
                    })
                  ) && !productCategory?.isNonFood
                );
              })
              .sort(byForSaleStatus)
          : []
      };
    }
  );

  if (productsWithoutBaseUnit.length === 0) return null;

  return (
    <Container flex flexBasis="48%" flexGrow={1}>
      <Callout colour="warning" width="full">
        <Typography variant="paragraphBold" as="p" mb="xs">
          {t('producer:ProductsWithoutWeightVolumeInfoNewsMessageHeading')}
        </Typography>
        <Typography variant="paragraph" mb="s">
          <Trans
            t={t}
            i18nKey="producer:ProductsWithoutWeightVolumeInfoNewsMessageContent"
            count={productsWithoutBaseUnit.length}
            values={{ count: productsWithoutBaseUnit.length }}
            components={{
              B: <B />
            }}
          />
        </Typography>
        <ProductsWithoutBaseUnitList products={productsWithoutBaseUnit} />
      </Callout>
    </Container>
  );
};

export default ProducerMessagesWeightVolume;
