import { tv } from 'tailwind-variants';

import { ButtonComponentProps } from '../components/as-button';
import { LinkComponentProps } from '../components/as-link';
import { ButtonWrapperInternal } from '../components';

const style = tv({
  base: 'rounded-none',
  variants: {
    close: {
      true: `
        rounded-b
        border-x-0
        !border-t-thin
        border-b-0
        border-solid
        border-t-lightGrey
        text-error
      `
    }
  }
});

type Props = (
  | Omit<ButtonComponentProps, 'styles' | 'className' | 'type'>
  | Omit<LinkComponentProps, 'styles' | 'className'>
) & {
  close?: boolean;
};

export const ModalAction = ({ close, ...props }: Props) => {
  return (
    <ButtonWrapperInternal
      {...props}
      variant="borderless"
      size="regular"
      className={style({ close })}
    />
  );
};
