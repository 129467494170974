import { useTranslation } from '@dagens/frontend-i18n';
import Theme from '@dagensmat/carrot/Theme';
import { forSaleStatuses } from '@dagensmat/core';
import styled from 'styled-components';
import { isSoldOut } from '_consumer/reducers/productsForSale';
import Tag from '_common/components/utils/Tag';
import { type DeliveryDate } from '_common/components/search/DeliveryDateOptions';
import { ColorValue } from 'theme';
import { type ProductForSale } from 'types/Product';
import { hasCustomOffer } from 'utils/pricing';

const TagContainer = styled.div`
  position: absolute;
  left: ${Theme.Spacings.xs}px;
  top: ${Theme.Spacings.xs}px;
`;

type ProductLabelProps = {
  product: ProductForSale;
  deliveryDate?: DeliveryDate;
  isOwnShop?: boolean;
};

type ProductLabelState = {
  textKey: string;
  backgroundColor?: ColorValue;
};

const hasCustomOfferTag = (
  product: ProductForSale,
  isOwnShop?: boolean
): boolean => {
  if (product.hasSpecialPricing) return true;
  if (isOwnShop && hasCustomOffer(product.prices ?? [])) return true;
  return false;
};

const getLabelState = (
  product: ProductForSale,
  deliveryDate: DeliveryDate | undefined,
  isOwnShop?: boolean
): ProductLabelState | null => {
  switch (true) {
    case hasCustomOfferTag(product, isOwnShop):
      return {
        textKey: 'common:CustomOffer',
        backgroundColor: Theme.Colours.noteworthy
      };
    case product.seasonStart:
      return {
        textKey: 'common:SeasonStart',
        backgroundColor: Theme.Colours.availability
      };
    case product.justAdded:
      return {
        textKey: 'common:JustAdded',
        backgroundColor: Theme.Colours.dagensPurple
      };
    case product.forSaleStatus === forSaleStatuses.ON_REQUEST:
      return {
        textKey: 'common:ON_REQUEST',
        backgroundColor: Theme.Colours.messages
      };
    case isSoldOut(product, deliveryDate):
      return {
        textKey: 'common:soldOut'
      };
    default:
      return null;
  }
};

const ProductTag = ({
  product,
  deliveryDate,
  isOwnShop
}: ProductLabelProps) => {
  const { t } = useTranslation();
  const state = getLabelState(product, deliveryDate, isOwnShop);

  if (state === null) return null;
  const { backgroundColor, textKey } = state;
  return (
    <TagContainer>
      <Tag bgColor={backgroundColor} text={t(textKey)} />
    </TagContainer>
  );
};

export default ProductTag;
