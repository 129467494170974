import { forSaleStatuses } from '@dagensmat/core';
import { find } from 'lodash';
import React from 'react';
import { ProductModalTypeProps } from '../../../types/Modal';
import { useAppSelector } from '../../hooks/reduxHooks';
import ProductView from './ProductView';
import { DeliveryDate } from '_common/components/search/DeliveryDateOptions';
import Divider from '_common/components/utils/Divider.style';
import PaddingContainer from '_common/components/utils/PaddingContainer.style';
import { DeliveryDateContext } from '_common/context/DeliveryDateContext';
import usePageView from '_common/hooks/usePageView';
import ProducerView from '_consumer/components/producer/ProducerView';
import { PRODUCT_PAGE_VIEW } from 'utils/mixpanel';
import { isGuestConsumer } from 'utils/role';

type Props = ProductModalTypeProps & {
  closeModal: () => void;
};

const ProductModal = ({
  producerProducts,
  productId,
  producer,
  closeModal,
  deliveryDateContext
}: Props) => {
  const [isolatedContextDeliveryDate, setIsolatedContextDeliveryDate] =
    React.useState<DeliveryDate>();

  const { product, roleType, isGuest, isAuthenticated, isInSameDA, userId } =
    useAppSelector(
      ({
        auth: {
          _type: roleType,
          _id: userId,
          authenticated,
          distributionAreaName
        }
      }) => {
        const product = find(producerProducts, { _id: productId });

        return {
          product,
          roleType,
          isGuest: Boolean(userId && isGuestConsumer(userId)),
          isAuthenticated: authenticated,
          isInSameDA:
            distributionAreaName !== undefined &&
            producer?.distributionAreas?.includes(distributionAreaName),
          userId
        };
      }
    );
  usePageView(PRODUCT_PAGE_VIEW, {
    productId: product?._id,
    isOrderItem: product?.forSaleStatus === forSaleStatuses.ON_REQUEST
  });

  const context = React.useMemo(() => {
    return {
      pageContext: deliveryDateContext,
      isolatedContextDeliveryDate,
      setIsolatedContextDeliveryDate
    };
  }, [isolatedContextDeliveryDate, deliveryDateContext]);

  return (
    <DeliveryDateContext.Provider value={context}>
      {product && (
        <ProductView
          product={product}
          closeModal={closeModal}
          isGuest={isGuest}
          isAuthenticated={isAuthenticated}
          isProducer={roleType === 'producers'}
          isInSameDA={isInSameDA}
          userId={userId}
        />
      )}
      {producer && (
        <>
          <Divider />
          <PaddingContainer>
            <ProducerView
              producer={producer}
              products={producerProducts}
              roleType={roleType}
              isGuest={isGuest}
              isInSameDA={Boolean(isInSameDA)}
            />
          </PaddingContainer>
        </>
      )}
    </DeliveryDateContext.Provider>
  );
};

export default ProductModal;
