import { useCallback } from 'react';

import { Option } from '../../../carrot/select-multi/types';
import { ALL_CUSTOMERS } from '../../../config';

type Args = {
  previousValue: readonly string[];
  onChange: (value: readonly string[]) => void;
};

export const useOnChange = ({ previousValue, onChange }: Args) => {
  const onChangeLocal = useCallback(
    (newValue: readonly Option[]) => {
      if (previousValue.includes(ALL_CUSTOMERS)) {
        onChange(
          newValue
            .filter(option => {
              return option.value !== ALL_CUSTOMERS;
            })
            .map(({ value }) => {
              return value;
            })
        );
        return;
      }

      const containsAllCustomers = newValue.find(option => {
        return option.value === ALL_CUSTOMERS;
      });

      if (containsAllCustomers) {
        onChange([ALL_CUSTOMERS]);
        return;
      }

      const values = newValue.map(({ value }) => {
        return value;
      });
      onChange(values);
    },
    [previousValue, onChange]
  );

  return onChangeLocal;
};
