import { Link } from 'react-router-dom';

import { tv } from 'tailwind-variants';
import { Logo } from '../../theme/logo';

const style = tv({
  base: `
    ml-xs

    [&:not(:empty)]:pt-xs
  `
});

export const NavLogoHome = () => {
  return (
    <Link className={style()} to="/">
      <Logo />
    </Link>
  );
};

export const NavLogoLandingPage = () => {
  return (
    <a className={style()} href="https://dagens.farm" rel="noopener noreferrer">
      <Logo />
    </a>
  );
};
