import { FC } from 'react';

import { useAppSelector } from '../../../../_common/hooks/reduxHooks';
import { ContentLoader } from '../../../../components/content-loader';
import { AddOrderConsumerListLinkElement } from './AddOrderConsumerListLinkElement';
import { Consumer } from 'types/Consumer';

type Props = {
  items: Consumer[];
};

export const AddOrderConsumerList: FC<Props> = ({ items }) => {
  const { req } = useAppSelector(({ consumers: { req: consumersReq } }) => {
    return { req: consumersReq };
  });

  return (
    <ContentLoader req={req}>
      <div>
        {items.map(consumer => {
          return (
            <AddOrderConsumerListLinkElement
              key={consumer._id}
              consumer={consumer}
            />
          );
        })}
      </div>
    </ContentLoader>
  );
};
