import { AvailableLanguages, CountryCode } from '@dagensmat/core';
import { Hub, StructuredAddress } from '../types/Logistics';
import { Order, OrderKind } from '../types/Order';
import { DeliveryWindow } from '../types/Consumer';
import { del, get, post, postRaw } from 'services/HttpService';
import { AvailabilityStatusType, ForSaleStatus } from 'types/Product';

/** API Endpoints */

/** GET Requests */

export type GetOrganizationFoundResponse = {
  name: string;
  phone: string;
  email: string;
  address: string;
  orgNumber: string;
  country: string;
  exists: boolean;
  isNotVatRegistered?: boolean;
  status: 'ORGANIZATION_FOUND';
};
export type GetOrganizationNotFoundResponse = {
  exists: boolean;
  status: 'ORGANIZATION_NOT_FOUND';
};

export type GetOrganizationResponse =
  | GetOrganizationFoundResponse
  | GetOrganizationNotFoundResponse;

export type GetOrganizationParams = {
  orgNumber: string;
};

export const getOrganization = (
  params: GetOrganizationParams
): Promise<GetOrganizationResponse> => {
  return get('/utils/organization', params);
};

export type GetAddressResponse = StructuredAddress;

export type GetAddressParams = {
  address: string;
};

export const getAddress = (
  params: GetAddressParams
): Promise<GetAddressResponse> => {
  return get('/utils/address', params);
};

export const getBillingAccount = (params: any) => {
  return get('/billing', params);
};

export const getNews = (params: any) => {
  return get('/news', params);
};

export const getMessages = (params: any) => {
  return get('/message', params);
};

export const getProductRequests = (params: any) => {
  return get('/products/request', params);
};

export const getConsumers = (params: any) => {
  return get('/consumer', params);
};

export const getOrder = (id: string): Promise<{ status: 200; data: Order }> => {
  return get(`/order/${id}`);
};

export const getOrderAndAdjustments = (
  id: string,
  params: any
): Promise<{ status: 200; data: OrderKind[] }> => {
  return get(`/order/${id}/adjustments`, params);
};

export const getOrders = (params: any): Promise<Order[]> => {
  return get('/order', params);
};

export const getOrderHistory = (params: any): Promise<OrderKind[]> => {
  return get('/order/history', params);
};

export const getHasFirstOrder = (params: any) => {
  return get('/order/hasFirstOrder', params);
};

export const getLogout = (params: any) => {
  return get('/auth/logout', params);
};

export const getIdentity = (params: any) => {
  return get('/auth', params);
};

export const getProducerDeliveryDates = (params: any) => {
  return get('/logistics/deliveryDates', params);
};

export const getProducerDeliveryRoutes = (params: any) => {
  return get('/logistics/deliveryRoutes', params);
};

export const getProducerProducts = (params: any) => {
  return get('/products', params);
};

export const getProducers = (params: any) => {
  return get('/producer', params);
};

export const getCategories = () => {
  return get('/categories');
};

export const getCertifications = (params: any) => {
  return get('/certifications', params);
};

export const getStatistics = (params: any) => {
  return get('/order/history/statistics', params);
};

export const getProducerSalesStatistics = (params: any) => {
  return get('/producer/salesStatistics', params);
};

export const getProducerShopUrl = (params: any) => {
  return get('/producer/shopUrl', params);
};

export const getOnboardingHistory = (id: any) => {
  return get(`/producer/${id}/onboardingHistory`);
};

export type GetConsumerDeliveryInformationResponse = {
  deliveryAddress: string;
  deliveryInfo?: string;
  deliveryWindow: DeliveryWindow;
  earliestDeliveryWindowHour: number;
  latestDeliveryWindowHour: number;
  minDeliveryWindowDuration: number;
};

export type GetConsumerDeliveryInformationParams = {
  consumerId?: string;
};

export const getConsumerDeliveryInformation = (
  params: GetConsumerDeliveryInformationParams
): Promise<GetConsumerDeliveryInformationResponse> => {
  return get('/consumer/deliveryInformation', params);
};

export const getParcelsByOrderId = (id: any) => {
  return get(`/order/${id}/parcels`);
};

type GetHubsByCountryParams = {
  country: CountryCode;
};

export const getHubsByCountry = (
  params: GetHubsByCountryParams
): Promise<Hub[]> => {
  return get('/logistics/hubs', params);
};

export type GetBasketPrefillResponse = {
  productId: string;
  forSaleStatus: ForSaleStatus;
  nrOfUnits: number;
  deliveryDate: string;
  availabilityStatus: AvailabilityStatusType;
};
export type GetBasketPrefillParams = {
  consumerId?: string;
};

export const getBasketPrefill: (
  params: GetBasketPrefillParams
) => Promise<GetBasketPrefillResponse[]> = async (
  params: GetBasketPrefillParams
) => {
  return get(`/basket/prefill`, params) as Promise<GetBasketPrefillResponse[]>;
};

/** POST Requests */

export const postLeftProducer = (params: any) => {
  return post('/order/leftProducer', params);
};

export const postSeenNews = (params: any) => {
  return post('/news/update', params);
};

export const postLogin = (params: any) => {
  return post('/auth/login', params);
};

export const postResetPassword = (params: any) => {
  return post('/auth/resetPassword', params);
};

export const postOpenProductRequest = (params: any) => {
  return post('/products/request/open', params);
};

export const postRequestProduct = (params: any) => {
  return post('/products/request', params);
};

export const postFavorite = (params: any) => {
  return post('/favorite', params);
};

export const postMessage = (params: any) => {
  return post('/message', params);
};

export const postSeenMessages = (params: any) => {
  return post('/message/seen', params);
};

export type PostOrderResponse = {
  orderId: string;
  secret: string;
  orderNumberString: string;
}[];

export type PostOrderBody = {
  basket: {
    productId: string;
    unitsInBasket: number;
  }[];
  deliveryDate: string;
  consumerId?: string;
  createdBy?: string;
  createdAs?: string;
  messageFromProducerToConsumer: string;
  withLogistics: boolean;
  orderReference: string;
};

export const postOrder = (
  params: PostOrderBody
): Promise<PostOrderResponse> => {
  return post('/order', params);
};

export const postCheckout = (params: any) => {
  return post('/basket/checkout', params);
};

export const postProduct = (params: any) => {
  return post('/products', params);
};

export const postProductFieldsUpdate = (params: any) => {
  return post('/products/update', params);
};

export const postOrderStatuses = (params: any) => {
  return post('/order/statuses', params);
};

export type UpdateOrderLinePayload = {
  orderId: string;
  lineKey: string;
  nrOfOrderedUnitsDelivered?: number;
  nrOfPricedUnitsDelivered?: number;
  nokPerPricedUnit: number;
  batchIdentification?: string;
};

export const postOrderLine = (params: UpdateOrderLinePayload) => {
  return post('/order/orderLine', params);
};

export const postBatchIdentification = (params: any) => {
  return post('/order/orderLine/batchIdentification', params);
};

export const postUserInvite = (params: any) => {
  return post('/user/invite', params);
};

export const postUserRemove = (params: any) => {
  return post('/user/remove', params);
};

export const postAddOrderLines = (params: any) => {
  return post('/order/addOrderLines', params);
};

export const postOrderUpdate = (params: any) => {
  return post('/order/update', params);
};

export type CreateOrderAdjustmentPayload = {
  orderLines: {
    lineKey: string;
    nrOfOrderedUnitsDelivered?: number;
    nrOfPricedUnitsDelivered?: number;
    nokPerPricedUnit?: number;
  }[];
};

export const postOrderAdjustment = (
  id: string,
  payload: CreateOrderAdjustmentPayload
) => {
  return post(`/order/${id}/adjust`, payload);
};

export const createUser = (params: any) => {
  return post('/user', params);
};

export const verifyUser = (params: any) => {
  return post('/user/verify', params);
};

export const createConsumer = (params: any) => {
  return post('/consumer', params);
};

export const createProducer = (params: any) => {
  return post('/producer', params);
};

export const postDeliveryRouteRequest = (params: any) => {
  return post('/logistics/request', params);
};

export type PostProducerUpdateResponse = {
  status: 200;
};
export type PostProducerUpdateBody = {
  userId?: string;
  name?: string;
  contactPerson?: string;
  emails?: string[];
  phone?: string;
  instagram?: string;
  profileArea?: string;
  profileBio?: string;
  shopUrl?: string | null;
  hideShop?: boolean;
  temporarilyUnavailable?: {
    _type: 'period';
    from: string;
    to: string;
  };
  image?: any;
  minimumOrderAmount?: number;
  roleLang?: AvailableLanguages;
  ecologicNumber?: string;
  ecologicCodeNumber?: 'DK-ØKO-050' | 'DK-ØKO-100';
  labelFormat?: '1' | '2';
};

export const postProducerUpdate = (params: PostProducerUpdateBody) => {
  return post(
    '/producer/update',
    params
  ) as Promise<PostProducerUpdateResponse>;
};

export const postConsumerUpdate = (params: any) => {
  return post('/consumer/update', params);
};

export const postAsset = (payload: any) => {
  return postRaw('/assets', payload);
};

export const postBillingAccountUpdate = (params: any) => {
  return post('/billing', params);
};

export const postProducerShopUrl = (params: any) => {
  return post('/producer/shopUrl', params);
};

export const postProducerMinimumOrder = (params: any) => {
  return post('/producer/minimumOrder', params);
};

export const postConsumerDeliveryInformation = (params: any) => {
  return post('/consumer/deliveryInformation', params);
};

export const postParcelsCount = (params: any) => {
  return post('/parcels', params);
};

export const fetchDeliveryFees = (params: any) => {
  return post('/basket/deliveryFees', params);
};

/** DELETE Requests */

export const deleteFavorite = (payload: any) => {
  return del('/favorite', payload);
};

export const deleteProduct = (payload: any) => {
  return del('/products', payload);
};
