import { Badge, Nav, NavLinkProps } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { useAppSelector } from '../../_common/hooks/reduxHooks';
import { countTotalUnseenMessages } from '../../_common/reducers/messages';
import { countProductsInBasket } from '../../_consumer/reducers/basket';
import { NavTabs } from '@components/navbar/tabs';
import { Navbar } from '@components/navbar/navbar';
import { NavMenuLogout, NavMenuSecondaryItems } from '@components/navbar/menu';

const Menu = () => {
  const { t } = useTranslation();
  const { name, email } = useAppSelector(({ auth }) => {
    return {
      name: auth.name,
      email: auth.loginEmail,
      webshop: auth.shopUrl
    };
  });

  return (
    <Nav.Menu heading={t('common:Menu')}>
      <Nav.MenuGroup title={t('common:Account', { name })}>
        <Nav.MenuPrimaryItem text={t('consumer:MyProfile')} to="/profile" />
        <Nav.MenuPrimaryItem
          text={t('common:BillingAccountHeader')}
          to="/billing-account"
        />
      </Nav.MenuGroup>
      <Nav.MenuDivider />
      <Nav.MenuGroup title={t('common:You', { email })}>
        <Nav.MenuPrimaryItem
          text={t('common:header.switchRole')}
          to="/switch-role"
        />
      </Nav.MenuGroup>
      <Nav.MenuDivider />
      <NavMenuSecondaryItems />
      <Nav.MenuDivider />
      <NavMenuLogout />
    </Nav.Menu>
  );
};

const useLinks = () => {
  const { t } = useTranslation();
  const [animateFavoriteIcon, setAnimateFavoriteIcon] = useState(false);
  const { basketCount, messageCount, basketAboveMinAmount, favoritesCount } =
    useAppSelector(({ auth, messages: { items }, basket }) => {
      return {
        basketCount: countProductsInBasket(basket.items),
        messageCount: countTotalUnseenMessages(items, auth._id),
        basketAboveMinAmount: basket.isAboveMinimumOrderThreshold,
        favoritesCount: auth.favorites?.length ?? 0
      };
    });
  const previousFavoritesCount = useRef<number>();

  useEffect(() => {
    setAnimateFavoriteIcon(
      previousFavoritesCount.current !== undefined &&
        favoritesCount > previousFavoritesCount.current
    );
    previousFavoritesCount.current = favoritesCount;
    setTimeout(() => {
      if (previousFavoritesCount.current === favoritesCount) {
        setAnimateFavoriteIcon(false);
      }
    }, 500);
  }, [favoritesCount]);

  return [
    {
      icon: 'home',
      label: t('common:Home'),
      to: '/',
      activeOn: [
        '/profile',
        '/billing-account',
        '/delivery-information',
        'switch-role'
      ]
    },
    {
      icon: 'orders',
      label: t('common:Orders'),
      to: messageCount !== 0 ? '/orders/product-requests' : '/orders',
      badge: messageCount !== 0 && <Badge text={messageCount.toString()} />
    },
    {
      icon: animateFavoriteIcon ? 'favoriteFilled' : 'favorite',
      iconAnimation: animateFavoriteIcon ? 'heartbeat' : undefined,
      label: t('consumer:Favorites'),
      to: '/favourites'
    },
    {
      icon: 'producer',
      label: t('common:header.producers'),
      to: '/producers'
    },
    {
      icon: basketCount > 0 ? 'basket' : 'basketEmpty',
      label: t('consumer:Basket'),
      to: '/basket',
      badge: basketCount !== 0 && (
        <Badge text={basketCount.toString()} warning={!basketAboveMinAmount} />
      )
    }
  ] satisfies NavLinkProps[];
};

type Props = {
  contextActions?: ReactNode;
  contextName?: string;
  showLogo: boolean;
  showActionsOnMobile: boolean;
};

export const ConsumerNavbar = ({
  contextActions,
  contextName,
  showLogo,
  showActionsOnMobile
}: Props) => {
  const links = useLinks();
  return (
    <Navbar
      showLogo={showLogo}
      showActionsOnMobile={showActionsOnMobile}
      links={links}
      actions={<Menu />}
      contextActions={contextActions}
      contextName={contextName}
    />
  );
};

export const ConsumerNavTabs = () => {
  const links = useLinks();
  return <NavTabs links={links} />;
};
