import { Form } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import {
  CombinedUnit,
  MeasureUnit,
  PackedUnit,
  ScaledMeasureUnit,
  type UnitType
} from '@dagensmat/core';
import { useCallback } from 'react';

import ExpandableCallout from '../../../_common/components/callout/ExpandableCallout';
import { FormField } from '../../../carrot/form-field';
import { ConsumerSelect } from '../../../components/consumer-select';
import { ALL_CUSTOMERS } from '../../../config';
import {
  isMeasureUnit,
  isPackedUnit,
  MEASURE_UNIT_VALUES,
  PACKED_UNIT_VALUES
} from '../../../utils/units';
import InputMeasureUnitInput from './InputMeasureUnitInput';
import OrderedUnitInput from './OrderedUnitInput';
import PricedUnitInput from './PricedUnitInput';
import TipForPricingProductsCallout from './TipForPricingProductsCallout';
import { isRegularPrice } from 'utils/pricing';
import { isOnlyProducerDeliveryRoutes } from 'utils/delivery';
import { EditablePricing, Pricing } from 'types/Product';
import { FormFieldType } from 'carrot/form-field/types';
import { useAppSelector } from '_common/hooks/reduxHooks';

const getConsumersWithPricing = (
  currentPrice: Pricing | EditablePricing,
  prices: (Pricing | EditablePricing)[]
) => {
  const otherPrices = prices.filter(price => {
    return price._key !== currentPrice._key;
  });

  const consumerIds = otherPrices
    .map(({ specialConsumers }) => {
      return specialConsumers?.map(({ _ref }) => {
        return _ref;
      });
    })
    .filter((ids): ids is string[] => {
      return ids !== undefined;
    })
    .flat();

  return Array.from(new Set(consumerIds));
};

const getHasPricingSetForAllCustomers = (
  currentPrice: Pricing | EditablePricing,
  prices: (Pricing | EditablePricing)[]
) => {
  const otherPrices = prices.filter(price => {
    return price._key !== currentPrice._key;
  });

  const hasPricingSetForAllCustomers = Boolean(
    otherPrices.find(pricing => {
      return isRegularPrice(pricing);
    })
  );
  return hasPricingSetForAllCustomers;
};

const inputMeasureUnitOptions = [
  MeasureUnit.kg,
  ScaledMeasureUnit.g,
  MeasureUnit.liter,
  ScaledMeasureUnit.ml
];

type EditPricingFlowProps = {
  editablePricing: EditablePricing;
  onPricingChange: (pricing: EditablePricing) => void;
  prices?: any;
  showError?: boolean;
  allowZeroWeight?: boolean;
};

const EditPricingFlow = ({
  editablePricing,
  onPricingChange,
  prices,
  showError,
  allowZeroWeight
}: EditPricingFlowProps) => {
  const { t } = useTranslation();
  const {
    pricedUnit,
    nokPerPricedUnit,
    orderedUnit,
    pricedUnitsPerOrderedUnit,
    unitSizeDescription,
    isSimplePricing,
    inputMeasureUnit,
    inputMeasureUnitValue,
    specialConsumers = []
  } = editablePricing;

  const [deliveryRoutes, producerId] = useAppSelector(
    ({ auth: { logisticsRoutes = [], _id } }) => {
      return [logisticsRoutes, _id];
    }
  );

  // @ts-expect-error: TODO(strict)
  const handlePricingChange = partialPricing => {
    onPricingChange({ ...editablePricing, ...partialPricing });
  };

  const pricedUnitOptions = Array.from(
    new Set([
      ...MEASURE_UNIT_VALUES,
      CombinedUnit.pcs,
      ...PACKED_UNIT_VALUES,
      pricedUnit
    ])
  ).filter(Boolean);

  const orderedUnitOptions = Array.from(
    new Set([
      PackedUnit.crate,
      PackedUnit.package,
      PackedUnit.tray,
      CombinedUnit.pcs,
      orderedUnit
    ])
  ).filter(unit => {
    return !!unit && unit !== pricedUnit;
  });

  const pricingConsumerIds = specialConsumers.map(specialConsumer => {
    return specialConsumer._ref;
  });

  const disabledConsumers = [
    ...getConsumersWithPricing(editablePricing, prices),
    ...(getHasPricingSetForAllCustomers(editablePricing, prices)
      ? [ALL_CUSTOMERS]
      : [])
  ];

  const onConsumerChange = useCallback(
    (consumerIds: readonly string[]) => {
      const partial = {
        specialConsumers: consumerIds.map(id => {
          return {
            _ref: id,
            _key: id,
            _type: 'reference'
          };
        })
      };
      handlePricingChange(partial);
    },
    [handlePricingChange]
  );

  return (
    <div>
      <ExpandableCallout
        expandedContent={<TipForPricingProductsCallout />}
        mb="xl"
      >
        <Typography variant="paragraphBold">
          {t('producer:tipsForPricingProductsTitle')}
        </Typography>
      </ExpandableCallout>
      <Form>
        <ConsumerSelect
          disabledValues={disabledConsumers}
          showError={showError}
          value={pricingConsumerIds}
          onChange={onConsumerChange}
        />
        <PricedUnitInput
          pricedUnit={pricedUnit}
          nokPerPricedUnit={nokPerPricedUnit}
          pricedUnitOptions={pricedUnitOptions}
          hasDagensLogistics={
            !isOnlyProducerDeliveryRoutes(deliveryRoutes, producerId)
          }
          onPricedUnitChange={(value: UnitType) => {
            handlePricingChange({
              pricedUnit: value,
              orderedUnit: isPackedUnit(value) ? value : PackedUnit.crate,
              isSimplePricing: Boolean(isPackedUnit(value)),
              pricedUnitsPerOrderedUnit: isPackedUnit(value) ? 1 : '',
              unitSizeDescription: '',
              inputMeasureUnit: isMeasureUnit(value) ? value : MeasureUnit.kg,
              inputMeasureUnitValue: isMeasureUnit(value) ? 1 : ''
            });
          }}
          onNokPerPricedUnitChange={value => {
            handlePricingChange({
              nokPerPricedUnit: value
            });
          }}
          showError={showError}
        />
        <InputMeasureUnitInput
          inputMeasureUnit={inputMeasureUnit}
          inputMeasureUnitValue={inputMeasureUnitValue}
          inputMeasureUnitOptions={inputMeasureUnitOptions}
          pricedUnit={pricedUnit}
          allowZeroWeight={allowZeroWeight}
          onInputMeasureUnitChange={option => {
            handlePricingChange({
              inputMeasureUnit: option
            });
          }}
          onInputMeasureUnitValueChange={value => {
            handlePricingChange({
              inputMeasureUnitValue: value,
              inputMeasureUnit
            });
          }}
          showError={showError}
        />
        <OrderedUnitInput
          orderedUnit={orderedUnit}
          pricedUnitsPerOrderedUnit={pricedUnitsPerOrderedUnit}
          orderedUnitOptions={orderedUnitOptions}
          pricedUnit={pricedUnit}
          isSimplePricing={isSimplePricing}
          onOrderedUnitChange={value => {
            handlePricingChange({
              orderedUnit: value
            });
          }}
          onPricedUnitsPerOrderedUnitChange={value => {
            handlePricingChange({
              pricedUnitsPerOrderedUnit: value
            });
          }}
          onIsSimplePricingChange={isSimple => {
            if (isSimple) {
              handlePricingChange({
                isSimplePricing: true,
                orderedUnit: pricedUnit,
                pricedUnitsPerOrderedUnit: 1
              });
            } else {
              handlePricingChange({
                isSimplePricing: false,
                orderedUnit: PackedUnit.crate,
                pricedUnitsPerOrderedUnit: ''
              });
            }
          }}
          showError={showError}
        />
        <FormField
          type={FormFieldType.TEXT}
          inputProps={{
            value: unitSizeDescription,
            placeholder: t('producer:unitSizeDescriptionPlaceholder'),
            onChange: ({ target: { value } }) => {
              return handlePricingChange({
                unitSizeDescription: value.slice(0, 50)
              });
            }
          }}
          label={t('producer:productExtraInfo')}
          errorMessage={t('common:requiredField')}
          optional
          showError={showError}
        />
      </Form>
    </div>
  );
};

export default EditPricingFlow;
