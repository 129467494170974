import { DateRange } from 'react-day-picker';
import { parseDate, slugifyDate } from './format';
import { Period } from 'types/Product';

export const slugifyPeriod = (
  period: Partial<Period> | undefined
): Period | {} => {
  if (!period || Object.values(period).filter(Boolean).length === 0) return {};

  return {
    from: slugifyDate(period.from),
    to: slugifyDate(period.to)
  };
};

export const periodToRangeModifier = (period?: Partial<Period>): DateRange => {
  return {
    from: period?.from ? parseDate(period.from) : undefined,
    to: period?.to ? parseDate(period.to) : undefined
  };
};

export const rangeModifierToPeriod = (range: DateRange): Partial<Period> => {
  return {
    from: range.from ? slugifyDate(range.from) : undefined,
    to: range.to ? slugifyDate(range.to) : undefined
  };
};
