import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import Theme from '@dagensmat/carrot/Theme';
import styled from 'styled-components';
import Tag from '_common/components/utils/Tag';

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${Theme.Spacings.s}px ${Theme.Spacings.xs}px;
  border-bottom: 1px solid ${Theme.Colours.lightGrey};
  cursor: pointer;

  :hover {
    background-color: ${Theme.Colours.brightPurple};
  }
`;

const Symbol = styled.i`
  display: contents;
  transform: translate3d(0, -50%, 0);
  font-size: ${Theme.FontSizes.leadText}px;
`;

type RoleListElementProps = {
  header: string;
  subTitle: string;
  symbol?: string;
  onClick: () => void;
  active: boolean;
  guest: boolean;
};

const RoleListElement = ({
  header,
  subTitle,
  onClick,
  symbol = '→',
  active = false,
  guest = false
}: RoleListElementProps) => {
  const { t } = useTranslation();

  return (
    <StyledContainer onClick={onClick} role="button">
      <div>
        <Container
          flex
          justify="flex-start"
          wrap="wrap-reverse"
          alignItems="center"
        >
          <Typography variant="paragraphBold" as="span" mr="xxs">
            {header}
          </Typography>
          {active && <Tag text={t('common:Active')} />}
          {!active && guest && <Tag text={t('common:ViewStore')} />}
        </Container>
        <Typography variant="paragraphSmall" color="secondary">
          {subTitle}
        </Typography>
      </div>
      <Symbol>{symbol}</Symbol>
    </StyledContainer>
  );
};

export default RoleListElement;
