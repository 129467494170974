import { useTranslation } from '@dagens/frontend-i18n';
import { Button, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import Theme from '@dagensmat/carrot/Theme';
import styled from 'styled-components';
import { Illustrations } from '../../../utils/Illustrations';
import { MediaQuery } from 'utils/mediaQueries';
import {
  INTRO_SCREEN_TWO_CLICK_CTA,
  INTRO_SCREEN_TWO_PAGE_VIEW,
  track
} from 'utils/mixpanel';

import usePageView from '_common/hooks/usePageView';
import ResponsiveImage from '_common/components/utils/ResponsiveImage.style';

const PageTwoBodyWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${MediaQuery.tabletUp} {
    flex-direction: row;

    > div,
    ${ResponsiveImage} {
      width: 50%;
    }
  }
`;

type PageTwoProps = {
  next: () => void;
};

const PageTwo = ({ next }: PageTwoProps) => {
  usePageView(INTRO_SCREEN_TWO_PAGE_VIEW);
  const { t } = useTranslation();
  return (
    <Container
      flex
      justify="space-between"
      alignItems="baseline"
      flexGrow={1}
      direction="column"
      minHeight="calc(100vh-56px)"
    >
      <Typography variant="primaryHeading" mb="s" mt="s">
        {t('consumer:GetYourselfReadyForTrading')}
      </Typography>
      <PageTwoBodyWrapper>
        <div>
          {[
            'OrderInAdvance',
            'TalkDirectlyToProducers',
            'FavoriteProductReminder'
          ].map(translationKey => {
            return (
              <div key={translationKey} style={{ display: 'flex' }}>
                <Typography variant="leadText" mr="xs">
                  →
                </Typography>
                <Typography variant="leadText" mb="s">
                  {t(`consumer:${translationKey}`)}
                </Typography>
              </div>
            );
          })}
        </div>
        <ResponsiveImage
          src={Illustrations.CUSTOMER_CARRYING_MANY_PRODUCE}
          alt={t('consumer:BrowseUniqueQualityProduce')}
          style={{ padding: Theme.Spacings.s }}
        />
      </PageTwoBodyWrapper>
      <Button
        variant="primary"
        onClick={() => {
          track(INTRO_SCREEN_TWO_CLICK_CTA);
          next();
        }}
        text={t('common:LetsGo')}
      />
    </Container>
  );
};

export default PageTwo;
