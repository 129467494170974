import { useTranslation } from '@dagens/frontend-i18n';
import React from 'react';
import CheckboxButtons, {
  CheckboxButtonsProps
} from '_common/components/radio-buttons/CheckboxButtons';

type ProductCertificationsProps = {
  onChange: CheckboxButtonsProps['onChange'];
  certificationIds: string[];
  certifications: {
    _id: string;
    displayNameKey: string;
  }[];
};

const ProductCertifications = ({
  onChange,
  certificationIds = [],
  certifications = []
}: ProductCertificationsProps) => {
  const { t } = useTranslation();

  const toKeyValue = React.useCallback(
    ({ _id, displayNameKey }: { _id: string; displayNameKey: string }) => {
      return {
        key: _id,
        value: t(`common:${displayNameKey}`)
      };
    },
    [t]
  );

  if (certifications.length <= 0) {
    return null;
  }

  return (
    <CheckboxButtons
      headerText={t('producer:productCertificationsLabelText')}
      options={certifications.map(toKeyValue)}
      selectedOptions={certificationIds}
      onChange={onChange}
      optional
    />
  );
};

export default ProductCertifications;
