import BasketOrderLinesByProducer from './BasketOrderLinesByProducer';
import { IBasketWithProducer } from '_consumer/reducers/productsForSale';
import { DeliveryDayFee } from 'types/Basket';

type ProducerDeliveredOrderLinesProps = {
  basket: IBasketWithProducer[];
  deliveryDate: string;
  deliveryFees: DeliveryDayFee;
};

const ProducerDeliveredOrderLines = ({
  basket,
  deliveryDate,
  deliveryFees
}: ProducerDeliveredOrderLinesProps) => {
  const selfDeliveryProducers = deliveryFees.producers
    .filter(producer => {
      return producer.type === 'ProducerDelivery';
    })
    .map(p => {
      return p.id;
    });

  const producerDeliveries = basket.filter(product => {
    return selfDeliveryProducers.includes(product.producer._id);
  });

  if (!producerDeliveries.length) {
    return null;
  }

  return (
    <BasketOrderLinesByProducer
      basket={producerDeliveries}
      deliveryDate={deliveryDate}
      deliveryTagTextKey="ProducerDelivery"
    />
  );
};

export default ProducerDeliveredOrderLines;
