import { useTranslation } from '@dagens/frontend-i18n';
import Theme from '@dagensmat/carrot/Theme';
import { useContext } from 'react';
import SignUpToTradeButton from './SignUpToTradeButton';
import Button from '_common/components/button/Button.style';
import LikeButton from '_common/components/button/LikeButton';
import {
  CardImageContainer as ImageContainer,
  Content,
  RegularCardContainer as Container
} from '_common/components/product/Product.style';
import ProductInfo from '_common/components/product/ProductInfo';
import ProductTag from '_common/components/product/ProductTag';
import ProductBuyer from '_common/components/product-buyer/ProductBuyer';
import {
  DELIVERY_DATE_CONTEXT_DEFAULT,
  DeliveryDateContext
} from '_common/context/DeliveryDateContext';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { ProductForSale } from 'types/Product';
import { getImageUrl } from 'utils/imageUrls';
import { PRODUCT_CARD_CLICKED, track } from 'utils/mixpanel';

type ProductForSaleCardProps = {
  isGuest?: boolean;
  isAuthenticated?: boolean;
  product: ProductForSale;
  onClick: () => void;
  isInSameDA: boolean;
  isProducer: boolean;
  isOwnShop?: boolean;
};

const ProductForSaleCard = ({
  product,
  isGuest = false,
  isAuthenticated = false,
  isInSameDA,
  isProducer,
  onClick,
  isOwnShop
}: ProductForSaleCardProps) => {
  const { t } = useTranslation();
  const { _id: productId, image, name } = product;

  const { pageContext } = useContext(DeliveryDateContext);
  const { selectedDeliveryDate } = useAppSelector(
    ({
      productsForSale: {
        selectedDeliveryDate: deliveryDate,
        availableDeliveryDates: availableDates
      }
    }) => {
      return {
        selectedDeliveryDate: deliveryDate,
        availableDeliveryDates: availableDates
      };
    }
  );
  const imageUrl = getImageUrl(image, { width: 500 });
  const buttonDisabled = !isInSameDA || isProducer || isGuest;

  return (
    <Container
      onClick={() => {
        track(PRODUCT_CARD_CLICKED);
        onClick();
      }}
    >
      <ImageContainer imageUrl={imageUrl} name={name} />
      <LikeButton productId={productId} />
      <ProductTag
        product={product}
        deliveryDate={selectedDeliveryDate}
        isOwnShop={isOwnShop}
      />
      <Content>
        <ProductInfo
          product={product}
          isAuthenticated={isAuthenticated}
          isInSameDA={isInSameDA}
          isOwnShop={isOwnShop}
          isGuest={isGuest}
          isProducer={isProducer}
        />
        {(() => {
          if (!isAuthenticated) {
            return <SignUpToTradeButton />;
          }

          if (
            !selectedDeliveryDate ||
            pageContext !== DELIVERY_DATE_CONTEXT_DEFAULT
          ) {
            return (
              <Button my={Theme.Spacings.xs} disabled={buttonDisabled}>
                {t('consumer:ChooseDeliveryDay')}
              </Button>
            );
          }

          return (
            <ProductBuyer
              product={product}
              selectedDeliveryDate={selectedDeliveryDate}
            />
          );
        })()}
      </Content>
    </Container>
  );
};

export default ProductForSaleCard;
