import { Trans, useTranslation } from '@dagens/frontend-i18n';
import { Callout, Typography } from '@dagensmat/carrot/Components';
import { Container } from '@dagensmat/carrot/Layout';
import Theme from '@dagensmat/carrot/Theme';
import { formatPhoneNumber } from '@dagensmat/core';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import ImagePreviewSimple from '_common/components/image-uploader/ImagePreviewSimple';
import ProductCard from '_common/components/product-card/ProductCard';
import MultiProductSeasonCalendar from '_common/components/season/MultiProductSeasonCalendar';
import { ButtonTabs } from '_common/components/tabs/Tabs';
import Icon from '_common/components/utils/Icon';
import { DeliveryDateContext } from '_common/context/DeliveryDateContext';
import { useAppDispatch } from '_common/hooks/reduxHooks';
import { GridContainer } from '_common/pages/search/grid/Grid';
import { RoleType } from '_common/reducers/auth';
import { showProductModal } from '_common/reducers/modal';
import { Producer } from 'types/Producer';
import { ProductForSale } from 'types/Product';
import { getImageUrl } from 'utils/imageUrls';
import { MediaQuery } from 'utils/mediaQueries';
import { PRODUCER_SEASON_CAL_CLICKED, track } from 'utils/mixpanel';

const HeaderSection = styled.div`
  --span: 1;
  display: grid;
  grid-template-columns: repeat(var(--span), 1fr);
  grid-template-rows: auto;
  gap: ${Theme.Spacings.m}px;
  margin-bottom: ${Theme.Spacings.m}px;

  ${MediaQuery.tabletUp} {
    --span: 2;
    gap: ${Theme.Spacings.xl}px;
  }

  > div {
    max-width: 490px;
  }
`;

const TabContentContainer = styled.div`
  padding-top: ${Theme.Spacings.m}px;
`;

const AboutUsTextContainer = styled.div`
  max-width: 55ch;
  padding: ${Theme.Spacings.s}px 0;

  ${MediaQuery.tabletUp} {
    padding: ${Theme.Spacings.xl}px 0;
    padding-left: ${Theme.Spacings.jumbo}px;
  }
`;

const InstagramLink = ({ instagram }: { instagram?: string }) => {
  const parsedInstagram = instagram?.replace('@', '').replace(/^.+.com\//, '');
  return (
    <Typography
      variant="link"
      href={`https://instagram.com/${parsedInstagram}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`@${parsedInstagram}`}
    </Typography>
  );
};

type ProducerViewProps = {
  producer: Producer;
  products: ProductForSale[];
  roleType?: RoleType;
  isGuest: boolean;
  isInSameDA: boolean;
};

type TabOption = 'AVAILABLE' | 'PLAN_AHEAD' | 'ABOUT';

const tabMap: TabOption[] = ['AVAILABLE', 'PLAN_AHEAD', 'ABOUT'];

const ProducerView = ({
  producer,
  products = [],
  roleType,
  isGuest,
  isInSameDA
}: ProducerViewProps) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<TabOption>('AVAILABLE');
  const dispatch = useAppDispatch();

  const {
    name,
    phone,
    profileArea,
    contactPerson,
    instagram,
    image,
    profileBio,
    distributionAreas
  } = producer;

  const { pageContext: deliveryDateContext } = useContext(DeliveryDateContext);

  const onCardClick = (productId: string) => {
    dispatch(
      showProductModal({
        producerProducts: products,
        producer,
        productId,
        deliveryDateContext
      })
    );
  };

  const isConsumer = roleType === 'consumers';
  const tabOptions = [
    {
      text: t('producer:ProducerAvailableTab'),
      onClick: () => {
        setSelectedTab('AVAILABLE');
      }
    },
    {
      text: t('producer:ProducerPlanAheadTab'),
      onClick: () => {
        setSelectedTab('PLAN_AHEAD');
      }
    },
    {
      text: t('producer:ProducerAboutTab'),
      onClick: () => {
        setSelectedTab('ABOUT');
      }
    }
  ];
  return (
    <>
      <HeaderSection>
        <div>
          <ImagePreviewSimple
            imageUrl={
              image ? getImageUrl(image, { fit: 'max' }) : '/assets/Illu_1.svg'
            }
          />
        </div>
        <Container
          flex
          alignItems="baseline"
          direction="column"
          justify="center"
        >
          <div>
            <Typography variant="primaryHeading" mb="xs">
              {name}
            </Typography>
            {!isGuest && (
              <Typography variant="paragraph">
                {contactPerson}{' '}
                <Typography variant="link" href={`tel:${phone}`}>
                  {formatPhoneNumber(phone)}
                </Typography>
              </Typography>
            )}

            {(profileArea ||
              (distributionAreas && distributionAreas.length > 0)) && (
              <Container mt="m">
                {profileArea && (
                  <Typography variant="paragraph" my="xs">
                    <Icon icon="location" $size="m" mr={Theme.Spacings.s} />
                    {t('producer:LocatedIn', { locatedIn: profileArea })}
                  </Typography>
                )}
                {distributionAreas && distributionAreas.length > 0 && (
                  <Typography variant="paragraph" my="xs">
                    <Icon
                      icon="deliveryAreas"
                      $size="m"
                      mr={Theme.Spacings.s}
                    />
                    {t('producer:TradingIn', {
                      tradingIn: distributionAreas?.join(', ')
                    })}
                  </Typography>
                )}
              </Container>
            )}
            {!isGuest && !isInSameDA && isConsumer && (
              <Callout colour="lightGrey" mt="m">
                <Typography variant="paragraph">
                  {t('common:ProducerNotAvailableInDAInfo', { name })}
                </Typography>
              </Callout>
            )}
          </div>
        </Container>
      </HeaderSection>
      <ButtonTabs
        active={tabMap.findIndex(o => {
          return o === selectedTab;
        })}
        tabs={tabOptions}
      />
      <TabContentContainer>
        {selectedTab === 'AVAILABLE' && (
          <GridContainer>
            {products.map(product => {
              return (
                <ProductCard
                  key={product._id}
                  product={product}
                  onClick={() => {
                    onCardClick(product._id);
                  }}
                />
              );
            })}
          </GridContainer>
        )}
        {selectedTab === 'PLAN_AHEAD' && (
          <MultiProductSeasonCalendar
            heading={t('consumer:ProduceSeason')}
            products={products}
            onClick={(productId: string) => {
              track(PRODUCER_SEASON_CAL_CLICKED);
              onCardClick(productId);
            }}
          />
        )}
        {selectedTab === 'ABOUT' && (
          <AboutUsTextContainer>
            {profileBio ? (
              <Typography variant="leadText" style={{ whiteSpace: 'pre-line' }}>
                {profileBio}
              </Typography>
            ) : (
              <Callout colour="lightGrey">
                <Typography
                  variant="paragraph"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {t('producer:MissingProfileBio', {
                    producerName: name
                  })}
                </Typography>
              </Callout>
            )}
            {Boolean(instagram) && (
              <Typography variant="paragraph" my="m">
                <Trans
                  t={t}
                  i18nKey="producer:CheckoutOnInstagram"
                  components={{
                    InstagramLink: <InstagramLink instagram={instagram} />
                  }}
                />
              </Typography>
            )}
          </AboutUsTextContainer>
        )}
      </TabContentContainer>
    </>
  );
};

export default ProducerView;
