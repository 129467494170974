import { ButtonGroup, Button } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';

import useMixpanelEvent from '../../_common/hooks/useMixpanelEvent';
import useRedirectLogin from '../../_common/hooks/useRedirectLogin';
import { track } from '../../utils/mixpanel';
import { Navbar } from './navbar';

export const UnauthNavbar = () => {
  const { t } = useTranslation();
  const { get: getRedirectLogin } = useRedirectLogin();
  const trackNavBarSignupCTA = useMixpanelEvent();
  return (
    <Navbar
      showLogo
      showActionsOnMobile
      actions={
        <ButtonGroup.NoWrap>
          <Button
            as="link"
            size="regular"
            variant="primary"
            to={`/letsgo?redirectLogin=${getRedirectLogin()}`}
            onClick={() => {
              track(trackNavBarSignupCTA);
            }}
          >
            {t('common:header.signUpToTrade')}
          </Button>
          <Button
            as="link"
            size="small"
            variant="borderless"
            to={`/login?redirectLogin=${getRedirectLogin()}`}
          >
            {t('common:header.login')}
          </Button>
        </ButtonGroup.NoWrap>
      }
    />
  );
};
