import { MeasureUnit, PackedUnit } from '@dagensmat/core';
import { type UnitType } from '@dagensmat/core';

export const MEASURE_UNIT_VALUES = Object.values(MeasureUnit);
export const PACKED_UNIT_VALUES = Object.values(PackedUnit);

export const isPackedUnit = (value: UnitType): value is PackedUnit => {
  return PACKED_UNIT_VALUES.includes(value as PackedUnit);
};

export const isMeasureUnit = (value: UnitType): value is MeasureUnit => {
  return MEASURE_UNIT_VALUES.includes(value as MeasureUnit);
};
