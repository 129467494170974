import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { AnyAction } from '@reduxjs/toolkit';
import { find } from 'lodash';
import { Dispatch, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Category,
  EditablePricing,
  ProducerProduct
} from '../../../types/Product';
import PageHeader from '@components/page-header/PageHeader';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import usePageView from '_common/hooks/usePageView';
import EditPricingFlow from '_producer/components/edit-pricing-flow/EditPricingFlow';
import PricingPreview from '_producer/components/product-form/PricingPreview';
import { getOfferType } from '_producer/components/product-form/PricingPreviews';
import { FormWrapper } from '_producer/components/product-form/ProductForm';
import { productEdited as updateProductInStore } from '_producer/reducers/products';
import { ActionButton } from '@components/action-button';
import { BottomActions } from '@components/bottom-actions';
import { Page } from '@components/page';
import { postProductFieldsUpdate } from 'api';
import {
  ADD_OFFER_CLICK_SAVE_OFFER,
  ADD_OFFER_PAGE_VIEW,
  getConsumersForDataTracking,
  track
} from 'utils/mixpanel';
import {
  convertPrices,
  getExistingProductInitialPricing,
  isMeasureUnitValid,
  makeEditable,
  pricingComplete,
  shouldPriceAllowZeroWeight
} from 'utils/pricing';
import REQ, { ReqType } from 'utils/REQ';
import { formatProductNameAndType } from 'utils/texts';

const useOnSave = ({
  isValid,
  product,
  pricing,
  dispatch
}: {
  isValid: boolean;
  product?: ProducerProduct;
  pricing: EditablePricing;
  dispatch: Dispatch<AnyAction>;
}) => {
  const [saveReq, setSaveReq] = useState<ReqType>(REQ.INIT);
  const [showError, setShowError] = useState(false);
  const { consumers } = useAppSelector(({ consumers: { items = [] } }) => {
    return { consumers: items };
  });

  const onSave = useCallback(async () => {
    if (saveReq !== REQ.INIT) {
      return;
    }

    if (!isValid || !product) {
      setShowError(true);
      return;
    }

    const consumersSelected = getConsumersForDataTracking(consumers, pricing);
    try {
      setSaveReq(REQ.PENDING);
      const prices = convertPrices([...product.prices, pricing]);
      await postProductFieldsUpdate({
        productId: product._id,
        prices
      });
      dispatch(updateProductInStore({ ...product, prices }));
      setSaveReq(REQ.SUCCESS);
      track(ADD_OFFER_CLICK_SAVE_OFFER, { ...consumersSelected });
    } catch (error) {
      setSaveReq(REQ.ERROR);
    }
  }, [isValid, consumers, pricing, product, dispatch]);

  return { onSave, saveReq, showError };
};

const getIsValid = (
  pricing: EditablePricing,
  productCategoryId: string | undefined,
  categories: Category[]
) => {
  const allowZeroWeight = shouldPriceAllowZeroWeight(
    productCategoryId,
    categories
  );
  const priceHasWeight = isMeasureUnitValid(pricing);

  const isValid = [
    pricingComplete(pricing),
    pricing.specialConsumers && pricing.specialConsumers?.length > 0,
    allowZeroWeight || priceHasWeight
  ].every(Boolean);
  return isValid;
};

type Props = {
  product?: ProducerProduct;
};

const AddPricingPage = ({ product }: Props) => {
  usePageView(ADD_OFFER_PAGE_VIEW);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector(({ producerCategories }) => {
    return { categories: producerCategories.categories };
  });

  const [pricing, setPricing] = useState(
    makeEditable(getExistingProductInitialPricing(product?.prices))
  );

  const isValid = getIsValid(pricing, product?.categoryId, categories);
  const { onSave, saveReq, showError } = useOnSave({
    isValid,
    product,
    pricing,
    dispatch
  });
  /* const buttonText = useButtonText(saveReq, isValid, showError); */

  if (!product) {
    return null;
  }

  return (
    <Page
      bottom={
        <BottomActions>
          <ActionButton.Save
            onClick={onSave}
            feedbackText={{
              [REQ.PENDING]: t('producer:PricingSavingChangesButtonFeedback'),
              [REQ.SUCCESS]: t('producer:PricingChangesSavedButtonFeedback')
            }}
            saveReq={saveReq}
            redirectTo={-1}
          >
            {isValid || !showError
              ? t('producer:PricingSaveChangesButtonFeedback')
              : t('producer:PricingSaveChangesMissingFieldsFeedback')}
          </ActionButton.Save>
        </BottomActions>
      }
    >
      <PageHeader
        headerText={t('producer:AddPricingHeader')}
        subTitle={formatProductNameAndType(product)}
      />
      <FormWrapper>
        <EditPricingFlow
          allowZeroWeight={shouldPriceAllowZeroWeight(
            product.categoryId,
            categories
          )}
          editablePricing={pricing}
          onPricingChange={setPricing}
          prices={product.prices}
          showError={showError}
        />
        {isValid && (
          <>
            <Typography variant="inputLabel">
              {t('producer:OfferSummarised')}
            </Typography>
            <PricingPreview
              offerType={getOfferType(pricing)}
              numberOfPrices={1}
              productId={product._id}
              pricing={pricing}
            />
          </>
        )}
      </FormWrapper>
    </Page>
  );
};

const AddPricingPageFetcher = () => {
  const { id } = useParams();
  const { product, req } = useAppSelector(
    ({ producerProducts: { items, req } }) => {
      const product = find(items, { _id: id });

      return {
        req: req === REQ.SUCCESS && !product ? REQ.NOT_FOUND : req,
        product
      };
    }
  );

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }

  return <AddPricingPage product={product} />;
};

export default AddPricingPageFetcher;
