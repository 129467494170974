import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import REQ from '../../../utils/REQ';
import PageHeader from '@components/page-header/PageHeader';
import ProductRequestList from '_common/components/product-request-list/ProductRequestList';
import { useProductRequests } from '_common/reducers/productRequests';
import { CommonNavbar } from '@components/navbar';
import { Page } from '@components/page';

type Props = {
  productRequests: {
    _id: string;
    _createdAt: string;
    hasNewMessage: boolean;
    isOpened: boolean;
    counterPartName: string;
    pathname: string;
  }[];
};

const ProductRequestsPage = ({ productRequests = [] }: Props) => {
  const { t } = useTranslation();

  return (
    <Page header={<CommonNavbar showLogo />}>
      <PageHeader
        headerText={t('producer:MyRequests')}
        subTitle={t('producer:productRequests', {
          count: productRequests.length
        })}
      />
      {productRequests.length === 0 && (
        <Typography variant="paragraph" color="secondary" my="m">
          {t('common:NoActiveProductRequests')}
        </Typography>
      )}
      {productRequests.length > 0 && (
        <ProductRequestList items={productRequests} />
      )}
    </Page>
  );
};

const ProductRequestsPageLoader = () => {
  const { productRequests, req } = useProductRequests();
  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }
  return <ProductRequestsPage productRequests={productRequests} />;
};

export default ProductRequestsPageLoader;
