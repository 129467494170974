import { Trans, useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { Page } from '@components/page';

const CookiesInfoPage = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <Typography variant="primaryHeading" mt="m" mb="xl">
        {t('common:Cookies')}
      </Typography>
      <Typography variant="paragraph">
        {t('common:CookiesReasoning')}
      </Typography>
      <Typography variant="secondaryHeading" mt="xl" mb="s">
        {t('common:Mixpanel')}
      </Typography>
      <Typography variant="paragraph">
        {t('common:MixpanelReasoning')}
      </Typography>

      <Typography variant="secondaryHeading" mt="xl" mb="s">
        {t('common:Hotjar')}
      </Typography>
      <Typography variant="paragraph">
        <Trans
          t={t}
          i18nKey="common:HotjarReasoning"
          values={{ linkText: t('common:HotjarDetailsLink') }}
          components={{
            HotjarDetailsLink: (
              <Typography
                variant="link"
                href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar"
                target="_blank"
                rel="noopener noreferrer"
              />
            )
          }}
        />
      </Typography>
    </Page>
  );
};

export default CookiesInfoPage;
