import { Button, Icon, useMediaQuery } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { useNavigate } from 'react-router-dom';

/**
 * @package
 */
export const AddCustomerButtonLink = () => {
  const { t } = useTranslation();
  const { desktopUp } = useMediaQuery();
  const navigate = useNavigate();

  if (desktopUp) {
    return (
      <Button
        as="link"
        size="regular"
        variant="borderless"
        to={'/customers/add/start'}
      >
        <Icon icon="add" /> {t('producer:AddCustomer.headerAnnotation')}
      </Button>
    );
  }

  return (
    <Button
      size="regular"
      variant="borderless"
      onClick={() => {
        navigate('/customers/add/start');
      }}
    >
      <Icon icon="add" />
    </Button>
  );
};
