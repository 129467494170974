import { Trans, useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';

const MinimumOrderPageCallout = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="paragraph" my="s">
        <Trans i18nKey="producer:MinumumOrderPageCalloutText" t={t} />
      </Typography>
      <Typography variant="paragraphBold" mb="s" as="p">
        {t('producer:MinumumOrderPageCalloutImageHeader')}
      </Typography>
      <img
        src="/assets/Customer_experience_preview_picture.png"
        style={{
          height: 'auto',
          width: '100%',
          display: 'block',
          maxWidth: '360px'
        }}
        alt={t('producer:MinumumOrderPageCalloutImageHeader')}
      />
    </>
  );
};

export default MinimumOrderPageCallout;
