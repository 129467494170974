import { Nav, NavLinkProps } from '@dagens/carrot';

import { useMediaQuery } from '../../_common/hooks/useMediaQueries';

type Props = {
  links: readonly NavLinkProps[];
};

export const NavTabs = ({ links }: Props) => {
  const { desktopUp } = useMediaQuery();
  if (desktopUp) {
    return null;
  }

  return <Nav.Tabs links={links} />;
};
