import 'react-day-picker/dist/style.css';

import Theme from '@dagensmat/carrot/Theme';
import { DayPicker } from 'react-day-picker';
import styled from 'styled-components';

export const StyledDeliveryDatePicker = styled(DayPicker)`
  text-align: center;
  display: block;
  margin: 0;

  &--today {
    color: ${Theme.Colours.secondary};
  }

  .rdp-caption {
    text-transform: capitalize;
  }

  .rdp-caption_label {
    font-weight: ${Theme.FontWeights.semiBold};
    line-height: ${Theme.Spacings.m}px;
    font-size: ${Theme.FontSizes.paragraph}px;
    letter-spacing: 0.1px;
  }

  .rdp-months {
    justify-content: center;
  }

  .rdp-day {
    margin-top: ${Theme.Spacings.xxs / 2}px;
    margin-bottom: ${Theme.Spacings.xxs / 2}px;
  }

  .rdp-button {
    border-radius: ${Theme.BorderRadius.regular}px;
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: ${Theme.Colours.brightPurple};
  }

  .rdp-day_range_middle {
    color: ${Theme.Colours.black};
    background-color: ${Theme.Colours.lightGrey};
  }

  .rdp-day_hasDagensLogistics {
    background-color: ${Theme.Colours.dagensPurple};
    color: ${Theme.Colours.black};
    border-radius: ${Theme.BorderRadius.regular}px;
  }

  .rdp-day_soldOut {
    color: ${Theme.Colours.black};
    border-radius: ${Theme.BorderRadius.regular}px;
    background-color: ${Theme.Colours.white};
    position: relative;
    overflow: hidden;
    &::before {
      content: '';
      display: block;
      border-radius: ${Theme.BorderRadius.regular}px;
      border: 2px solid ${Theme.Colours.black};
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &::after {
      content: '';
      display: block;
      border-top: 2px solid ${Theme.Colours.black};
      position: absolute;
      top: -4px;
      left: -4px;
      right: -50%;
      transform: rotate(45deg);
      transform-origin: 0 0;
    }
  }

  .rdp-day_disabled {
    cursor: not-allowed;
  }

  .rdp-day_selected {
    background-color: ${Theme.Colours.black};
    border-radius: ${Theme.BorderRadius.regular}px;
    color: ${Theme.Colours.white};
  }
`;
