import { useTranslation, TFunction } from '@dagens/frontend-i18n';
import Theme from '@dagensmat/carrot/Theme';
import { capitalize } from '@dagensmat/core';
import { useEffect, useState } from 'react';

import HorisontalScrollContainer from '../utils/HorisontalScrollContainer';
import FilterComp, { ActiveFilters, FilterData } from './Filter';
import FilterButton from './FilterButton';
import { Category, ProcessedState, ProcessedStateEnum } from 'types/Product';
import { getCategories } from 'api';
import { getCertificationFacets } from 'algolia/productsIndex';
import { useAppSelector } from '_common/hooks/reduxHooks';

const getFilterButtonLabel = (
  activeFilters: string[] | ProcessedState[] | undefined,
  prefixKey: string,
  filters: FilterData['options'],
  t: TFunction
) => {
  if (activeFilters && activeFilters.length === 0)
    return `${prefixKey}: ${t('common:All')}`;
  const TAGS_TO_SHOW = 2;
  const filterValues = activeFilters?.map((filter: string | ProcessedState) => {
    return filters.find(({ key }) => {
      return key === filter;
    });
  });

  const first2Tags = filterValues
    ?.slice(0, TAGS_TO_SHOW)
    .map(({ value } = {} as FilterData['options'][0]) => {
      return value && value;
    })
    .join(', ');
  return [
    `${prefixKey}: ${first2Tags}`,
    filterValues &&
      filterValues.length > TAGS_TO_SHOW &&
      filterValues.length - TAGS_TO_SHOW
  ]
    .filter(Boolean)
    .join(' + ');
};

type FilterIndex = number | undefined;

type FiltersComponentProps = {
  activeFilters: ActiveFilters;
  onFilterClick: (index: FilterIndex, filter: string | null) => void;
};

const FiltersComponent = ({
  activeFilters,
  onFilterClick
}: FiltersComponentProps) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterIndex>(undefined);
  const [filters, setFilters] = useState<FilterData[]>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [certifications, setCertifications] = useState<string[]>([]);
  const [processedStates, setProcessedStates] = useState<ProcessedState[]>([]);
  const { t } = useTranslation();

  const { roleLang, distributionAreaId } = useAppSelector(({ auth }) => {
    return {
      roleLang: auth.roleLang,
      distributionAreaId: auth.distributionAreaId
    };
  });

  const formatFilters = () => {
    setFilters([
      {
        label: t('common:ProductCategory'),
        options: categories.map(category => {
          const value = roleLang ? category[`name_${roleLang}`] : category.name;
          return {
            key: category.translateKey,
            value: capitalize(value)
          };
        })
      },
      {
        label: t('common:Certification'),
        options: certifications.map(certificationDisplayNameKey => {
          return {
            key: certificationDisplayNameKey,
            value: t(`common:${certificationDisplayNameKey}`)
          };
        })
      },
      {
        label: t('common:RawOrProcessed'),
        options: processedStates.map(productProccedState => {
          return {
            key: productProccedState,
            value: t(`producer:${capitalize(productProccedState)}`)
          };
        })
      }
    ] as any);
  };

  const getFilters = async () => {
    const filteredCategories = await getCategories().then(allCategories => {
      return (allCategories as Category[]).filter(c => {
        return !c.parent;
      });
    });
    const certificationFacets =
      await getCertificationFacets(distributionAreaId);
    setCategories(filteredCategories);
    setCertifications(certificationFacets);
    setProcessedStates([ProcessedStateEnum.RAW, ProcessedStateEnum.PROCESSED]);
  };

  const hasFetchedFilters = () => {
    return (
      categories.length > 0 &&
      certifications.length > 0 &&
      processedStates.length > 0
    );
  };

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    if (hasFetchedFilters()) {
      formatFilters();
    }
  }, [
    JSON.stringify(categories),
    JSON.stringify(certifications),
    JSON.stringify(processedStates)
  ]);

  if (!filters || !hasFetchedFilters()) return null;

  return (
    <div style={{ marginBottom: Theme.Spacings.xs }}>
      <HorisontalScrollContainer>
        <>
          {filters.map((filter, i) => {
            const activeFilter = activeFilters.at(i);
            return (
              <FilterButton
                key={filter.label}
                label={getFilterButtonLabel(
                  activeFilter,
                  filter.label,
                  filters[i].options,
                  t
                )}
                isActive={Boolean(activeFilter && activeFilter.length > 0)}
                handleButtonClick={() => {
                  if (showFilter && i === selectedFilter) {
                    setShowFilter(false);
                  } else {
                    setShowFilter(true);
                    setSelectedFilter(i);
                  }
                }}
                isSelected={showFilter && selectedFilter === i}
              />
            );
          })}
        </>
      </HorisontalScrollContainer>
      <FilterComp
        showFilter={showFilter}
        selectedFilter={selectedFilter}
        filters={filters}
        activeFilters={activeFilters}
        onFilterClick={onFilterClick}
        onClose={() => {
          return setShowFilter(false);
        }}
      />
    </div>
  );
};

export default FiltersComponent;
