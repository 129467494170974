import { Button, Modal } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { Consumer } from 'types/Consumer';

type Props = {
  activeConsumer: Consumer | null;
  onClose: () => void;
};

export const CustomerActionsModal = ({ activeConsumer, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal open={Boolean(activeConsumer)} onClose={onClose}>
      <Modal.Title>{activeConsumer?.name}</Modal.Title>
      <Modal.Actions>
        <Button.ModalAction
          as="link"
          to="/orders/add/delivery-date"
          state={{
            consumer: activeConsumer
          }}
        >
          {t('common:MakeNewOrder')}
        </Button.ModalAction>
        <Button.ModalAction as="link" to={`tel:${activeConsumer?.phone}`}>
          {t('common:Call')}
        </Button.ModalAction>
        <Button.ModalAction
          as="link"
          to={`mailto:${activeConsumer?.emails.join(',')}`}
        >
          {t('common:Email')}
        </Button.ModalAction>
        <Button.ModalAction onClick={onClose} close>
          {t('common:close')}
        </Button.ModalAction>
      </Modal.Actions>
    </Modal>
  );
};
