import { configureStore } from '@reduxjs/toolkit';
import authReducer from '_common/reducers/auth';
import checkoutReducer from '_common/reducers/checkout';
import messagesReducer from '_common/reducers/messages';
import modalReducer from '_common/reducers/modal';
import ordersReducer from '_common/reducers/orders';
import productRequestsReducer from '_common/reducers/productRequests';
import utilsReducer from '_common/reducers/utils';
import basketReducer from '_consumer/reducers/basket';
import producersReducer from '_consumer/reducers/producers';
import productsForSaleReducer from '_consumer/reducers/productsForSale';
import statisticsReducer from '_consumer/reducers/statistics';
import producerCategoriesReducer from '_producer/reducers/categories';
import consumersReducer from '_producer/reducers/consumers';
import newProductReducer from '_producer/reducers/newProduct';
import producerProductsReducer from '_producer/reducers/products';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    orders: ordersReducer,
    productRequests: productRequestsReducer,
    messages: messagesReducer,
    producerProducts: producerProductsReducer,
    producerCategories: producerCategoriesReducer,
    newProduct: newProductReducer,
    productsForSale: productsForSaleReducer,
    producers: producersReducer,
    consumers: consumersReducer,
    basket: basketReducer,
    modal: modalReducer,
    checkout: checkoutReducer,
    statistics: statisticsReducer,
    utils: utilsReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type ReduxStore = typeof store;
