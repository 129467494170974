import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import { tv } from 'tailwind-variants';
import { cellStyle } from './cell';

const style = tv({
  base: `
    text-start
    text-paragraphSmallBold
    text-black
  `
});

type Props = PropsWithChildren;

export const FooterCell = ({ children }: Props) => {
  return <td className={twMerge(cellStyle(), style())}>{children}</td>;
};
