import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useState
} from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';
import { evaluate, round } from 'mathjs';
import { inputStyle } from '../styles';

const stringToNumber = (
  value: string | undefined,
  type: 'integer' | 'float'
) => {
  if (value === undefined) {
    return undefined;
  }

  try {
    const result = evaluate(value);
    const n = typeof result === 'number' ? result : result.value;
    return type === 'integer' ? round(n, 0) : round(n, 2);
  } catch (e) {
    return undefined;
  }
};

const style = tv({
  slots: {
    input: `text-right`,
    unit: `
      text-inputText
      text-secondary
    `
  }
});

type InputProps = InputHTMLAttributes<HTMLInputElement>;

type Props = {
  type?: 'integer' | 'float';
  unit: string;
  placeholder?: InputProps['placeholder'];
  error?: boolean;
  value?: number;
  onChange?: (value: number) => void;
};

const Component = (
  {
    type = 'integer',
    unit,
    error,
    placeholder = '#',
    value,
    onChange,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [inputValue, setInputValue] = useState(value?.toString() ?? '');
  const { input: numberInput, unit: unitStyle } = style();
  const { container, input } = inputStyle({ error });

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      return;
    }
    setInputValue(stringToNumber(value, type)?.toString() ?? value);
  };

  useEffect(() => {
    onChange?.(stringToNumber(inputValue, type));
  }, [inputValue, type]);

  return (
    <label className={container()}>
      <input
        {...props}
        value={inputValue}
        onChange={event => setInputValue(event.target.value)}
        onBlur={onBlur}
        placeholder={placeholder}
        type="text"
        ref={ref}
        className={twMerge(input(), numberInput())}
      />
      <span className={unitStyle()}>{unit}</span>
    </label>
  );
};

export const QuantityInput = forwardRef(Component);
