import { Typography } from '@dagensmat/carrot/Components';
import Theme from '@dagensmat/carrot/Theme';
import { useRef } from 'react';
import styled from 'styled-components';
import InclusiveTagFilter from '_common/components/tags/InclusiveTagFilter';
import ExpandCollapseAnimationContainer from '_common/components/container/ExpandCollapseAnimationContainer';
import CloseButton from '_common/components/button/CloseButton';
import { ProcessedState } from 'types/Product';

const BorderContainer = styled.div`
  border: 2px solid black;
  border-radius: ${Theme.BorderRadius.regular}px;
  align-self: stretch;
  padding: ${Theme.Spacings.s}px;
  position: relative;
  width: 100%;
`;
export type FilterData = {
  label: string;
  options: { key: string; value: string }[];
};

export type ActiveFilters = [
  string[] | undefined,
  string[] | undefined,
  ProcessedState[] | undefined
];

type FilterProps = {
  showFilter: boolean;
  selectedFilter: number | undefined;
  filters: FilterData[];
  activeFilters: ActiveFilters;
  onFilterClick: (
    filter: number | undefined,
    filterValue: string | null
  ) => void;
  onClose: () => void;
};

const Filter = ({
  showFilter,
  selectedFilter,
  filters,
  activeFilters,
  onFilterClick,
  onClose
}: FilterProps) => {
  const animationRef = useRef<HTMLDivElement>(null);
  const filter =
    selectedFilter !== undefined ? filters.at(selectedFilter) : undefined;
  const tags =
    selectedFilter !== undefined ? activeFilters[selectedFilter] : undefined;
  return (
    <ExpandCollapseAnimationContainer shown={showFilter}>
      <BorderContainer ref={animationRef}>
        <Typography variant="tertiaryHeading" mb="s">
          {filter?.label}
        </Typography>
        <InclusiveTagFilter
          tags={filter?.options ?? []}
          selectedTags={tags ?? []}
          toggleTag={id => {
            onFilterClick(selectedFilter, id);
          }}
        />
        <CloseButton
          onClick={onClose}
          style={{
            position: 'absolute',
            padding: Theme.Spacings.s,
            top: Theme.Spacings.xxs,
            right: Theme.Spacings.xxs
          }}
        />
      </BorderContainer>
    </ExpandCollapseAnimationContainer>
  );
};

export default Filter;
