import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import styled from 'styled-components';
import CertificationsView from '_common/components/certifications/CertificationsView';
import margins, { MarginProps } from 'utils/style';

type ProductReferenceProps = {
  variant: 'small' | 'regular' | 'productView';
  product: any;
  style?: any;
} & MarginProps;

const TableView = styled.div`
  display: inline-block;
  height: auto;
  ${margins}
`;

const ProductViewContainer = styled.div`
  display: block;
  ${margins}

  div:first-child {
    display: inline-block;
  }
`;

const ProductView = ({ product, ...rest }: any) => {
  const { t } = useTranslation();
  const { name, type, temperatureZone, certifications } = product;
  return (
    <ProductViewContainer {...rest}>
      <div>
        <Typography variant="secondaryHeading" as="span" mr="xxs">
          {name}
        </Typography>
        {certifications && (
          <CertificationsView
            certifications={certifications}
            variant="large"
            display="inline"
          />
        )}
      </div>
      <div>
        {type && (
          <Typography variant="paragraph" as="span" mr="xxs">
            {type}
          </Typography>
        )}
        {temperatureZone && temperatureZone !== 'DRY' && (
          <Typography
            variant="paragraphSmall"
            style={{ whiteSpace: 'nowrap' }}
            as="span"
            color="secondary"
          >
            {t(`common:${temperatureZone}`)}
          </Typography>
        )}
      </div>
    </ProductViewContainer>
  );
};

const ProductReference = ({
  product,
  variant,
  ...rest
}: ProductReferenceProps) => {
  const { t } = useTranslation();
  const { name, type, temperatureZone, certifications } = product;

  if (variant === 'productView') {
    return <ProductView product={product} {...rest} />;
  }

  return (
    <TableView {...rest}>
      <Typography
        variant={variant === 'regular' ? 'paragraph' : 'paragraphSmall'}
        mr="xxs"
        as="span"
      >
        {[name, type].join(' ')}
      </Typography>
      {certifications && (
        <CertificationsView certifications={certifications} variant={variant} />
      )}
      {temperatureZone && temperatureZone !== 'DRY' && (
        <Typography
          variant={variant === 'regular' ? 'paragraph' : 'paragraphSmall'}
          style={{ whiteSpace: 'nowrap' }}
          as="span"
          color="secondary"
        >
          {t(`common:${temperatureZone}`)}
        </Typography>
      )}
    </TableView>
  );
};

export default ProductReference;
