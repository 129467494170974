import Theme from '@dagensmat/carrot/Theme';
import styled, { keyframes } from 'styled-components';

export const FooterContainer = styled.div`
  width: 100%;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Loader = styled.div<{ delay: string }>`
  animation-name: ${fadeIn};
  animation-delay: ${props => {
    return props.delay;
  }};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: both;
`;

export const SystemFeedbackContainer = styled.div`
  text-align: center;
  padding: ${Theme.Spacings.xs + Theme.Spacings.xxs}px 0;
`;
