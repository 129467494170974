import { tv } from 'tailwind-variants';

export const inputStyle = tv({
  slots: {
    container: `
      flex
      w-full
      items-center
      gap-xs
      rounded
      border
      border-lightGrey
      p-[10px]

      focus-within:border-black
      focus-within:hover:border-black

      hover:border-secondary
    `,
    input: `
      form-input
      flex-1
      border-0
      p-0
      text-inputText
      text-black

      focus:outline-none
      focus:ring-0

      placeholder:text-secondary
    `
  },
  variants: {
    error: {
      true: {
        container: `
          border-error

          focus-within:border-error
          focus-within:hover:border-error

          hover:border-error
        `
      }
    }
  }
});
