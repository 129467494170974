import { Radio as HeadlessRadio, RadioGroup } from '@headlessui/react';
import { tv } from 'tailwind-variants';

const style = tv({
  slots: {
    container: `
      flex
      flex-col
      gap-xxs
      text-paragraph
    `,
    radio: `
      cursor-pointer
      p-[2px]

      focus:outline-none
    `,
    label: `
      flex
      gap-xs
      rounded
      p-[2px]
    `,
    check: `
      mt-[2px]
      flex
      size-[20px]
      items-center
      justify-center
      rounded-full
      border
      border-secondary
    `,
    checked: `
      size-xs
      rounded-full
      bg-black
    `,
    description: `
      text-paragraphSmall
      text-secondary
    `
  },
  variants: {
    checked: {
      true: {
        check: `border-black`
      }
    },
    focused: {
      true: {
        label: `focus-style`
      }
    }
  }
});

type Props<T> = {
  options: {
    value: T;
    description?: string;
  }[];
  displayValue?: (value?: T) => string;

  value?: T;
  onChange?: (value: T) => void;
};

export const Radio = <T,>({
  value,
  options,
  onChange,
  displayValue
}: Props<T>) => {
  const {
    container,
    radio,
    label,
    check,
    checked: checkedStyle,
    description: descriptionStyle
  } = style();
  return (
    <RadioGroup value={value} onChange={onChange} className={container()}>
      {options.map(({ value: option, description }) => {
        const text = displayValue ? displayValue(option) : option?.toString();
        return (
          <HeadlessRadio
            key={text}
            className={radio()}
            value={option}
            onChange={() => onChange?.(option)}
          >
            {({ checked, focus }) => (
              <div className={label({ focused: focus })}>
                <div className={check({ checked })}>
                  {checked && <div className={checkedStyle()} />}
                </div>
                <div>
                  {text}
                  {description && (
                    <div className={descriptionStyle()}>{description}</div>
                  )}
                </div>
              </div>
            )}
          </HeadlessRadio>
        );
      })}
    </RadioGroup>
  );
};
